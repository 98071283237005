import React, { useContext } from "react";
import { NodeProps } from "../../../types/CommonType";
import {
  Icon,
  SidebarMenuContainer,
  SidebarItem,
  SidebarContainer,
  CloseIcon,
  SideBtnWrapper,
  SideBtn,
  ProfileContainer,
  Text,
  ButtonWrapper,
  SignInBtn,
} from "./SidebarElements";
import { Avatar } from "antd";
import { UserAuthContext } from "../../../contexts/UserAuthContext";
import { UserAuthType } from "../../../types/UserAuth";
import SidebarMenu from "./SidebarMenu";
import { BiUserCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, toggle, profileImg }: NodeProps) => {
  const { userInfo, handleLogout, isAuth } = useContext(
    UserAuthContext
  ) as UserAuthType;
  const navigate = useNavigate();

  const logoutByUser = () => {
    console.log("logout from sidebar");
    handleLogout(true);
  };

  return (
    <>
      <SidebarContainer isOpen={isOpen}>
        {isAuth() && (
          <ProfileContainer>
            {userInfo.googleImgUrl ? (
              <Avatar
                size="large"
                src={userInfo.googleImgUrl}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <Avatar
                size="large"
                src={profileImg}
                style={{ cursor: "pointer" }}
              />
            )}
            <Text>{userInfo.userName}</Text>
          </ProfileContainer>
        )}
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarMenuContainer>
          <SidebarItem>
            <SidebarMenu to="/" name={"Home"} handleClick={toggle} />
            <SidebarMenu to="room" name={"Room"} handleClick={toggle} />
            <SidebarMenu
              to="requestRoom"
              name={"Request Room"}
              handleClick={toggle}
            />
          </SidebarItem>
        </SidebarMenuContainer>
        {isAuth() ? (
          <SideBtnWrapper>
            <SideBtn onClick={logoutByUser}>Logout</SideBtn>
          </SideBtnWrapper>
        ) : (
          <ButtonWrapper
            onClick={() => {
              navigate("/login");
            }}
          >
            <SignInBtn>
              <BiUserCircle size={25} />
              <div style={{ marginLeft: "10px" }}>Sign in</div>
            </SignInBtn>
          </ButtonWrapper>
        )}
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
