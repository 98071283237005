import { createContext, FC, useEffect, useState } from "react";
import { NodeProps } from "../types/CommonType";
import { UserAuthType, UserInfo, DefaultUserInfo } from "../types/UserAuth";
import { setUserPermission } from "../utils/UserUtil";

export const UserAuthContext = createContext<UserAuthType | null>(null);

const UserAuthProvider: FC<NodeProps> = ({ children }) => {
  const getUserInfoFromLocal = () => {
    const oldUserAuth = JSON.parse(localStorage.getItem("userAuth") || "{}");
    return oldUserAuth;
  };

  const [userInfo, setUserInfo] = useState<UserInfo>(getUserInfoFromLocal());

  useEffect(() => {
    const existingUser: UserInfo = getUserInfoFromLocal();
    setUserInfo(existingUser);
  }, []);

  const updateUserInfo = (userInfo: UserInfo) => {
    setUserInfo(userInfo);
  };

  const handleLoginInContext = async (loginValue: UserInfo) => {
    const newUserInfo: UserInfo = {
      id: loginValue.id,
      roleId: loginValue.roleId,
      roleName: loginValue.role?.name,
      isApproveRoom: loginValue?.role?.isApproveRoom,
      name: loginValue.userName,
      userName: loginValue.userName,
      refreshToken: loginValue.refreshToken,
      activeStatus: loginValue.activeStatus,
      email: loginValue.email,
      googleImgUrl: loginValue.googleImgUrl,
      loginType: loginValue.loginType,
      lastActive: loginValue.lastActive,
    };
    localStorage.setItem("userAuth", JSON.stringify(newUserInfo));
    setUserPermission(
      loginValue?.role?.isApproveRoom,
      loginValue?.role?.isApproveRequestRoom
    );
    updateUserInfo(newUserInfo);
    window.location.href = "/dashboard";
  };

  const handleLogout = (isReload: boolean = true) => {
    console.log("logout inside context!");
    setUserInfo(DefaultUserInfo);
    localStorage.removeItem("userAuth");
    if (isReload) window.location.reload();
  };

  const isAuth = (): boolean => {
    if (userInfo.refreshToken) {
      return true;
    }
    return false;
  };

  return (
    <UserAuthContext.Provider
      value={{
        userInfo,
        isAuth,
        getUserInfoFromLocal,
        updateUserInfo,
        handleLoginInContext,
        handleLogout,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthProvider;
