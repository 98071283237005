import React, { useEffect, useState } from "react";
import { Image, Switch, Table } from "antd";
import { PAGE_SIZE, STATUS_CODE } from "../../constants/GlobalConstant";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import {
  Container,
  ModalBtn,
  TableContainer,
  Title,
  Wrapper,
} from "./AdvertisementElements";
import { ColumnsType } from "antd/lib/table";
import {
  AdvertisementDataType,
  AdvertisementResponseDataTypeList,
  AdvertisementResponseTypeList,
} from "../../types/AdvertisementDataType";
import { convertToLocaleDate } from "../../utils/DateUtil";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";
import { getAdvertisementListApi } from "../../services/RouteServices/AdvertisementRouteApi";
import AdvertisementAddModal from "./AdvertisementModal/AdvertisementAddModal";

const Advertisement: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [advertisementList, setAdvertisementList] =
    useState<AdvertisementDataType[]>();

  const columns: ColumnsType<AdvertisementDataType> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image",
      key: "image",
      render: (record) => (
        <>
          <Image
            src={"data:image/png;base64," + record.imgUrl}
            style={{ width: "100px", height: "auto" }}
          />
        </>
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      render: (remark: string) => <>{remark ? remark : "-"}</>,
    },
    {
      title: "Expired Date",
      dataIndex: "expiredDate",
      key: "expiredDate",
      render: (expiredDate: string) => <>{expiredDate ? expiredDate : "-"}</>,
    },
    {
      title: "Created User",
      dataIndex: "createdUser",
      key: "createdUser",
      render: (user: string) => <>{user ? user : "-"}</>,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: Date) => (
        <>{date ? <>{convertToLocaleDate(date)}</> : "-"}</>
      ),
    },
    {
      title: "Updated User",
      dataIndex: "updatedUser",
      key: "updatedUser",
      render: (user: string) => <>{user ? user : "-"}</>,
    },
    {
      title: "Updated Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: Date) => (
        <>{date ? <>{convertToLocaleDate(date)}</> : "-"}</>
      ),
    },
    {
      title: "Deleted User",
      dataIndex: "deletedUser",
      key: "deletedUser",
      render: (user: string) => <>{user ? user : "-"}</>,
    },
    {
      title: "Deleted Date",
      dataIndex: "deletedAt",
      key: "deletedAt",
      render: (date: Date) => (
        <>{date ? <>{convertToLocaleDate(date)}</> : "-"}</>
      ),
    },
    {
      title: "Active",
      key: "active",
      render: (record) => (
        <>
          <Switch
            checked={record.checkedAd}
            onChange={(checked) => onChangeActive(checked, record.id)}
          />
        </>
      ),
    },
  ];

  const onChangeActive = (checked: boolean, recordId: number) => {
    setAdvertisementList((ad: any) =>
      ad.map((record: AdvertisementDataType) => {
        if (record.id === recordId) {
          const updatedRecord = { ...record, checkedAd: checked };
          localStorage.setItem("AD", JSON.stringify(updatedRecord));
          return updatedRecord;
        } else {
          return record;
        }
      })
    );
  };

  useEffect(() => {
    getAdvertisementList();
  }, []);

  const getAdvertisementList = async () => {
    const inputData = {
      offset,
      limit: pageSize,
    };
    await getAdvertisementListApi(inputData)
      .then((response: AdvertisementResponseTypeList) => {
        const responseData: AdvertisementResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setAdvertisementList(responseData.data);
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 100);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setOffset(CalculateOffset(page, pageSize));
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Title>Advertisements</Title>
          <ModalBtn
            onClick={() => {
              setShowModal(true);
            }}
          >
            Add New
          </ModalBtn>
        </Wrapper>
        <TableContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
          <Table
            rowKey={"id"}
            columns={columns}
            dataSource={advertisementList}
            scroll={{ x: 100 }}
            pagination={{
              defaultPageSize: pageSize,
              showSizeChanger: false,
              total: total,
              defaultCurrent: page,
              current: page,
              onChange: (page) => handlePageChange(page),
            }}
            footer={() => (
              <p>
                Total <b>{total}</b> records found.
              </p>
            )}
          />
        </TableContainer>
      </Container>

      {/* Add Modal */}
      <AdvertisementAddModal
        showModal={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

export default Advertisement;
