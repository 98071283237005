import { Modal } from "antd";
import React from "react";
import { UserDeleteModalDataType } from "../../../types/UserDataType";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import { Button, ButtonWrapper, ModalBtn } from "../UserElements";

const UserDeleteModal: React.FC<UserDeleteModalDataType> = ({
  onCancel,
  showRemoveModal,
  error,
  success,
  handleDelete,
  removeUsername,
}) => {
  return (
    <>
      <Modal
        title="Delete User"
        open={showRemoveModal}
        onCancel={onCancel}
        footer={null}
      >
        {error && (
          <AlertMessage
            message={error}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {success && (
          <AlertMessage
            message={success}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <h2>
          Are you sure you want to delete [
          <span style={{ color: "red" }}>{removeUsername}</span>]?
        </h2>
        <ButtonWrapper>
          <Button onClick={onCancel}>Cancel</Button>
          <ModalBtn onClick={handleDelete}>Delete</ModalBtn>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default UserDeleteModal;
