import {
  DeleteMRTLineDetailData,
  EditMRTLineDetailData,
  getAllMrtLineData,
  getDistinctMrtName,
  getMrtInMrtLineById,
  MRTLineDetailData,
  PaginationData,
} from "../../types/MRTLineDataType";
import http from "../axiosService";

export const addMrtInMrtLineApi = async (inputData: MRTLineDetailData) => {
  return await http.post("/mrtInMrtLine/add", inputData);
};

export const getMrtInMrtLineListApi = async (inputData: PaginationData) => {
  return await http.post("/mrtInMrtLine/get/list", inputData);
};

export const getAllMrtInMrtLineListApi = async (
  inputData: getAllMrtLineData
) => {
  return await http.post("/mrtInMrtLine/get/all/list", inputData);
};

export const deleteMrtInMrtLineListApi = async (
  inputData: DeleteMRTLineDetailData
) => {
  return await http.post("/mrtInMrtLine/delete", inputData);
};

export const updateMrtInMrtLineListApi = async (
  inputData: EditMRTLineDetailData
) => {
  return await http.post("/mrtInMrtLine/update", inputData);
};

export const getMrtInMrtLineByIdApi = async (
  inputData: getMrtInMrtLineById
) => {
  return await http.post("/mrtInMrtLine/getById", inputData);
};

export const getDistinctMrtNameApi = async (inputData: getDistinctMrtName) => {
  return await http.post("/mrtInMrtLine/get/list/distinctMrtName", inputData);
};
