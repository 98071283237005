import styled from "styled-components";
import { NodeProps } from "../../types/CommonType";

export const DrawerWrapper = styled.aside<NodeProps>`
  padding-top: 80px;
  margin-left: ${({ isOpen }) => (isOpen ? "0px" : "250px")};
  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const Wrapper = styled.div`
  padding-top: 80px;
`;
