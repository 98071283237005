import React, { useEffect, useState } from "react";
import { ROOM_PAGE_SIZE, STATUS_CODE } from "../../constants/GlobalConstant";
import { getRoomListByUserIdApi } from "../../services/RouteServices/RoomRouteApi";
import {
  RoomResponseData,
  RoomResponseDataTypeList,
  RoomResponseTypeList,
} from "../../types/RoomResponseType";
import { UserInfo } from "../../types/UserAuth";
import { getCurrentUser } from "../../utils/UserUtil";
import { Container, Title } from "./MyRoomsElements";
import RoomCard from "../Shared/RoomCard/RoomCard";
import { Pagination } from "antd";
import Loading from "../Shared/Loading/Loading";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";

const MyRooms = () => {
  const userInfo: UserInfo = getCurrentUser();
  const [rooms, setRooms] = useState<RoomResponseData[]>([]);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(ROOM_PAGE_SIZE);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getRoomListByUserId();
  }, [offset, page]);

  const getRoomListByUserId = async () => {
    const inputData = { userId: userInfo.id, offset, limit: pageSize };
    await getRoomListByUserIdApi(inputData)
      .then((response: RoomResponseTypeList) => {
        const responseData: RoomResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setRooms(responseData.data);
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
              setIsLoading(false);
            }, 100);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
        setIsLoading(false);
      });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setOffset(CalculateOffset(page,pageSize));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Title>My Rooms</Title>
        <RoomCard data={rooms} error={modalError} modal={true} />

        {rooms.length !== 0 && (
          <Pagination
            defaultCurrent={page}
            defaultPageSize={pageSize}
            onChange={(page) => handlePageChange(page)}
            total={total}
          />
        )}
      </Container>
    </>
  );
};

export default MyRooms;
