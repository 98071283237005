import styled from "styled-components";

export const Container = styled.div`
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: #fff;
  text-align: center;
`;

export const CardContainer = styled.div`
  width: 90%;
  margin-top: 10px;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  width: 90%;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  // width: 60%;
  margin: 10px 5px;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 425px) {
    width: 95%;
  }
`;

export const InputLabel = styled.small`
  font-weight: bold;
`;

export const Required = styled.b`
  color: red;
`;

export const ButtonWrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
  // align-items: center;
  margin-top: 5px;

  @media screen and (max-width: 768px) {
    width: 80%;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  outline: none;
  border: none;
  padding: 10px 15px;
  background-color: dodgerblue;
  color: #fff;
  border-radius: 10px;
  font-weight: 400;
  cursor: pointer;
  margin: 5px;
  font-size: 110%;

  &:hover {
    background-color: #007aec;
    color: #fff;
  }
`;

export const CancelButton = styled.button`
  outline: none;
  border: none;
  padding: 10px 15px;
  background-color: #636464;
  color: #fff;
  border-radius: 10px;
  font-weight: 400;
  cursor: pointer;
  margin: 5px;
  font-size: 110%;

  &:hover {
    // background-color: #007aec;
    color: #fff;
  }
`;

export const StepWrapper = styled.div`
  width: 30%;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;
