import React from "react";
import { ImgContainer } from "./ImageCarouselElements";
import { Image } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

const ImageCarousel = (props: any) => {
  const images = props.images;
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          padding: "3px",
        }}
      >
        <ImgContainer itemType={props.room}>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            {images[0] && (
              <SwiperSlide>
                <Image
                  src={"data:image/png;base64," + images[0]}
                  style={{ borderRadius: "20px" }}
                />
              </SwiperSlide>
            )}
            {images[1] && (
              <SwiperSlide>
                <Image
                  src={"data:image/png;base64," + images[1]}
                  style={{ borderRadius: "20px" }}
                />
              </SwiperSlide>
            )}
            {images[2] && (
              <SwiperSlide>
                <Image
                  src={"data:image/png;base64," + images[2]}
                  style={{ borderRadius: "20px" }}
                />
              </SwiperSlide>
            )}
            {images[3] && (
              <SwiperSlide>
                <Image
                  src={"data:image/png;base64," + images[3]}
                  style={{ borderRadius: "20px" }}
                />
              </SwiperSlide>
            )}
          </Swiper>
        </ImgContainer>
      </div>
    </>
  );
};

export default ImageCarousel;
