import React, { useEffect, useState } from "react";
import {
  SidebarMenu,
  SidebarItem,
  SidebarContainer,
  SideContentContainer,
  MenuHeader,
} from "./DrawerElements";
import { FiUsers } from "react-icons/fi";
import { NodeProps } from "../../../types/CommonType";
import DrawerMenu from "./DrawerMenu";
import { BiTrain } from "react-icons/bi";
import {
  RiAdvertisementLine,
  RiFileChartLine,
  RiListSettingsFill,
  RiShieldUserFill,
} from "react-icons/ri";
import {
  AiOutlineHistory,
  AiOutlineSetting,
  AiOutlineTrademark,
} from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { UserInfo, UserPermission } from "../../../types/UserAuth";
import {
  getCurrentUser,
  getUserPermission,
  isSpecialUser,
  setUserPermission,
} from "../../../utils/UserUtil";
import { getUserByIdApi } from "../../../services/RouteServices/UserRouteApi";
import {
  UserResponseDataType,
  UserResponseType,
} from "../../../types/UserResponseType";
import { ROOT_USER_ID, STATUS_CODE } from "../../../constants/GlobalConstant";
import { MdEventNote, MdOutlineDashboard } from "react-icons/md";

const Drawer = ({ isOpen, toggle }: NodeProps) => {
  const location = useLocation();
  const path = location.pathname;
  const permission: UserPermission = getUserPermission();
  const userInfo: UserInfo = getCurrentUser();
  const [userId, setUserId] = useState(userInfo.id || 0);

  useEffect(() => {
    getUserById();
  });

  const getUserById = async () => {
    const inputData = { id: userId };
    await getUserByIdApi(inputData)
      .then((response: UserResponseType) => {
        const responseData: UserResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            const user = responseData.data;
            setUserPermission(
              user.role.isApproveRoom,
              user.role.isApproveRequestRoom
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <SidebarContainer isOpen={isOpen}>
        <SidebarMenu>
          <SidebarItem>
            <SideContentContainer>
              <DrawerMenu
                handleClick={toggle}
                to="/dashboard"
                name={"Dashboard"}
                icon={<MdOutlineDashboard />}
              />
              <MenuHeader>Room</MenuHeader>
              {path === `/my-rooms/edit` ? (
                <DrawerMenu
                  handleClick={toggle}
                  to={`/my-rooms/edit`}
                  name={"My Rooms"}
                  icon={<AiOutlineTrademark />}
                />
              ) : (
                <DrawerMenu
                  handleClick={toggle}
                  to="/my-rooms"
                  name={"My Rooms"}
                  icon={<AiOutlineTrademark />}
                />
              )}
              <DrawerMenu
                handleClick={toggle}
                to="/my-request-rooms"
                name={"My Request Rooms"}
                icon={<AiOutlineTrademark />}
              />
              {permission.isApproveRoom && (
                <DrawerMenu
                  handleClick={toggle}
                  to="/manage-rooms"
                  name={"Manage Rooms"}
                  icon={<AiOutlineTrademark />}
                />
              )}
              {permission.isApproveRequestRoom && (
                <DrawerMenu
                  handleClick={toggle}
                  to="/manage-request-rooms"
                  name={"Manage Request Rooms"}
                  icon={<AiOutlineTrademark />}
                />
              )}
              {/* <DrawerMenu
              handleClick={toggle}
              to="/contents"
              name={"Contents"}
              icon={<TbFileText />}
            /> */}

              {isSpecialUser() && (
                <>
                  <MenuHeader>User</MenuHeader>
                  <DrawerMenu
                    handleClick={toggle}
                    to="/users"
                    name={"Users"}
                    icon={<FiUsers />}
                  />
                  <DrawerMenu
                    handleClick={toggle}
                    to="/role"
                    name={"Role"}
                    icon={<RiShieldUserFill />}
                  />
                </>
              )}
              <MenuHeader>General</MenuHeader>
              <DrawerMenu
                handleClick={toggle}
                to="/setting"
                name={"Setting"}
                icon={<AiOutlineSetting />}
              />
              {userInfo.roleId === ROOT_USER_ID && (
                <DrawerMenu
                  handleClick={toggle}
                  to="/advertisements"
                  name={"Advertisements"}
                  icon={<RiAdvertisementLine />}
                />
              )}
              {isSpecialUser() && (
                <>
                  <DrawerMenu
                    handleClick={toggle}
                    to="/login-history"
                    name={"Login History"}
                    icon={<AiOutlineHistory />}
                  />
                  <DrawerMenu
                    handleClick={toggle}
                    to="/events"
                    name={"Events"}
                    icon={<MdEventNote />}
                  />
                  <DrawerMenu
                    handleClick={toggle}
                    to="/report"
                    name={"Report"}
                    icon={<RiFileChartLine />}
                  />
                  {path === `/mrt-lines/detail` ? (
                    <DrawerMenu
                      handleClick={toggle}
                      to={`/mrt-lines/detail`}
                      name={"MRT Lines"}
                      icon={<BiTrain />}
                    />
                  ) : (
                    <DrawerMenu
                      handleClick={toggle}
                      to="mrt-lines"
                      name={"MRT Lines"}
                      icon={<BiTrain />}
                    />
                  )}
                  <DrawerMenu
                    handleClick={toggle}
                    to="/config"
                    name={"Config"}
                    icon={<RiListSettingsFill />}
                  />
                </>
              )}
            </SideContentContainer>
          </SidebarItem>
        </SidebarMenu>
      </SidebarContainer>
    </>
  );
};

export default Drawer;
