import React, { FC, useEffect, useRef, useState } from "react";
import {
  CardContainer,
  CardItem,
  CardContent,
  DownloadWrapper,
  Desc,
  PostButton,
  ScrollWrapper,
  ImgContainer,
  Card,
  ModalBtnWrapper,
  DownloadIcon,
} from "./RoomCardElements";
import { FaArrowCircleUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { randomImageUtil } from "../../../utils/RandomImageUtil";
import AlertMessage from "../AlertMessage/AlertMessage";
import { convertToLocaleDate } from "../../../utils/DateUtil";
import {
  RoomCardDataType,
  RoomDataType,
  RoomDeleteDataType,
  RoomUpdateStatus,
} from "../../../types/RoomDataType";
import {
  deleteRoomApi,
  updateRoomApprovalStatusApi,
} from "../../../services/RouteServices/RoomRouteApi";
import {
  RoomResponseDataType,
  RoomResponseType,
} from "../../../types/RoomResponseType";
import {
  APPROVE_STATUS,
  LOG_INFO_LEVEL,
  PENDING_STATUS,
  REJECT_STATUS,
  RESERVE_STATUS,
  STATUS_CODE,
} from "../../../constants/GlobalConstant";
import Loading from "../Loading/Loading";
import RoomCardDetailModal from "./RoomCardModal/RoomCardDetailModal";
import RoomCardDeleteModal from "./RoomCardModal/RoomCardDeleteModal";
import { getCurrentUser, getCurrentUserOnly } from "../../../utils/UserUtil";
import { UserInfo } from "../../../types/UserAuth";
import { htmlToImageConvert } from "../../../utils/DownloadUtil";
import RoomApproveOrRejectModal from "./RoomCardModal/RoomApproveOrRejectModal";
import RoomCardImages from "./RoomCardComponents/RoomCardImages";
import RoomCardActionButtons from "./RoomCardComponents/RoomCardActionButtons";
import RoomCardFooter from "./RoomCardComponents/RoomCardFooter";
import RoomCardContent from "./RoomCardComponents/RoomCardContent";
import RoomCardRandomImgShow from "./RoomCardComponents/RoomCardRandomImgShow";
import RoomCardDuplicateModal from "./RoomCardModal/RoomCardDuplicateModal";
import { addEventLog } from "../../../utils/EventLogUtil";

const iconSize = 17;

const RoomCard: FC<RoomCardDataType> = ({
  data,
  error,
  modal,
  isLoading,
  roomType,
  page,
  offset,
}) => {
  const userName = getCurrentUserOnly();
  const userInfo: UserInfo = getCurrentUser();
  const navigate = useNavigate();
  const [showScroll, setShowScroll] = useState<boolean>(false);
  const [minIndex, setMinIndex] = useState<number>(0);
  const [maxIndex, setMaxIndex] = useState<number>(10);
  const [roomList, setRoomList] = useState<RoomDataType[]>(data);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [justShowImg, setJustShowImg] = useState(randomImageUtil());
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [room, setRoom] = useState<RoomDataType>();
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [removeRoomId, setRemoveRoomId] = useState<number>(0);
  const [roomId, setRoomId] = useState<number>(0);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [showDuplicate, setShowDuplicate] = useState<boolean>(false);
  const [duplicatedRoomId, setDuplicatedRoomId] = useState<number>(0);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    setRoomList(data);
    setModalError(error);
  }, [data, error]);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  const handleDelete = async () => {
    const deleteData: RoomDeleteDataType = {
      roomId: removeRoomId,
      deletedUser: userName,
      deletedUserId: userInfo.id,
      refreshToken: userInfo.refreshToken,
    };
    await deleteRoomApi(deleteData)
      .then((response: RoomResponseType) => {
        const responseData: RoomResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            const rooms = [...roomList];
            const filterRooms = rooms.filter(
              (room) => room.id !== removeRoomId
            );
            setRoomList(filterRooms);
            setTimeout(() => {
              setModalSuccess("");
              setShowRemoveModal(false);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handleChangeStatus = async () => {
    const updateData: RoomUpdateStatus = {
      roomId: roomId,
      approvalStatus: status,
      approveUser: userInfo?.userName,
      approveUserId: userInfo?.id,
      refreshToken: userInfo.refreshToken,
    };
    await updateRoomApprovalStatusApi(updateData)
      .then((response: RoomResponseType) => {
        const responseData: RoomResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            window.location.reload();
            setTimeout(() => {
              setModalSuccess("");
              setShowStatusModal(false);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const addEventLogForDownload = async (item: RoomDataType) => {
    const inputData = {
      newEventLog: `downloaded room id ${item.id}`,
      eventLevel: LOG_INFO_LEVEL,
      createdUser: userInfo?.userName || "unknown",
    };
    await addEventLog(inputData);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {roomList.length === 0 ? (
        <RoomCardRandomImgShow showImage={justShowImg} />
      ) : (
        <>
          <CardContainer>
            {modalError && (
              <AlertMessage
                message={modalError}
                visible={true}
                typeAlert={"error"}
                width={"60%"}
              />
            )}
            {/* {modalSuccess && (
              <AlertMessage
                message={modalSuccess}
                visible={true}
                typeAlert={"success"}
                width={"60%"}
              />
            )} */}

            <CardItem>
              {Array.isArray(roomList) &&
                roomList.map(
                  (item: RoomDataType, index: number) =>
                    index >= minIndex &&
                    index < maxIndex && (
                      <Card
                        color={item.createdUserId === userInfo.id ? "gold" : ""}
                        key={item.id + String(Math.random())}
                        ref={(el) => (cardRefs.current[index] = el)}
                      >
                        <CardContent>
                          <ImgContainer>
                            <RoomCardImages
                              room={item}
                              roomType={roomType || ""}
                            />
                          </ImgContainer>
                          <div style={{ width: "90%" }}>
                            <Desc>
                              <DownloadWrapper>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {item?.mrtInMrtLine?.mrtName}
                                </p>
                                <DownloadIcon
                                  onClick={() => {
                                    htmlToImageConvert(
                                      cardRefs.current[index],
                                      item?.mrtInMrtLine?.mrtName
                                    );
                                    addEventLogForDownload(item);
                                  }}
                                />
                              </DownloadWrapper>
                              <RoomCardContent
                                room={item}
                                roomType={roomType || ""}
                              />
                            </Desc>
                            {roomType === "manageRooms" &&
                              item?.updatedAt &&
                              item?.updatedUser && (
                                <div
                                  style={{
                                    padding: "10px 30px",
                                    fontStyle: "italic",
                                    paddingBottom: "0px",
                                  }}
                                >
                                  <h5>
                                    Last updated -{" "}
                                    {convertToLocaleDate(
                                      new Date(item?.updatedAt)
                                    )}
                                  </h5>
                                  <h5>
                                    Last updated user - {item?.updatedUser}
                                  </h5>
                                </div>
                              )}
                            {roomType === "manageRooms" && (
                              <div
                                style={{
                                  padding: "0px 30px",
                                  fontStyle: "italic",
                                }}
                              >
                                {item?.approvalStatus !== PENDING_STATUS && (
                                  <h5>Approve user - {item?.approveUser}</h5>
                                )}
                              </div>
                            )}
                            <div style={{ margin: "10px 30px" }}>
                              {!modal ? (
                                <PostButton
                                  onClick={() => {
                                    navigate(
                                      {
                                        pathname: "/room/details",
                                        search: `?id=${item.id}`,
                                      },
                                      { state: { room: item, page, offset } }
                                    );
                                  }}
                                >
                                  Details...
                                </PostButton>
                              ) : (
                                <RoomCardActionButtons
                                  handleDetailButton={() => {
                                    setIsModalOpen(true);
                                    setRoom(item);
                                  }}
                                  room={item}
                                  roomType={roomType || ""}
                                  handleApproveButton={() => {
                                    setShowStatusModal(true);
                                    setType("approve");
                                    setStatus(APPROVE_STATUS);
                                    setRoomId(item?.id || 0);
                                  }}
                                  handleRejectButton={() => {
                                    setShowStatusModal(true);
                                    setType("reject");
                                    setStatus(REJECT_STATUS);
                                    setRoomId(item?.id || 0);
                                  }}
                                  handleReserveButton={() => {
                                    setShowStatusModal(true);
                                    setType("reserve");
                                    setStatus(RESERVE_STATUS);
                                    setRoomId(item?.id || 0);
                                  }}
                                  handleRemoveButton={() => {
                                    setShowRemoveModal(true);
                                    setRemoveRoomId(item.id || 0);
                                  }}
                                  handleShowDuplicate={() => {
                                    setShowDuplicate(true);
                                    setDuplicatedRoomId(
                                      item.duplicateRoomId || 0
                                    );
                                  }}
                                />
                              )}
                            </div>
                            <RoomCardFooter room={item} />
                          </div>
                        </CardContent>
                      </Card>
                    )
                )}
            </CardItem>
          </CardContainer>
        </>
      )}
      <ScrollWrapper
        onClick={scrollTop}
        style={{ display: showScroll ? "flex" : "none" }}
      >
        <FaArrowCircleUp size={iconSize} />
      </ScrollWrapper>

      {/* Detail Modal */}
      <RoomCardDetailModal
        data={room}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      />

      {/* Delete Modal */}
      <RoomCardDeleteModal
        removeRoomId={removeRoomId}
        open={showRemoveModal}
        onCancel={() => {
          setShowRemoveModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        error={modalError}
        success={modalSuccess}
        handleDelete={handleDelete}
      />

      {/* Room approve or reject modal */}
      <RoomApproveOrRejectModal
        showStatusModal={showStatusModal}
        modalError={modalError}
        modalSuccess={modalSuccess}
        handleChangeStatus={handleChangeStatus}
        onCancel={() => {
          setShowStatusModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        roomId={roomId}
        type={type}
      />

      {/* Show duplicate modal */}
      <RoomCardDuplicateModal
        roomId={duplicatedRoomId}
        roomType={roomType || ""}
        open={showDuplicate}
        onCancel={() => {
          setShowDuplicate(false);
        }}
      />
    </>
  );
};

export default RoomCard;
