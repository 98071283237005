import React, { useState } from "react";
import Card from "../../Shared/Card/Card";
import {
  Container,
  CardContainer,
  Content,
  ContentBlock,
  CardContent,
  Label,
  Value,
  Button,
  ButtonWrapper,
  Wrapper,
  WrapperFull,
  SubmitButton,
  IconContainerTwo,
} from "./PostConfirmElements";
import {
  PostComfirmDataType,
  PostRoomDataType,
} from "../../../types/PostRoomDataType";
import { ImImages, ImLocation2 } from "react-icons/im";
import { BsFillTelephoneFill, BsMailbox2 } from "react-icons/bs";
import { BiCurrentLocation, BiDetail } from "react-icons/bi";
import { MdOutlineAttachMoney, MdDateRange, MdTitle } from "react-icons/md";
import { FaRegStickyNote, FaUserAlt } from "react-icons/fa";
import {
  addRoomApi,
  updateRoomApi,
} from "../../../services/RouteServices/RoomRouteApi";
import { ANONYMOUS_USER, STATUS_CODE } from "../../../constants/GlobalConstant";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../../../types/UserAuth";
import { RcFile } from "antd/lib/upload";
import { RoomDataType } from "../../../types/RoomDataType";
import { getCurrentUser, getCurrentUserOnly } from "../../../utils/UserUtil";
import {
  RoomResponseDataType,
  RoomResponseType,
} from "../../../types/RoomResponseType";

const PostConfirm: React.FC<PostComfirmDataType> = ({
  inputData,
  handleConfirmCancle,
  edit,
  id,
  color,
}) => {
  const navigate = useNavigate();
  const userInfo: UserInfo = getCurrentUser();
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [anonymousUser, setAnonymousUser] = useState<string>(
    inputData.anonymousUser ? ANONYMOUS_USER : ""
  );

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async () => {
    let imgUrl1;
    let imgUrl2;
    let imgUrl3;
    let imgUrl4;

    if (
      inputData?.images?.[0]?.originFileObj ||
      inputData?.images?.[0]?.thumbUrl
    ) {
      if (inputData.images[0].originFileObj) {
        imgUrl1 = await getBase64(inputData.images[0].originFileObj as RcFile);
      } else {
        imgUrl1 = inputData.images[0].thumbUrl;
      }
    }

    if (
      inputData?.images?.[1]?.originFileObj ||
      inputData?.images?.[1]?.thumbUrl
    ) {
      if (inputData.images[1].originFileObj) {
        imgUrl2 = await getBase64(inputData.images[1].originFileObj as RcFile);
      } else {
        imgUrl2 = inputData.images[1].thumbUrl;
      }
    }

    if (
      inputData?.images?.[2]?.originFileObj ||
      inputData?.images?.[2]?.thumbUrl
    ) {
      if (inputData.images[2].originFileObj) {
        imgUrl3 = await getBase64(inputData.images[2].originFileObj as RcFile);
      } else {
        imgUrl3 = inputData.images[2].thumbUrl;
      }
    }

    if (
      inputData?.images?.[3]?.originFileObj ||
      inputData?.images?.[3]?.thumbUrl
    ) {
      if (inputData.images[3].originFileObj) {
        imgUrl4 = await getBase64(inputData.images[3].originFileObj as RcFile);
      } else {
        imgUrl4 = inputData.images[3].thumbUrl;
      }
    }
    const addRoomData: RoomDataType = {
      mrtId: inputData.mrtId,
      mrtName: inputData.location,
      title: inputData.title,
      gender: inputData.gender,
      phoneNo: Number(inputData.phoneNo),
      postalCode: inputData.postalCode,
      address: inputData.address,
      price: Number(inputData.price),
      importantNote: inputData.importantNote,
      details: inputData.detail,
      imgUrl1,
      imgUrl2,
      imgUrl3,
      imgUrl4,
      startDate: inputData.startDate,
      anonymousName: anonymousUser,
    };

    if (edit) {
      addRoomData.id = id;
      addRoomData.updatedUser = userInfo.userName;
      addRoomData.updatedUserId = userInfo.id;
      addRoomData.activeStatus = userInfo?.activeStatus;
      addRoomData.refreshToken = userInfo?.refreshToken;
      await updateRoomApi(addRoomData)
        .then((response: RoomResponseType) => {
          const responseData: RoomResponseDataType = response?.data;
          if (responseData) {
            if (responseData?.status === STATUS_CODE.successCode) {
              setModalError("");
              setModalSuccess(responseData?.message);
              setTimeout(() => {
                navigate("/my-rooms", { replace: true });
              }, 1000);
            } else {
              setModalError(responseData?.message);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setModalError(err?.response?.data?.message || err?.message);
        });
    } else {
      addRoomData.createdUser = getCurrentUserOnly();
      addRoomData.createdUserId = userInfo.id;
      addRoomData.activeStatus = userInfo?.activeStatus;
      addRoomData.refreshToken = userInfo?.refreshToken;
      await addRoomApi(addRoomData)
        .then((response: RoomResponseType) => {
          const responseData: RoomResponseDataType = response?.data;
          if (responseData) {
            if (responseData?.status === STATUS_CODE.successCode) {
              setModalError("");
              setModalSuccess(responseData?.message);
              setTimeout(() => {
                navigate("/room", { replace: true });
              }, 500);
            } else {
              setModalError(responseData?.message);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setModalError(err?.response?.data?.message || err?.message);
        });
    }
  };

  return (
    <>
      <Container>
        <CardContainer>
          <Card>
            <CardContent>
              <Wrapper>
                <Content>
                  <Label>
                    <IconContainerTwo color={color}>
                      <MdTitle />
                    </IconContainerTwo>
                    Title:
                  </Label>
                  <Value>{inputData.title || "-"}</Value>
                </Content>
              </Wrapper>
              <Wrapper>
                <Content>
                  <Label>
                    <IconContainerTwo color={color}>
                      <ImLocation2 />
                    </IconContainerTwo>
                    MRT:
                  </Label>
                  <Value>{inputData.location || "-"}</Value>
                </Content>
                <Content>
                  <Label>
                    <IconContainerTwo color={color}>
                      <FaUserAlt />
                    </IconContainerTwo>
                    Gender:
                  </Label>
                  <Value>{inputData.gender || "-"}</Value>
                </Content>
              </Wrapper>
              <Wrapper>
                <Content>
                  <Label>
                    <IconContainerTwo color={color}>
                      <BsFillTelephoneFill />
                    </IconContainerTwo>
                    Phone No:
                  </Label>
                  <Value>{inputData.phoneNo || "-"}</Value>
                </Content>
                <Content>
                  <Label>
                    <IconContainerTwo color={color}>
                      <BsMailbox2 />
                    </IconContainerTwo>
                    Postal code:
                  </Label>
                  <Value>{inputData.postalCode || "-"}</Value>
                </Content>
              </Wrapper>
              <Wrapper>
                <Content>
                  <Label>
                    <IconContainerTwo color={color}>
                      <BiCurrentLocation />
                    </IconContainerTwo>
                    Address:
                  </Label>
                  <Value>{inputData.address || "-"}</Value>
                </Content>
                <Content>
                  <Label>
                    <IconContainerTwo color={color}>
                      <MdOutlineAttachMoney />
                    </IconContainerTwo>
                    Price:
                  </Label>
                  <Value>{"S$ " + inputData.price || "-"}</Value>
                </Content>
              </Wrapper>
              <Wrapper>
                <Content>
                  <Label>
                    <IconContainerTwo color={color}>
                      <MdDateRange />
                    </IconContainerTwo>
                    Start Date:
                  </Label>
                  <Value>{inputData.startDate || "-"}</Value>
                </Content>
              </Wrapper>
              <WrapperFull>
                <ContentBlock>
                  <Label>
                    <IconContainerTwo color={color}>
                      <ImImages />
                    </IconContainerTwo>
                    Images:
                  </Label>
                  <Value>
                    {inputData.images.map((img) => (
                      <img
                        key={img.name + String(Math.random())}
                        style={{
                          width: 70,
                          height: 70,
                          margin: "5px",
                          border: "1px solid grey",
                        }}
                        alt={"img"}
                        src={img.thumbUrl}
                      />
                    ))}
                  </Value>
                </ContentBlock>
              </WrapperFull>
              <WrapperFull>
                <ContentBlock>
                  <Label>
                    <IconContainerTwo color={color}>
                      <FaRegStickyNote />
                    </IconContainerTwo>
                    Important Note
                  </Label>
                  <Value>{inputData.importantNote || "-"}</Value>
                </ContentBlock>
              </WrapperFull>
              <WrapperFull>
                <ContentBlock>
                  <Label>
                    <IconContainerTwo color={color}>
                      <BiDetail />
                    </IconContainerTwo>
                    Details:
                  </Label>
                  <Value>{inputData.detail || "-"}</Value>
                </ContentBlock>
              </WrapperFull>
              <Wrapper>
                <div style={{ marginTop: "20px" }}>
                  {modalError && (
                    <AlertMessage
                      message={modalError}
                      visible={true}
                      typeAlert={"error"}
                      width={"90%"}
                    />
                  )}
                  {modalSuccess && (
                    <AlertMessage
                      message={modalSuccess}
                      visible={true}
                      typeAlert={"success"}
                      width={"90%"}
                    />
                  )}
                </div>
              </Wrapper>
              <ButtonWrapper>
                <Button onClick={handleConfirmCancle}>Back</Button>
                <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
              </ButtonWrapper>
            </CardContent>
          </Card>
        </CardContainer>
      </Container>
    </>
  );
};

export default PostConfirm;
