import {
  Document,
  Image,
  Page,
  StyleSheet,
  View,
  Text,
  Font,
} from "@react-pdf/renderer";
import React from "react";
import { ORG_AND_WATERMARK_TEXT } from "../../constants/GlobalConstant";
import { RoomPdfDocumentProps } from "../../types/PdfDocumentType";
import { convertDateFormat } from "../../utils/DateUtil";

const logoImgPath = "/images/logo960.jpg";
const fontPath = '/font/pyidaungsu.ttf';

Font.register({
  family: 'BurmeseFont',
  src: fontPath,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    padding: 30,
    fontSize: "12px",
  },
  logoImg: {
    width: "10%",
    height: "auto",
    borderRadius: "50px",
    margin: 20,
  },
  section: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
    padding: 10,
  },
  textWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    padding: "0px 20px",
    backgroundColor: "#fbfbfb",
    color: "white",
  },
  mrtText: {
    padding: "20px 0px",
    color: "black",
    fontSize: "20px",
    fontFamily: 'BurmeseFont',
  },
  titleText: {
    padding: "20px 0px",
    color: "forestgreen",
    fontFamily: 'BurmeseFont',
  },
  text: {
    margin: "10px",
    padding: "5px",
    color: "#434343",
    fontFamily: 'BurmeseFont',
  },
  importantText: {
    margin: "15px",
    padding: "10px",
    color: "white",
    backgroundColor: "orange",
    fontFamily: 'BurmeseFont',
  },
  detailArea: {
    margin: "15px",
    padding: "10px",
    color: "black",
    fontFamily: 'BurmeseFont',
  },
  footerText: {
    color: "green",
    padding: "20px",
    fontFamily: 'BurmeseFont',
  },
});

const RoomPdfDocument: React.FC<RoomPdfDocumentProps> = ({ room }) => {
  
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page} wrap>
          <View>
            <View>
              <View style={styles.textWrapper}>
                <Text style={styles.mrtText}>{room?.mrtName}</Text>
                <Image src={logoImgPath} style={styles.logoImg}/>
              </View>
              <View style={styles.textWrapper}>
                <Text style={styles.titleText}>{room?.title}</Text>
              </View>
              <View style={styles.textWrapper}>
                <Text style={styles.text}>Price S$: {room?.price}</Text>
                <Text style={styles.text}>
                  Start Date: {convertDateFormat(room?.startDate || "")}
                </Text>
              </View>
              <View style={styles.textWrapper}>
                <Text style={styles.text}>Postal Code: {room?.postalCode}</Text>
                <Text style={styles.text}>Phone No: {room?.phoneNo}</Text>
              </View>
              <View style={styles.textWrapper}>
                <Text style={styles.text}>Gender: {room?.gender}</Text>
              </View>
              <View style={styles.textWrapper}>
                <Text style={styles.text}>Address: {room?.address}</Text>
              </View>
              <View style={styles.textWrapper}>
                <Text style={styles.text}>Post Owner: {room?.createdUser}</Text>
              </View>
              <View style={styles.textWrapper}>
                <Text style={styles.importantText}>
                  Important Note: {room?.importantNote}
                </Text>
              </View>
             
              <View style={styles.textWrapper}>
                <Text style={styles.detailArea}>{room?.details}</Text>
              </View>
            </View>

            <View style={styles.section}>
              {room?.imgUrl1 && (
                <Image
                  style={styles.image}
                  src={`data:image/jpeg;base64,${room.imgUrl1}`}
                />
              )}
            </View>
            <View style={styles.section}>
              {room?.imgUrl2 && (
                <Image
                  style={styles.image}
                  src={`data:image/jpeg;base64,${room.imgUrl2}`}
                />
              )}
            </View>
            <View style={styles.section}>
              {room?.imgUrl3 && (
                <Image
                  style={styles.image}
                  src={`data:image/jpeg;base64,${room.imgUrl3}`}
                />
              )}
            </View>
            <View style={styles.section}>
              {room?.imgUrl4 && (
                <Image
                  style={styles.image}
                  src={`data:image/jpeg;base64,${room.imgUrl4}`}
                />
              )}
            </View>

            {/* footer */}
            <View style={styles.textWrapper}>
              <Text style={styles.footerText}>Proudly brought by "{ORG_AND_WATERMARK_TEXT}" team</Text>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

export default RoomPdfDocument;
