import React, { useEffect, useState } from "react";
import {
  AlertButton,
  AlertContainer,
  AlertPage,
  AlertWrapper,
  Container,
  StepWrapper,
} from "./PostRoomElements";
import { Alert, Button, Steps } from "antd";
import PostConfirm from "../PostConfirm/PostConfirm";
import { useNavigate } from "react-router-dom";
import type { DatePickerProps } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { getAllMrtListApi } from "../../../services/RouteServices/MrtLineRouteApi";
import {
  getAllMrtInMrtLineListApi,
  getMrtInMrtLineListApi,
} from "../../../services/RouteServices/MrtLineDetailRouteApi";
import { MRTInMrtLineResponseData } from "../../../types/MRTInMrtLineResponseType";
import { PostRoomDataType } from "../../../types/PostRoomDataType";
import { GenderOption } from "../../../constants/GenderOptionConstant";
import PostRoomForm from "../../Shared/PostRoomForm/PostRoomForm";
import {
  ALLOW_IMG_EXTENSION_LIST,
  PAGE_SIZE,
  ROOT_USER_ID,
  STATUS_CODE,
  TOTAL_ROOM_LIMIT_FOR_EACH_USER,
  TOTAL_ROOM_LIMIT_FOR_ROOT_USER,
} from "../../../constants/GlobalConstant";
import { MRTLineResponseData } from "../../../types/MRTLineResponseType";
import { UserInfo } from "../../../types/UserAuth";
import { getCurrentUser } from "../../../utils/UserUtil";
import { getRoomListByUserIdApi } from "../../../services/RouteServices/RoomRouteApi";
import {
  RoomResponseDataTypeList,
  RoomResponseTypeList,
} from "../../../types/RoomResponseType";
import { BsArrowRightShort } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";

const { Step } = Steps;

const PostRoom: React.FC = () => {
  const userInfo: UserInfo = getCurrentUser();
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [location, setLocation] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [phoneNo, setPhoneNo] = useState<string>("");
  const [postalCode, setPostalCode] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [detail, setDetail] = useState<string>("");
  const [percent, setPercent] = useState<number>(0);
  const [current, setCurrent] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [mrtLines, setMrtLines] = useState<MRTLineResponseData[]>([]);
  const [mrtInMrtLines, setMrtInMrtLines] = useState<
    MRTInMrtLineResponseData[]
  >([]);
  const [mrtInMrtLine, setMrtInMrtLine] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>("");
  const [importantNote, setImportantNote] = useState<string>("");
  const [date, setDate] = useState<any>();
  const [anonymousUser, setAnonymousUser] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [filteredOptions, setFilteredOptions] = useState<
    MRTInMrtLineResponseData[]
  >([]);

  useEffect(() => {
    getAllMrtList();
    getRoomListByUserId();
  }, []);

  const getRoomListByUserId = async () => {
    const inputData = {
      userId: userInfo.id,
      offset: 0,
      limit:
        userInfo.id === ROOT_USER_ID
          ? TOTAL_ROOM_LIMIT_FOR_ROOT_USER
          : TOTAL_ROOM_LIMIT_FOR_EACH_USER,
    };
    await getRoomListByUserIdApi(inputData)
      .then((response: RoomResponseTypeList) => {
        const responseData: RoomResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setTotal(responseData.total);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllMrtList = async () => {
    const result = await getAllMrtListApi();
    setMrtLines(result.data.data);
  };

  const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    // console.log(date, dateString);
    setStartDate(dateString);
    setDate(date);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const inputData: PostRoomDataType = {
    location,
    mrtId: mrtInMrtLine,
    title,
    gender,
    phoneNo,
    postalCode,
    address,
    price,
    importantNote,
    detail,
    images: fileList,
    startDate,
    anonymousUser,
  };

  const handleChange = (value: string) => {
    const isGenderExists = GenderOption.some(
      (gender) => gender.value === value
    );
    if (!isGenderExists) {
      setError("Gender is invalid!");
    } else {
      setError("");
      setGender(value);
    }
  };

  const handleMrtLine = async (value: number) => {
    const inputData = { mrtLineId: value };
    const response = await getAllMrtInMrtLineListApi(inputData);
    setMrtInMrtLines(response.data.data);

    setFilteredOptions(response.data.data);
    setMrtInMrtLine(0);
    setLocation("");
  };

  const handleMrtInMrtLineChange = (value: number) => {
    setMrtInMrtLine(value);
    let selectedMrt: MRTInMrtLineResponseData | null = null;
    let mrt: MRTInMrtLineResponseData;
    for (mrt of mrtInMrtLines) {
      if (mrt.id === value) {
        selectedMrt = mrt;
        break;
      }
    }
    if (selectedMrt) {
      setLocation(selectedMrt.mrtName);
    } else {
      setLocation("");
      setMrtInMrtLine(0);
    }
  };

  const findMrtBySearchKey = (searchMrtText: string) => {
    let tempMatchMrtList: MRTInMrtLineResponseData[] = [];

    mrtInMrtLines.forEach((mrt: MRTInMrtLineResponseData) => {
      const mrtName = mrt.mrtName?.toLocaleLowerCase();
      const searchValue = searchMrtText.toLocaleLowerCase();

      if (mrtName.includes(searchValue)) {
        tempMatchMrtList.push(mrt);
      }
    });
    return tempMatchMrtList;
  };

  const handleSearchMrtLine = (value: string) => {
    if (!value) {
      setFilteredOptions(mrtInMrtLines); // if no search key, just show all mrt
    } else {
      const similiarMrtList = findMrtBySearchKey(value);
      setFilteredOptions(similiarMrtList);
    }
  };

  const handleConfirm = () => {
    setError("");
    setPercent(100);
    setCurrent(current + 1);
    setConfirm(true);
  };

  const handleConfirmCancle = () => {
    setConfirm(false);
    setCurrent(current - 1);
  };

  const handleBack = () => {
    navigate("/room", { replace: true });
  };

  const handleImgChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    let isValid = true;
    newFileList.forEach((file) => {
      const fileName = file?.name?.toLowerCase();
      const fileExtension = fileName.substring(fileName.lastIndexOf("."));
      if (!ALLOW_IMG_EXTENSION_LIST.includes(fileExtension)) {
        isValid = false;
      }
    });

    if (isValid) {
      setError("");
      setFileList(newFileList);
    } else {
      setError(
        "Invalid file type. Please upload only .gif, .png, .jpeg, or .jpg files."
      );
    }
  };

  const handleChangeAnonymousUser = (checked: boolean) => {
    setAnonymousUser(checked);
  };

  let totalRooms =
    userInfo.id === ROOT_USER_ID
      ? TOTAL_ROOM_LIMIT_FOR_ROOT_USER
      : TOTAL_ROOM_LIMIT_FOR_EACH_USER;
  if (total >= totalRooms) {
    return (
      <AlertWrapper>
        <AlertContainer>
          <AlertPage>
            <div
              style={{
                display: "flex",
              }}
            >
              <IoWarningOutline
                color="#ffc53d"
                size={30}
                style={{ marginRight: "5px" }}
              />
              <p style={{ color: "#000", opacity: "0.85" }}>
                You can't post a room because you exceed allowed room limit!
              </p>
            </div>
            <AlertButton onClick={() => navigate("/my-rooms")}>
              your rooms
              <BsArrowRightShort size={30} />
            </AlertButton>
          </AlertPage>
        </AlertContainer>
      </AlertWrapper>
    );
  }

  return (
    <Container>
      <StepWrapper>
        <Steps
          current={current}
          className="step"
          percent={percent}
          style={{ color: "green" }}
          labelPlacement="vertical"
        >
          <Step title="Post" />
          <Step title="Confirm" />
        </Steps>
      </StepWrapper>
      {!confirm && (
        <PostRoomForm
          error={error}
          handleMrtLine={handleMrtLine}
          mrtLines={mrtLines}
          mrtInMrtLine={mrtInMrtLine}
          handleMrtInMrtLineChange={handleMrtInMrtLineChange}
          handleSearchMrtLine={handleSearchMrtLine}
          mrtInMrtLines={mrtInMrtLines}
          filteredOptions={filteredOptions}
          gender={gender}
          handleChange={handleChange}
          genderOption={GenderOption}
          phoneNo={phoneNo}
          handlePhoneNoChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPhoneNo(e.target.value)
          }
          postalCode={postalCode}
          handlePostalCodeChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPostalCode(e.target.value)
          }
          title={title}
          handleTitleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setTitle(e.target.value)
          }
          address={address}
          handleAddressChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAddress(e.target.value)
          }
          price={price}
          handlePriceChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPrice(e.target.value)
          }
          date={date}
          onChangeDate={onChangeDate}
          detail={detail}
          handleDetailChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setDetail(e.target.value)
          }
          importantNote={importantNote}
          handleImportantNoteChange={(
            e: React.ChangeEvent<HTMLTextAreaElement>
          ) => setImportantNote(e.target.value)}
          fileList={fileList}
          handlePreview={handlePreview}
          handleImgChange={handleImgChange}
          previewOpen={previewOpen}
          previewTitle={previewTitle}
          previewImage={previewImage}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          handleBack={handleBack}
          location={location}
          handleChangeAnonymousUser={handleChangeAnonymousUser}
          anonymousUser={anonymousUser}
        />
      )}
      {confirm && (
        <PostConfirm
          inputData={inputData}
          handleConfirmCancle={handleConfirmCancle}
        />
      )}
    </Container>
  );
};

export default PostRoom;
