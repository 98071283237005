import { Alert } from "antd";
import React from "react";
import { RoomCardRandomImgShowProps } from "../../../../types/RoomDataType";
import { CardWrapper, RandomImageContainer } from "../RoomCardElements";

const RoomCardRandomImgShow: React.FC<RoomCardRandomImgShowProps> = ({
  showImage,
}) => {
  return (
    <>
      <RandomImageContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Alert
            message="No room has not been listed"
            type="warning"
            style={{ width: "90%" }}
            showIcon
          />
          <CardWrapper>
            <img
              src={showImage}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "10px",
              }}
              alt="justShowImg"
            />
          </CardWrapper>
        </div>
      </RandomImageContainer>
    </>
  );
};

export default RoomCardRandomImgShow;
