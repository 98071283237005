import styled from "styled-components";

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const DeactivateButton = styled.div`
  padding: 10px 40px;
  background-color: #d1961d;
  color: white;
  font-size: 120%;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #a8071a;
  }
`;

export const DeleteButton = styled.div`
  padding: 10px 40px;
  background-color: red;
  color: white;
  font-size: 120%;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #a8071a;
  }
`;

export const DeleteBtn = styled.button`
  outline: none;
  border: none;
  background-color: red;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #da2a3f;
    color: #fff;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
`;
export const ModalBtn = styled.button`
  outline: none;
  border: none;
  background-color: #d1961d;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #a8071a;
    color: #fff;
  }
`;
export const Button = styled.button`
  outline: none;
  border: none;
  background: #636464;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  color: #fff;

  &:hover {
    // background: #007aec;
    color: #fff;
  }
`;

export const Badge = styled.span`
  background-color: ${(props) =>
    props.color === "green"
      ? "#bae637"
      : props.color === "yellow"
      ? "#ffe58f"
      : "#b37feb"};
  border-radius: 10px;
  opacity: 0.9;
  padding: 5px 15px;
  font-size: 70%;
  cursor: pointer;
  margin: 0px 3px;
  color: #1e1e1e;

  &:hover {
    opacity: 0.75;
  }
`;
