import React, { FC, useEffect, useState } from "react";
import { DatePicker, DatePickerProps, Pagination, Select } from "antd";
import {
  RoomResponseData,
  RoomResponseDataTypeList,
  RoomResponseTypeList,
} from "../../types/RoomResponseType";
import {
  Container,
  Title,
  SelectWrapper,
  InputWrapper,
} from "../MyRooms/MyRoomsElements";
import RoomCard from "../Shared/RoomCard/RoomCard";
import { getRoomListApi } from "../../services/RouteServices/RoomRouteApi";
import { ALL_STATUS, ROOM_PAGE_SIZE, STATUS_CODE } from "../../constants/GlobalConstant";
import { ApprovalStatusOptions } from "../../constants/ApprovalStatusOptionConstants";
import Loading from "../Shared/Loading/Loading";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";

const ManageRooms: FC = () => {
  const [rooms, setRooms] = useState<RoomResponseData[]>([]);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(ROOM_PAGE_SIZE);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  useEffect(() => {
    getRoomList();
  }, [offset, page, startDate, endDate]);

  const getRoomList = async () => {
    const inputData = {
      offset,
      limit: pageSize,
      approvalStatus: ALL_STATUS,
      startDate,
      endDate,
    };
    await getRoomListApi(inputData)
      .then((response: RoomResponseTypeList) => {
        const responseData: RoomResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setRooms(responseData.data);
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setIsLoading(false);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setModalError(responseData?.message);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
        setIsLoading(false);
      });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
    setOffset(CalculateOffset(page,pageSize));
  };

  const handleStatusChange = async (value: string) => {
    const inputData = { offset, limit: pageSize, approvalStatus: value };
    await getRoomListApi(inputData)
      .then((response: RoomResponseTypeList) => {
        const responseData: RoomResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setRooms(responseData.data);
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setIsLoading(false);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setModalError(responseData?.message);
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
        setIsLoading(false);
      });
  };

  const onChangeStartDate: DatePickerProps["onChange"] = (date, dateString) => {
    setStartDate(dateString);
    setOffset(0);
    setPage(1);
  };
  const onChangeEndDate: DatePickerProps["onChange"] = (date, dateString) => {
    setEndDate(dateString);
    setOffset(0);
    setPage(1);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Title>Manage Rooms</Title>
        <InputWrapper>
          <SelectWrapper>
            <Select
              defaultValue={ALL_STATUS}
              style={{
                width: "100%",
                fontSize: "90%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              size="large"
              onChange={handleStatusChange}
              options={ApprovalStatusOptions}
            />
          </SelectWrapper>
          <DatePicker
            placeholder="Start Date"
            size="large"
            onChange={onChangeStartDate}
            style={{ margin: "0px 5px" }}
          />
          <DatePicker
            placeholder="End Date"
            size="large"
            onChange={onChangeEndDate}
          />
        </InputWrapper>
        <RoomCard
          data={rooms}
          roomType={"manageRooms"}
          error={modalError}
          modal={true}
        />

        {rooms.length !== 0 && (
          <Pagination
            defaultCurrent={page}
            current={page}
            showSizeChanger={false}
            defaultPageSize={pageSize}
            onChange={(page) => handlePageChange(page)}
            total={total}
          />
        )}
      </Container>
    </>
  );
};

export default ManageRooms;
