import { Modal } from "antd";
import React from "react";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { RiShieldUserLine } from "react-icons/ri";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import {
  Button,
  ButtonWrapper,
  InputContainer,
  InputLabel,
  ModalBtn,
  Required,
} from "../RoleElements";
import { Input as AntInput } from "antd";
import { RoleAddModalProps } from "../../../types/RoleDataType";

const RoleAddModal: React.FC<RoleAddModalProps> = ({
  showModal,
  handleCancel,
  modalError,
  modalSuccess,
  name,
  remark,
  handleChangeName,
  handleChangeRemark,
  handleAdd,
}) => {
  return (
    <Modal
      title="Add New Role"
      open={showModal}
      onCancel={handleCancel}
      footer={null}
    >
      <InputContainer>
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {modalSuccess && (
          <AlertMessage
            message={modalSuccess}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
      </InputContainer>
      <InputContainer>
        <InputLabel>
          Role Name<Required>*</Required>
        </InputLabel>
        <AntInput
          size="large"
          placeholder="Role Name"
          prefix={<RiShieldUserLine />}
          onChange={handleChangeName}
          value={name}
          maxLength={50}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Remark</InputLabel>
        <AntInput
          size="large"
          placeholder="Remark"
          prefix={<AiOutlineCheckSquare />}
          onChange={handleChangeRemark}
          value={remark}
          maxLength={50}
        />
      </InputContainer>
      <ButtonWrapper>
        <Button onClick={handleCancel}>Cancel</Button>
        <ModalBtn onClick={handleAdd}>Add</ModalBtn>
      </ButtonWrapper>
    </Modal>
  );
};

export default RoleAddModal;
