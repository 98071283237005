import {
  CHECK_SPECIAL_ROLE_ID,
  NORMAL_ROLE_ID,
} from "../constants/GlobalConstant";
import { UserInfo, UserPermission } from "../types/UserAuth";

export const getCurrentUser = (): UserInfo => {
  const cUserAuth: UserInfo = JSON.parse(
    localStorage.getItem("userAuth") || "{}"
  );
  return cUserAuth;
};

export const getCurrentUserOnly = (): string => {
  const cUser = getCurrentUser()?.userName || "";
  return cUser;
};

export const isSpecialUser = (): boolean => {
  const cRoleId = getCurrentUser()?.roleId;
  return cRoleId && cRoleId <= CHECK_SPECIAL_ROLE_ID ? true : false;
};

export const isNormalRole = (roleId: number): boolean => {
  return roleId === NORMAL_ROLE_ID ? false : true;
};

export const setUserPermission = (
  isApproveRoom?: boolean,
  isApproveRequestRoom?: boolean
) => {
  const permission: UserPermission = {
    isApproveRoom,
    isApproveRequestRoom,
  };
  localStorage.setItem("userPermission", JSON.stringify(permission));
};

export const getUserPermission = () => {
  return JSON.parse(localStorage.getItem("userPermission") || "{}");
};

export const getAdvertisement = () => {
  return JSON.parse(localStorage.getItem("AD") || "{}");
};
