import React, { useEffect, useState } from "react";
import { Form, Input, Switch } from "antd";
import Card from "../Shared/Card/Card";
import {
  CardContainer,
  Button,
  ButtonWrapper,
  CardWrapper,
} from "./ConfigElements";
import {
  addEmailConfigApi,
  getEmailConfigApi,
  updateEmailConfigApi,
} from "../../services/RouteServices/EmailConfigRouteApi";
import {
  EmailConfigResponseData,
  EmailConfigResponseDataType,
  EmailConfigResponseDataTypeList,
  EmailConfigResponseType,
  EmailConfigResponseTypeList,
} from "../../types/EmailConfigResponseType";
import { STATUS_CODE } from "../../constants/GlobalConstant";
import { UserInfo } from "../../types/UserAuth";
import { getCurrentUser } from "../../utils/UserUtil";
import { EmailConfigData } from "../../types/EmailConfigDataType";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import {
  MaintenanceConfigData,
  MaintenanceConfigResponseData,
  MaintenanceConfigResponseDataType,
  MaintenanceConfigResponseType,
} from "../../types/MaintenanceConfigDataType";
import {
  addMaintenanceConfigApi,
  getMaintenanceConfigApi,
  updateMaintenanceConfigApi,
} from "../../services/RouteServices/MaintenanceConfigRouteApi";
import { convertDateFormat } from "../../utils/DateUtil";

const Config: React.FC = () => {
  const userInfo: UserInfo = getCurrentUser();
  const [config, setConfig] = useState<EmailConfigResponseData>();
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [mainModalError, setMainModalError] = useState<string>("");
  const [mainModalSuccess, setMainModalSuccess] = useState<string>("");
  const [added, setAdded] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [hostName, setHostName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [configId, setConfigId] = useState<number>();
  const [details, setDetails] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [maintenanceConfig, setMaintenanceConfig] =
    useState<MaintenanceConfigResponseData>();
  const [mainConfigId, setMainConfigId] = useState<number>(0);
  const [active, setActive] = useState<boolean>(
    maintenanceConfig?.active ? true : false
  );

  useEffect(() => {
    getEmailConfigList();
    getMaintenanceConfigList();
  }, [added, updated]);

  const getEmailConfigList = async () => {
    await getEmailConfigApi()
      .then((response: EmailConfigResponseType) => {
        const responseData: EmailConfigResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setConfig(responseData.data);
            const config = responseData.data;
            setHostName(config?.hostName);
            setEmail(config?.email);
            setPassword(config?.password);
            setToken(config?.token);
            setConfigId(config?.id);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const getMaintenanceConfigList = async () => {
    await getMaintenanceConfigApi()
      .then((response: MaintenanceConfigResponseType) => {
        const responseData: MaintenanceConfigResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setMaintenanceConfig(responseData.data);
            const config = responseData.data;
            setDetails(config?.details);
            setRemark(config?.remark);
            setActive(config?.active);
            setMainConfigId(config?.id);
            setMainModalError("");
            setMainModalSuccess(responseData?.message);
            setTimeout(() => {
              setMainModalSuccess("");
            }, 1000);
          } else {
            setMainModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setMainModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handleAdd = async () => {
    // if (validateInput()) {
    // pass all validation to save
    const newEmailConfigData: EmailConfigData = {
      hostName: hostName,
      email: email,
      password: password,
      token: token,
      createdUser: userInfo.userName,
    };
    await addEmailConfigApi(newEmailConfigData)
      .then((response: EmailConfigResponseType) => {
        const responseData: EmailConfigResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
              setAdded(!added);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
    // }
  };

  const handleAddMaintenanceConfig = async () => {
    const newConfigData: MaintenanceConfigData = {
      details,
      remark,
      active,
      createdUser: userInfo.userName,
    };
    await addMaintenanceConfigApi(newConfigData)
      .then((response: MaintenanceConfigResponseType) => {
        const responseData: MaintenanceConfigResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setMainModalSuccess(responseData?.message);
            setTimeout(() => {
              setMainModalSuccess("");
              setAdded(!added);
            }, 1000);
          } else {
            setMainModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setMainModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handleUpdate = async () => {
    // if (validateInput()) {
    // pass all validation to save
    const editEmailConfigData: EmailConfigData = {
      id: mainConfigId,
      hostName: hostName,
      email: email,
      password: password,
      token: token,
      updatedUser: userInfo.userName,
    };
    await updateEmailConfigApi(editEmailConfigData)
      .then((response: EmailConfigResponseType) => {
        const responseData: EmailConfigResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
              setUpdated(!updated);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
    // }
  };

  const handleUpdateMaintenanceConfig = async () => {
    const editMaintenanceConfigData: MaintenanceConfigData = {
      id: configId,
      details,
      active,
      remark,
      updatedUser: userInfo.userName,
    };
    await updateMaintenanceConfigApi(editMaintenanceConfigData)
      .then((response: MaintenanceConfigResponseType) => {
        const responseData: MaintenanceConfigResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setMainModalError("");
            setMainModalSuccess(responseData?.message);
            setTimeout(() => {
              setMainModalSuccess("");
              setUpdated(!updated);
            }, 1000);
          } else {
            setMainModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setMainModalError(err?.response?.data?.message || err?.message);
      });
  };

  // const validateInput = () => {
  //   let isValidSuccess = false;
  //   setModalSuccess("");
  //   if (!hostName) {
  //     setModalError("Host Name is required!");
  //   } else if (!email) {
  //     setModalError("Email is required!");
  //   } else if (!password) {
  //     setModalError("Password is required!");
  //   } else if (!token) {
  //     setModalError("Token is required!");
  //   } else {
  //     isValidSuccess = true;
  //   }
  //   return isValidSuccess;
  // };

  const handleChangeActive = (checked: boolean) => {
    setActive(checked);
  };

  return (
    <div style={{ padding: "30px" }}>
      <h1 style={{ color: "#fff" }}>Configurations</h1>
      <CardWrapper>
        <CardContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
          <Card>
            <h4>Email Config</h4>
            <Form
              size="large"
              labelCol={{ span: 6 }}
              layout="horizontal"
              style={{ margin: "20px" }}
            >
              <Form.Item label="Host Name">
                <Input
                  placeholder="Host Name"
                  value={hostName}
                  onChange={(e) => setHostName(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Password">
                <Input.Password
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item label="Token">
                <Input
                  placeholder="Token"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                />
              </Form.Item>
            </Form>
            <ButtonWrapper>
              {!config ? (
                <Button
                  style={{ backgroundColor: "green" }}
                  onClick={handleAdd}
                >
                  Save
                </Button>
              ) : (
                <Button onClick={handleUpdate}>Update</Button>
              )}
            </ButtonWrapper>
          </Card>
        </CardContainer>
        <CardContainer>
          {mainModalError && (
            <AlertMessage
              message={mainModalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {mainModalSuccess && (
            <AlertMessage
              message={mainModalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
          <Card>
            <h4>Maintenance Config</h4>
            <Form
              size="large"
              labelCol={{ span: 6 }}
              layout="horizontal"
              style={{ margin: "20px" }}
            >
              <Form.Item label="Details">
                <TextArea
                  placeholder="Details"
                  value={details}
                  maxLength={500}
                  rows={3}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Remark">
                <Input
                  placeholder="Remark"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Active">
                <Switch checked={active} onChange={handleChangeActive} />
              </Form.Item>
              {maintenanceConfig?.updatedAt && (
                <div
                  style={{
                    fontStyle: "italic",
                    fontWeight: "500",
                    marginLeft: "20px",
                  }}
                >
                  Last Updated -{" "}
                  {convertDateFormat(maintenanceConfig.updatedAt)}
                </div>
              )}
              {maintenanceConfig?.updatedUser && (
                <>
                  <div
                    style={{
                      fontStyle: "italic",
                      fontWeight: "500",
                      marginLeft: "20px",
                    }}
                  >
                    Updated User - {maintenanceConfig.updatedUser}
                  </div>
                </>
              )}
            </Form>
            <ButtonWrapper>
              {!maintenanceConfig ? (
                <Button
                  style={{ backgroundColor: "green" }}
                  onClick={handleAddMaintenanceConfig}
                >
                  Save
                </Button>
              ) : (
                <Button onClick={handleUpdateMaintenanceConfig}>Update</Button>
              )}
            </ButtonWrapper>
          </Card>
        </CardContainer>
      </CardWrapper>
    </div>
  );
};

export default Config;
