import {
  DeleteMRTLineData,
  EditMRTLineData,
  MRTLineData,
  PaginationData,
} from "../../types/MRTLineDataType";
import http from "../axiosService";

export const addMrtLineApi = async (inputData: MRTLineData) => {
  return await http.post("/mrtLine/add", inputData);
};

export const getMrtListApi = async (inputData: PaginationData) => {
  return await http.post("/mrtLine/get/list", inputData);
};

export const getAllMrtListApi = async () => {
  return await http.post("/mrtLine/get/all/list");
};

export const updateMrtLineApi = async (editData: EditMRTLineData) => {
  return await http.post("/mrtLine/update", editData);
};

export const deleteMrtLineApi = async (deleteData: DeleteMRTLineData) => {
  return await http.post("/mrtLine/delete", deleteData);
};

export const deleteMrtInMrtLineByMrtLineIdApi = async (
  deleteData: DeleteMRTLineData
) => {
  return await http.post("/mrtInMrtLine/deleteByMrtLineId", deleteData);
};
