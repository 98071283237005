import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import styled from "styled-components";

export const Container = styled.div`
  padding: 30px;
  padding-top: 10px;
`;

export const Card = styled.div`
  background-color: ${(props) =>
    props.color === "gold" ? "#fff7e6" : "#f0f0f0"};
  // background-color: #f0f0f0;
  color: #000;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

export const CardContainer = styled.div`
  margin: 10px;
  padding-bottom: 10px;
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

export const Img = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 10px;
`;

export const Title = styled.h2`
  color: #fff;
`;

export const CardContent = styled.div`
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  padding: 20px;
`;

export const Text = styled.p`
  margin-top: 10px;
  text-align: justify;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.div`
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 90%;
  background-color: dodgerblue;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 5px;

  &:hover {
    background-color: #3d6009;
    color: #fff;
  }

  @media screen and (max-width: 425px) {
    padding: 10px 25px;
  }
`;

export const ImgWrapper = styled.div`
  // width: 70%;
  margin-bottom: 10px;
  justify-content: center;
  text-align: center;
`;

export const BtnWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: center;
  flex-warp: wrap;
`;

export const EditButton = styled(AiOutlineEdit)`
  color: dodgerblue;
  cursor: pointer;
  font-size: 170%;
  margin-right: 10px;

  &:hover {
    color: #007aec;
  }
`;

export const RemoveButton = styled(RiDeleteBin6Line)`
  color: red;
  cursor: pointer;
  font-size: 140%;
  margin-right: 20px;

  &:hover {
    color: #da2a3f;
  }
`;

export const ApproveBtn = styled.button`
  outline: none;
  border: none;
  background-color: green;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #3f6600;
    color: #fff;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
`;

export const DeleteBtn = styled.button`
  outline: none;
  border: none;
  background-color: red;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #da2a3f;
    color: #fff;
  }
`;

export const ModalButton = styled.button`
  outline: none;
  border: none;
  background: #636464;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  color: #fff;

  &:hover {
    // background: #007aec;
    color: #fff;
  }
`;

export const PostOwnerWrapper = styled.p`
  font-size: 85%;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;

export const Badge = styled.span`
  background-color: ${(props) =>
    props.color === "green"
      ? "#bae637"
      : props.color === "yellow"
      ? "#ffe58f"
      : props.color === "red"
      ? "#ff7875"
      : props.color === "pink"
      ? "#ff85c0"
      : "#b37feb"};
  border-radius: 10px;
  opacity: 0.9;
  padding: 5px 15px;
  font-size: 90%;
  position: absolute;
  top: 4%;
  z-index: 2;
  left: 5%;
  font-weight: 500;
`;
