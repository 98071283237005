import React, { useEffect, useState } from "react";
import { DatePicker, DatePickerProps, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { PAGE_SIZE, STATUS_CODE } from "../../constants/GlobalConstant";
import { getEventLogListApi } from "../../services/RouteServices/EventLogRouteApi";
import {
  EventResponseData,
  EventResponseDataTypeList,
  EventResponseTypeList,
} from "../../types/EventDataType";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import { Container, TableContainer, Wrapper } from "./EventElements";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";
import { convertToLocaleDate } from "../../utils/DateUtil";

const Event = () => {
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [events, setEvents] = useState<EventResponseData[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const getEventColor = (event: EventResponseData) => {
    const color =
      event.eventLevel === "new"
        ? "green"
        : event.eventLevel === "warn"
        ? "orange"
        : event.eventLevel === "alert"
        ? "red"
        : "";
    return color;
  };

  const columns: ColumnsType<EventResponseData> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        const color = getEventColor(record);
        return <span style={{ color }}>{text}</span>;
      },
    },
    {
      title: "Event Log",
      dataIndex: "eventLog",
      key: "eventLog",
      render: (text, record) => {
        const color = getEventColor(record);
        return <span style={{ color }}>{text}</span>;
      },
    },
    {
      title: "Event Level",
      dataIndex: "eventLevel",
      key: "eventLevel",
      render: (text, record) => {
        const color = getEventColor(record);
        return <span style={{ color }}>{text}</span>;
      },
    },
    {
      title: "Created User",
      dataIndex: "createdUser",
      key: "createdUser",
      render: (text, record) => {
        const color = getEventColor(record);
        return <span style={{ color }}>{text}</span>;
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: Date, record) => {
        const color = getEventColor(record);
        return (
          <span style={{ color }}>
            {date ? <>{convertToLocaleDate(date)}</> : "-"}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    getEventLogList();
  }, [searchText, page, startDate, endDate]);

  const getEventLogList = async () => {
    const inputData = {
      offset,
      limit: pageSize,
      searchText,
      startDate,
      endDate,
    };

    await getEventLogListApi(inputData)
      .then((response: EventResponseTypeList) => {
        const responseData: EventResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setEvents(responseData.data);
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handlePageChange = (page: number) => {
    setOffset(CalculateOffset(page, pageSize));
    setPage(page);
  };

  const onChangeStartDate: DatePickerProps["onChange"] = (date, dateString) => {
    setStartDate(dateString);
    setOffset(0);
    setPage(1);
  };
  const onChangeEndDate: DatePickerProps["onChange"] = (date, dateString) => {
    setEndDate(dateString);
    setOffset(0);
    setPage(1);
  };

  return (
    <Container>
      <h1 style={{ color: "#fff" }}>Events</h1>
      <Wrapper>
        <Input
          placeholder="Search"
          style={{ width: "25%" }}
          size="large"
          onChange={(e) => {
            setSearchText(e.target.value);
            setOffset(0);
            setPage(1);
          }}
        />
        <DatePicker
          placeholder="Start Date"
          size="large"
          onChange={onChangeStartDate}
          style={{ margin: "0px 5px" }}
        />
        <DatePicker
          placeholder="End Date"
          size="large"
          onChange={onChangeEndDate}
        />
      </Wrapper>
      <TableContainer>
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {modalSuccess && (
          <AlertMessage
            message={modalSuccess}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <Table
          rowKey={"id"}
          columns={columns}
          dataSource={events}
          scroll={{ x: 100 }}
          pagination={{
            defaultPageSize: pageSize,
            showSizeChanger: false,
            total: total,
            defaultCurrent: page,
            current: page,
            onChange: (page) => handlePageChange(page),
          }}
          footer={() => (
            <p>
              Total <b>{total}</b> records found.
            </p>
          )}
        />
      </TableContainer>
    </Container>
  );
};

export default Event;
