import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { CSVLink } from "react-csv";
import {
  CURRENT_MONTH,
  CURRENT_YEAR,
  STATUS_CODE,
} from "../../constants/GlobalConstant";
import { Bar } from "react-chartjs-2";
import { Select } from "antd";
import moment from "moment";
import {
  ChartContainer,
  CSVDownloadIcon,
  DownloadIcon,
  DownloadIconWrapper,
  SelectWrapper,
  BadgeWrapper,
  ChartWrapper,
} from "./ReportElements";
import { htmlToImageConvert } from "../../utils/DownloadUtil";
import { ReportPropsDataType } from "../../types/ReportPropsDataType";
import {
  getRoomListByMonthApi,
  getRoomListByYearApi,
} from "../../services/RouteServices/RoomRouteApi";
import {
  RoomResponseData,
  RoomResponseDataTypeList,
  RoomResponseTypeList,
} from "../../types/RoomResponseType";
import { getLocalCurrentDateOnlyStr } from "../../utils/DateUtil";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    x: {
      title: {
        display: true,
        text: "Room Created Date",
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
        precision: 0,
      },
      title: {
        display: true,
        text: "Room Count",
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Room Report",
    },
  },
};

let data;
let csvData: string[][];

const RoomReportBarChart: React.FC<ReportPropsDataType> = ({
  labels,
  yearOption,
  monthOption,
  getLabelForMonth,
}) => {
  const [roomList, setRoomList] = useState<RoomResponseData[]>([]);
  const [month, setMonth] = useState(CURRENT_MONTH);
  const [year, setYear] = useState(CURRENT_YEAR);
  const [type, setType] = useState<string>("month");
  const elementRef = useRef<HTMLDivElement>(null);

  if (type === "month") {
    const roomCountMap = new Map();
    roomList.forEach((room) => {
      const createdDate = moment(room.createdAt).format("YYYY-MM-DD"); // Format the created date
      if (roomCountMap.has(createdDate)) {
        roomCountMap.set(createdDate, roomCountMap.get(createdDate) + 1); // Increment the count for existing date
      } else {
        roomCountMap.set(createdDate, 1); // Set count to 1 for new date
      }
    });
    const labelForMonth = getLabelForMonth(month, year);

    csvData = [
      ["Published Date", "Room Count"],
      ...labelForMonth.map((date) => [date, roomCountMap.get(date) ?? 0]),
    ];
    data = {
      labels: labelForMonth,
      datasets: [
        {
          label: "Room Count",
          data: labelForMonth.map((date) => roomCountMap.get(date) || 0),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
  } else {
    const roomCountMap = new Map();
    roomList.forEach((room) => {
      const createdDate = moment(room.createdAt);
      const month = createdDate.format("MMMM"); // Get the month name (e.g., January, February)
      const count = roomCountMap.get(month) || 0; // Get the current count or default to 0
      roomCountMap.set(month, count + 1); // Increment the count for the month
    });
    csvData = [
      ["Published Month", "Room Count"],
      ...labels.map((date) => [date, roomCountMap.get(date) ?? 0]),
    ];
    data = {
      labels,
      datasets: [
        {
          label: "Room Count",
          data: labels.map((date) => roomCountMap.get(date) || 0),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
  }

  useEffect(() => {
    getRoomList();
  }, [month, year]);

  const getRoomList = async () => {
    if (type === "month" && month) {
      await getRoomListByMonthApi({ month, year })
        .then((response: RoomResponseTypeList) => {
          const responseData: RoomResponseDataTypeList = response?.data;
          if (responseData) {
            if (responseData?.status === STATUS_CODE.successCode) {
              setRoomList(responseData.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await getRoomListByYearApi({ year })
        .then((response: RoomResponseTypeList) => {
          const responseData: RoomResponseDataTypeList = response?.data;
          if (responseData) {
            if (responseData?.status === STATUS_CODE.successCode) {
              setRoomList(responseData.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeMonth = (value: string) => {
    setMonth(value);
    setType("month");
  };

  const handleChangeYear = (value: string) => {
    setYear(value);
    setType("year");
  };

  return (
    <>
      <ChartContainer ref={elementRef}>
        <BadgeWrapper>
          <SelectWrapper>
            <Select
              defaultValue={year}
              style={{ width: 120, marginRight: "4px" }}
              onChange={handleChangeYear}
              options={yearOption}
            />
            <Select
              defaultValue={month}
              style={{ width: 120 }}
              onChange={handleChangeMonth}
              options={monthOption}
            />
            <h4 style={{ marginLeft: "10px" }}>Total : {roomList.length}</h4>
          </SelectWrapper>
          <DownloadIconWrapper>
            <DownloadIcon
              onClick={() =>
                htmlToImageConvert(
                  elementRef.current,
                  `roomReport-${getLocalCurrentDateOnlyStr()}`
                )
              }
            />
            <CSVLink
              data={csvData}
              filename={`roomReport-${getLocalCurrentDateOnlyStr()}.csv`}
            >
              <CSVDownloadIcon />
            </CSVLink>
          </DownloadIconWrapper>
        </BadgeWrapper>
        <ChartWrapper>
          <Bar
            data={data}
            options={options}
            style={{ width: "100%", height: "100%" }}
          />
        </ChartWrapper>
      </ChartContainer>
    </>
  );
};

export default RoomReportBarChart;
