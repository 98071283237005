import styled from "styled-components";

export const Container = styled.div`
  padding: 30px;
`;

export const CardContainer = styled.div`
  margin: 10px;
  padding-bottom: 10px;
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;

export const Title = styled.h2`
  color: #fff;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

