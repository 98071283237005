import React, { useEffect, useState } from "react";
import {
  Title,
  Container,
  Header,
  PostButton,
  Wrapper,
  SelectWrapper,
  AutoCompleteWrapper,
  ButtonWrapper,
} from "./RoomElements";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AutoComplete,
  DatePicker,
  DatePickerProps,
  Pagination,
  Select,
} from "antd";
import {
  getRoomListApi,
  getRoomListDistinctNameApi,
} from "../../services/RouteServices/RoomRouteApi";
import {
  APPROVE_STATUS,
  ROOM_PAGE_SIZE,
  STATUS_CODE,
} from "../../constants/GlobalConstant";
import {
  RoomResponseData,
  RoomResponseDataTypeList,
  RoomResponseTypeList,
} from "../../types/RoomResponseType";
import RoomCard from "../Shared/RoomCard/RoomCard";
import { RoomPaginationData } from "../../types/RoomDataType";
import { getDistinctMrtNameApi } from "../../services/RouteServices/MrtLineDetailRouteApi";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";

const Room: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(
    location.state?.page || 1
  );
  const [pageSize, setPageSize] = useState<number>(ROOM_PAGE_SIZE);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(location.state?.offset || 0);
  const [roomList, setRoomList] = useState<RoomResponseData[]>([]);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [mrtNameList, setMrtNameList] = useState<{ value: string }[]>([]);
  const [autoSerchOptions, setAutoSerchOptions] = useState<{ value: string }[]>(
    []
  );

  const onSelect = (inputTxt: string) => {
    // console.log("onSelect", inputTxt);
    handleSearch(inputTxt);
  };

  const onSearchText = async (searchText: string) => {
    if (!searchText) {
      setAutoSerchOptions([]);
      handleSearch("");
    } else {
      var optionArr = [];
      optionArr.push({ value: searchText });

      /* get the suggestion list from db */
      const data = await getDistinctName(searchText);
      data?.forEach((opt: { value: string }) => {
        optionArr.push(opt);
      });
      setAutoSerchOptions(optionArr);
    }
  };

  const getDistinctName = async (searchText: string) => {
    const inputData = {
      searchText,
    };

    const res = await getRoomListDistinctNameApi(inputData);
    return res?.data?.data;
  };

  const handleChangeSearchRoom = (data: string) => {
    setSearchValue(data);
  };

  useEffect(() => {
    const inputData = {
      offset,
      limit: pageSize,
      approvalStatus: APPROVE_STATUS,
      startDate,
      endDate,
    };
    getRoomList(inputData);
    getDistinctMrtNames("");
  }, [offset, currentPage, startDate, endDate]);

  const getRoomList = async (inputData: RoomPaginationData) => {
    await getRoomListApi(inputData)
      .then((response: RoomResponseTypeList) => {
        const responseData: RoomResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setRoomList(responseData.data);
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
              setIsLoading(false);
            }, 100);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
        setIsLoading(false);
      });
  };

  const handlePostRoom = () => {
    navigate("/room/post", { replace: true });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setOffset(CalculateOffset(page, pageSize));
  };

  const handleSearch = (value: string) => {
    const inputData = {
      offset,
      limit: pageSize,
      approvalStatus: APPROVE_STATUS,
      searchText: value,
    };
    getRoomList(inputData);
  };

  const onChangeStartDate: DatePickerProps["onChange"] = (date, dateString) => {
    setStartDate(dateString);
    setOffset(0);
    setCurrentPage(1);
  };
  const onChangeEndDate: DatePickerProps["onChange"] = (date, dateString) => {
    setEndDate(dateString);
    setOffset(0);
    setCurrentPage(1);
  };

  const getDistinctMrtNames = async (searchMrt: string) => {
    let mrtNameArr: { value: string }[] = [];
    const res = await getDistinctMrtNameApi({ searchMrt: searchMrt });
    res.data?.data?.forEach((opt: { value: string }) => {
      mrtNameArr.push(opt);
    });
    setMrtNameList(mrtNameArr);
  };

  const handleChangeMrtNameSearch = (value: string | string[]) => {
    const inputData = {
      offset,
      limit: pageSize,
      approvalStatus: APPROVE_STATUS,
      searchArray: value,
    };
    getRoomList(inputData);
  };

  return (
    <>
      <Container>
        <Header>
          <Title>Rooms</Title>
          <ButtonWrapper>
            <PostButton onClick={handlePostRoom}>Post Room</PostButton>
          </ButtonWrapper>
        </Header>
        <Wrapper>
          <SelectWrapper>
            <Select
              mode="multiple"
              size="large"
              placeholder="Select MRT Name"
              onChange={handleChangeMrtNameSearch}
              onSearch={getDistinctMrtNames}
              style={{ width: "100%" }}
              options={mrtNameList}
            />
          </SelectWrapper>
          <AutoCompleteWrapper>
            <AutoComplete
              size="large"
              style={{
                width: "100%",
                borderRadius: "10px",
                fontSize: "90%",
              }}
              onSelect={onSelect}
              onSearch={onSearchText}
              placeholder="Search Room (e.g bedok)"
              onChange={handleChangeSearchRoom}
              value={searchValue}
            >
              {autoSerchOptions.map((option, index) => (
                <AutoComplete.Option key={index} value={option.value}>
                  {option.value}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </AutoCompleteWrapper>
          <DatePicker
            placeholder="Start Date"
            size="large"
            onChange={onChangeStartDate}
            style={{ marginRight: "5px", width: "200px" }}
          />
          <DatePicker
            placeholder="End Date"
            size="large"
            onChange={onChangeEndDate}
            style={{ width: "200px" }}
          />
        </Wrapper>
        <RoomCard
          data={roomList}
          error={modalError}
          isLoading={isLoading}
          roomType="rooms"
          page={currentPage}
          offset={offset}
        />
      </Container>
      {roomList?.length !== 0 && (
        <Pagination
          defaultCurrent={currentPage}
          current={currentPage}
          defaultPageSize={pageSize}
          showSizeChanger={false}
          onChange={(page) => handlePageChange(page)}
          total={total}
        />
      )}
    </>
  );
};

export default Room;
