import {
  RequestRoomDataType,
  RequestRoomDeleteDataType,
  RequestRoomListByUserIdData,
  RequestRoomPaginationData,
  RequestRoomUpdateStatus,
} from "../../types/RequestRoomDataType";
import http from "../axiosService";

export const addRequestRoomApi = async (inputData: RequestRoomDataType) => {
  return await http.post("/requestRoom/add", inputData);
};

export const getRequestRoomListApi = async (
  inputData: RequestRoomPaginationData
) => {
  return await http.post("/requestRoom/get/list", inputData);
};

export const getRequestRoomListByUserIdApi = async (
  inputData: RequestRoomListByUserIdData
) => {
  return await http.post("/requestRoom/get/listByUserId", inputData);
};

export const updateRequestRoomApi = async (inputData: RequestRoomDataType) => {
  return await http.post("/requestRoom/update", inputData);
};

export const deleteRequestRoomApi = async (
  inputData: RequestRoomDeleteDataType
) => {
  return await http.post("/requestRoom/delete", inputData);
};

export const updateRequestRoomApprovalStatusApi = async (
  inputData: RequestRoomUpdateStatus
) => {
  return await http.post("/requestRoom/update/approvalStatus", inputData);
};
