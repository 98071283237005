import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getAllUserListByMonthApi,
  getAllUserListByYearApi,
} from "../../services/RouteServices/UserRouteApi";
import {
  UserResponseData,
  UserResponseDataTypeList,
  UserResponseTypeList,
} from "../../types/UserResponseType";
import {
  CURRENT_MONTH,
  CURRENT_YEAR,
  STATUS_CODE,
} from "../../constants/GlobalConstant";
import { Bar } from "react-chartjs-2";
import { Select } from "antd";
import moment from "moment";
import {
  ChartContainer,
  ChartWrapper,
  CSVDownloadIcon,
  DownloadIcon,
  DownloadIconWrapper,
  SelectWrapper,
  BadgeWrapper,
} from "./ReportElements";
import { htmlToImageConvert } from "../../utils/DownloadUtil";
import { ReportPropsDataType } from "../../types/ReportPropsDataType";
import { CSVLink } from "react-csv";
import { getLocalCurrentDateOnlyStr } from "../../utils/DateUtil";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    x: {
      title: {
        display: true,
        text: "User Joined Date",
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
        precision: 0,
      },
      title: {
        display: true,
        text: "User Count",
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "User Report",
    },
  },
};

let data;
let csvData: string[][];

const UserReportBarChart: React.FC<ReportPropsDataType> = ({
  labels,
  yearOption,
  monthOption,
  getLabelForMonth,
}) => {
  const [userData, setUserData] = useState<UserResponseData[]>([]);
  const [month, setMonth] = useState(CURRENT_MONTH);
  const [year, setYear] = useState(CURRENT_YEAR);
  const [type, setType] = useState<string>("month");
  const elementRef = useRef<HTMLDivElement>(null);

  if (type === "month") {
    const userCountMap = new Map();
    userData.forEach((user) => {
      const createdDate = moment(user.createdAt).format("YYYY-MM-DD"); // Format the created date
      if (userCountMap.has(createdDate)) {
        userCountMap.set(createdDate, userCountMap.get(createdDate) + 1); // Increment the count for existing date
      } else {
        userCountMap.set(createdDate, 1); // Set count to 1 for new date
      }
    });

    const labelForMonth = getLabelForMonth(month, year);
    csvData = [
      ["Joined Date", "User Count"],
      ...labelForMonth.map((date) => [date, userCountMap.get(date) ?? 0]),
    ];

    data = {
      labels: labelForMonth,
      datasets: [
        {
          label: "User Count",
          data: labelForMonth.map((date) => userCountMap.get(date) || 0),
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
  } else {
    const userCountMap = new Map();
    userData.forEach((user) => {
      const createdDate = moment(user.createdAt);
      const month = createdDate.format("MMMM"); // Get the month name (e.g., January, February)
      const count = userCountMap.get(month) || 0; // Get the current count or default to 0
      userCountMap.set(month, count + 1); // Increment the count for the month
    });
    csvData = [
      ["Joined Month", "User Count"],
      ...labels.map((date) => [date, userCountMap.get(date) ?? 0]),
    ];
    data = {
      labels,
      datasets: [
        {
          label: "User Count",
          data: labels.map((date) => userCountMap.get(date) || 0),
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
  }

  useEffect(() => {
    getUserList();
  }, [month, year]);

  const getUserList = async () => {
    if (type === "month" && month) {
      await getAllUserListByMonthApi({ month, year })
        .then((response: UserResponseTypeList) => {
          const responseData: UserResponseDataTypeList = response?.data;
          if (responseData) {
            if (responseData?.status === STATUS_CODE.successCode) {
              setUserData(responseData.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await getAllUserListByYearApi({ year })
        .then((response: UserResponseTypeList) => {
          const responseData: UserResponseDataTypeList = response?.data;
          if (responseData) {
            if (responseData?.status === STATUS_CODE.successCode) {
              setUserData(responseData.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeMonth = (value: string) => {
    setMonth(value);
    setType("month");
  };

  const handleChangeYear = (value: string) => {
    setYear(value);
    setType("year");
  };

  return (
    <>
      <ChartContainer ref={elementRef}>
        <BadgeWrapper>
          <SelectWrapper>
            <Select
              defaultValue={year}
              style={{ width: 120, marginRight: "4px" }}
              onChange={handleChangeYear}
              options={yearOption}
            />
            <Select
              defaultValue={month}
              style={{ width: 120 }}
              onChange={handleChangeMonth}
              options={monthOption}
            />
            <h4 style={{ marginLeft: "10px" }}>Total : {userData.length}</h4>
          </SelectWrapper>
          <DownloadIconWrapper>
            <DownloadIcon
              onClick={() =>
                htmlToImageConvert(
                  elementRef.current,
                  `userReport-${getLocalCurrentDateOnlyStr()}`
                )
              }
            />
            <CSVLink
              data={csvData}
              filename={`userReport-${getLocalCurrentDateOnlyStr()}.csv`}
            >
              <CSVDownloadIcon />
            </CSVLink>
          </DownloadIconWrapper>
        </BadgeWrapper>
        <ChartWrapper>
          <Bar
            data={data}
            options={options}
            style={{ width: "100%", height: "100%" }}
          />
        </ChartWrapper>
      </ChartContainer>
    </>
  );
};

export default UserReportBarChart;
