import { MaintenanceConfigData } from "../../types/MaintenanceConfigDataType";
import http from "../axiosService";

export const addMaintenanceConfigApi = async (
  inputData: MaintenanceConfigData
) => {
  return await http.post("maintenanceConfig/add", inputData);
};

export const updateMaintenanceConfigApi = async (
  inputData: MaintenanceConfigData
) => {
  return await http.post("maintenanceConfig/update", inputData);
};

export const getMaintenanceConfigApi = async () => {
  return await http.post("maintenanceConfig/get");
};
