import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 30px;
  padding-top: 10px;
`;

export const Title = styled.h2`
  color: #fff;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const CardWrapper = styled.div`
  width: 60vh;
`;

export const InputContainer = styled.div`
  // width: 60%;
  margin: 10px 5px;
`;

export const InputLabel = styled.small`
  font-weight: bold;
`;

export const Required = styled.b`
  color: red;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(143, 208, 113);
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 80%;
  margin: 5px;
  font-weight: 500;

  &:hover {
    background-color: #8a9a5b;
    color: #fff;
  }
`;

export const CancelButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #848884;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 5px;
  font-weight: 500;
  font-size: 80%;

  &:hover {
    background-color: #71797e;
    color: #fff;
  }
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;
export const AlertContainer = styled.div`
  width: 70%;
  height: 40%;
  @media screen and (max-width: 550px) {
    width: 90%;
  }
`;
export const AlertPage = styled.div`
  background-color: #fffbe6;
  padding: 30px 40px;
  border-radius: 10px;
`;

export const AlertButton = styled.div`
  font-size: 80%;
  color: #fff;
  cursor: pointer;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  background-color: #1890ff;

  &:hover {
    background-color: #2f54eb;
  }

  @media screen and (max-width: 700px) {
    width: 70%;
  }
`;
