import React, { useContext } from "react";
import { Avatar } from "antd";
import { AiOutlineUser } from "react-icons/ai";
import { IoMdLogOut } from "react-icons/io";
import { Dropdown, Menu, Space } from "antd";
import { BiUserCircle } from "react-icons/bi";
import {
  NavbarContainer,
  Nav,
  Logo,
  Image,
  AvatarMenu,
  NavItemContainer,
  NavItem,
  NavItemList,
  Title,
  LogoLink,
  LogoWrapper,
  ButtonWrapper,
  Text,
  MobileLogoWrapper,
  OpenIcon,
  CloseIcon,
} from "./NavbarElements";
import { UserAuthContext } from "../../../contexts/UserAuthContext";
import { UserAuthType } from "../../../types/UserAuth";
import { NodeProps } from "../../../types/CommonType";
import { GoogleLogout } from "react-google-login";
import {
  CLIENT_ID,
  ORG_AND_WATERMARK_TEXT,
} from "../../../constants/GlobalConstant";
import NavMenu from "./NavMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { HiMenuAlt1 } from "react-icons/hi";

const Navbar = ({
  transparent,
  toggle,
  drawerToggle,
  isOpen,
  profileImg,
}: NodeProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { userInfo, handleLogout, isAuth } = useContext(
    UserAuthContext
  ) as UserAuthType;

  const logoutByUser = () => {
    console.log("logout from navbar");
    handleLogout(true);
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <AvatarMenu>
              <AiOutlineUser style={{ marginRight: "10px" }} /> {userInfo.name}
            </AvatarMenu>
          ),
        },
        {
          key: "2",
          label: (
            <>
              <>
                <AvatarMenu style={{ color: "red" }} onClick={logoutByUser}>
                  <IoMdLogOut
                    style={{ marginRight: "10px", marginTop: "2px" }}
                  />
                  <GoogleLogout
                    clientId={CLIENT_ID}
                    buttonText="Log out"
                    // onLogoutSuccess={logoutByUser}
                    render={(renderProps) => (
                      <p
                        // onClick={renderProps.onClick}
                        // onClick={logoutByUser}
                        style={{ marginTop: "3px", marginBottom: "0px" }}
                      >
                        Logout
                      </p>
                    )}
                  />
                </AvatarMenu>
              </>
            </>
          ),
        },
      ]}
    />
  );
  return (
    <>
      <Nav transparent={transparent}>
        <NavbarContainer>
          <LogoWrapper>
            <Logo>
              <LogoLink to={"/"}>
                <Image src={"/images/logo960.jpg"} alt="logo" />
              </LogoLink>
            </Logo>
            {isAuth() && (
              <>
                {isOpen ? (
                  <OpenIcon onClick={drawerToggle} />
                ) : (
                  <CloseIcon onClick={drawerToggle} />
                )}
              </>
            )}
            <Title>{ORG_AND_WATERMARK_TEXT}</Title>
          </LogoWrapper>
          <MobileLogoWrapper onClick={toggle}>
            <HiMenuAlt1 size={30} />
          </MobileLogoWrapper>
          <NavItemContainer>
            <NavItemList>
              <NavMenu to={"/"} name={"Home"} />
            </NavItemList>
            <NavItemList>
              {path === "/room/post" ? (
                <NavMenu to={"room/post"} name={"Room"} />
              ) : (
                <NavMenu to={"room"} name={"Room"} />
              )}
            </NavItemList>
            <NavItemList>
              {path === "/requestRoom/post" ? (
                <NavMenu to={"requestRoom/post"} name={"Request Room"} />
              ) : (
                <NavMenu to={"requestRoom"} name={"Request Room"} />
              )}
            </NavItemList>

            {isAuth() ? (
              <>
                <NavItemList>
                  <NavItem to={""} style={{ paddingLeft: "15px" }}>
                    <Dropdown
                      overlay={menu}
                      placement="bottom"
                      trigger={["click"]}
                    >
                      <Space>
                        {userInfo.googleImgUrl ? (
                          <Avatar
                            size="large"
                            src={userInfo.googleImgUrl}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <Avatar
                            size="large"
                            src={profileImg}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </Space>
                    </Dropdown>
                  </NavItem>
                </NavItemList>
              </>
            ) : (
              <>
                <NavItemList>
                  <ButtonWrapper
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    <BiUserCircle size={25} />
                    <Text>Sign in</Text>
                  </ButtonWrapper>
                </NavItemList>
              </>
            )}
          </NavItemContainer>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
