import React from "react";
import { OFFICIAL_TEAM_NAME } from "../../../constants/GlobalConstant";
import {
  Container,
  Content,
  TempContent,
  Icon,
  FooterLink,
} from "./FooterElements";

const Footer = () => {
  return (
    <>
      <Container>
        <Content></Content>
        <Content>
          Developed by <i>"{OFFICIAL_TEAM_NAME}"</i> Team
        </Content>
        <TempContent>(beta version)</TempContent>

        <Content>
          <FooterLink
            href="https://www.facebook.com/profile.php?id=100081514702799"
            target={"_blank"}
          >
            <Icon />
          </FooterLink>
        </Content>
      </Container>
    </>
  );
};

export default Footer;
