import { Modal, Select } from "antd";
import React from "react";
import { STATUS } from "../../../constants/GlobalConstant";
import { UserStatusModalDataType } from "../../../types/UserDataType";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import {
  Button,
  ButtonWrapper,
  InputContainer,
  InputLabel,
  ModalBtn,
  Required,
} from "../UserElements";
const { Option } = Select;

const UserStatusModal: React.FC<UserStatusModalDataType> = ({
  showStatusModal,
  onCancel,
  modalError,
  modalSuccess,
  handleStatusChange,
  handleStatusUpdate,
  status,
}) => {
  return (
    <>
      <Modal
        title="Update User Status"
        open={showStatusModal}
        onCancel={onCancel}
        footer={null}
      >
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {modalSuccess && (
          <AlertMessage
            message={modalSuccess}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <InputContainer>
          <InputLabel>
            Status<Required>*</Required>
          </InputLabel>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={status}
            onChange={handleStatusChange}
          >
            <Option value={STATUS.activeStatus}>{STATUS.activeStatus}</Option>
            <Option value={STATUS.blockStatus}>{STATUS.blockStatus}</Option>
          </Select>
        </InputContainer>
        <ButtonWrapper>
          <Button onClick={onCancel}>Cancel</Button>
          <ModalBtn color="blue" onClick={handleStatusUpdate}>
            Update
          </ModalBtn>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default UserStatusModal;
