import { EmailConfigData } from "../../types/EmailConfigDataType";
import http from "../axiosService";

export const addEmailConfigApi = async (inputData: EmailConfigData) => {
  return await http.post("emailConfig/add", inputData);
};

export const updateEmailConfigApi = async (inputData: EmailConfigData) => {
  return await http.post("emailConfig/update", inputData);
};

export const getEmailConfigApi = async () => {
  return await http.post("emailConfig/get");
};
