import { Card, Upload, Modal, Switch, UploadFile } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ALLOW_IMG_EXTENSION_LIST,
  ANONYMOUS_USER,
  STATUS_CODE,
  UPLOADABLE_REQUEST_ROOM_IMG_LIMIT,
} from "../../../constants/GlobalConstant";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import {
  Button,
  ButtonWrapper,
  CancelButton,
  CardWrapper,
  Container,
  InputContainer,
  InputLabel,
  Required,
  Title,
  Wrapper,
} from "../PostRequestRoom/PostRequestRoomElements";
import { PlusOutlined } from "@ant-design/icons";
import { Input as AntInput } from "antd";
import {
  RoomRequestResponseData,
  RoomRequestResponseDataType,
  RoomRequestResponseType,
} from "../../../types/RequestRoomDataType";
import { RcFile, UploadProps } from "antd/lib/upload";
import { UserInfo } from "../../../types/UserAuth";
import { getCurrentUser } from "../../../utils/UserUtil";
import { updateRequestRoomApi } from "../../../services/RouteServices/RequestRoomRouteApi";

const EditRequestRoom: React.FC = () => {
  const userInfo: UserInfo = getCurrentUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [requestRoom, setRequestRoom] = useState<RoomRequestResponseData>(
    location.state?.room
  );
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [remark, setRemark] = useState<string>(location.state.room?.remark);
  const [details, setDetails] = useState<string>(location.state.room?.details);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [anonymousUser, setAnonymousUser] = useState<boolean>(
    location.state?.room?.anonymousName ? true : false
  );
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");

  useEffect(() => {
    let fileList: any[] = [];
    if (requestRoom) {
      if (requestRoom.imgUrl) {
        fileList.push({
          thumbUrl: "data:image/png;base64," + requestRoom.imgUrl,
        });
      }
    }
    setFileList(fileList);
  }, []);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      }
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleImgChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    let isValid = true;
    newFileList?.forEach((file) => {
      const fileName = file?.name?.toLowerCase();
      const fileExtension = fileName.substring(fileName.lastIndexOf("."));
      if (!ALLOW_IMG_EXTENSION_LIST.includes(fileExtension)) {
        isValid = false;
      }
    });

    if (isValid) {
      setModalError("");
      setFileList(newFileList);
    } else {
      setModalError(
        "Invalid file type. Please upload only .gif, .png, .jpeg, or .jpg files."
      );
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChangeAnonymousUser = (checked: boolean) => {
    setAnonymousUser(checked);
  };

  const handleUpdateRequestRoom = async () => {
    let imgUrl;
    if (fileList[0]?.originFileObj) {
      imgUrl = await getBase64(fileList[0]?.originFileObj as RcFile);
    } else {
      imgUrl = fileList[0]?.thumbUrl;
    }
    const updateRequestRoomData = {
      id: requestRoom?.id,
      remark,
      details,
      imgUrl: imgUrl,
      updatedUser: userInfo.userName,
      anonymousName: anonymousUser ? ANONYMOUS_USER : "",
    };
    await updateRequestRoomApi(updateRequestRoomData)
      .then((response: RoomRequestResponseType) => {
        const responseData: RoomRequestResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
              navigate("/requestRoom");
            }, 500);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  return (
    <>
      <Wrapper>
        <Title>Edit Request Room</Title>
        <Container>
          <CardWrapper>
            <Card>
              <InputContainer>
                {modalError && (
                  <AlertMessage
                    message={modalError}
                    visible={true}
                    typeAlert={"error"}
                    width={"100%"}
                  />
                )}
                {modalSuccess && (
                  <AlertMessage
                    message={modalSuccess}
                    visible={true}
                    typeAlert={"success"}
                    width={"100%"}
                  />
                )}
              </InputContainer>
              <InputContainer>
                <InputLabel>Images</InputLabel>
                <Upload
                  accept={ALLOW_IMG_EXTENSION_LIST}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleImgChange}
                  beforeUpload={() => false}
                  maxCount={UPLOADABLE_REQUEST_ROOM_IMG_LIMIT}
                >
                  {fileList?.length >= UPLOADABLE_REQUEST_ROOM_IMG_LIMIT
                    ? null
                    : uploadButton}
                </Upload>

                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={() => setPreviewOpen(false)}
                  style={{ width: "50%" }}
                >
                  <img
                    alt="uploadImage"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </InputContainer>
              <InputContainer>
                <InputLabel>
                  Details<Required>*</Required>
                </InputLabel>
                <TextArea
                  rows={4}
                  placeholder="eg.. Bedok မှာ couple နေဖို့ အခန်းလေး ရှိရင် ပြောပါအုံး 
                            $ 680 လောက်ဆို အဆင်ပြေ ပါတယ်"
                  maxLength={500}
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>Remark</InputLabel>
                <AntInput
                  size="large"
                  placeholder="Remark"
                  className="editRequestRoom"
                  prefix={<AiOutlineCheckSquare />}
                  onChange={(e) => setRemark(e.target.value)}
                  value={remark}
                  maxLength={50}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>Post as Anonymous</InputLabel>
                <div>
                  <Switch
                    defaultChecked={anonymousUser}
                    onChange={handleChangeAnonymousUser}
                  />
                </div>
              </InputContainer>
              <ButtonWrapper>
                <CancelButton onClick={() => navigate("/requestRoom")}>
                  Cancel
                </CancelButton>
                <Button onClick={handleUpdateRequestRoom}>Submit</Button>
              </ButtonWrapper>
            </Card>
          </CardWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

export default EditRequestRoom;
