import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isSpecialUser } from "../utils/UserUtil";

const UserCheckRoute = () => {
  if (isSpecialUser()) return <Outlet />;

  return <Navigate to={"/"} replace />;
};

export default UserCheckRoute;
