import React, { useEffect, useState } from "react";
import Table, { ColumnsType } from "antd/lib/table";
import {
  UserLoginHistoryResponseData,
  UserLoginHistoryResponseDataTypeList,
  UserLoginHistoryResponseTypeList,
} from "../../types/LoginHistoryDataType";
import { convertToLocaleDate } from "../../utils/DateUtil";
import {
  Container,
  TableContainer,
  Title,
  Wrapper,
} from "./LoginHistoryElements";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import { PAGE_SIZE, STATUS_CODE } from "../../constants/GlobalConstant";
import { getUserLoginHistoryListApi } from "../../services/RouteServices/UserLoginHistoryRouteApi";
import { DatePicker, DatePickerProps, Input } from "antd";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";

const LoginHistory: React.FC = () => {
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [loginHistory, setLoginHistory] = useState<
    UserLoginHistoryResponseData[]
  >([]);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  const columns: ColumnsType<UserLoginHistoryResponseData> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User Email",
      dataIndex: "userEmail",
      key: "userEmail",
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "User Login Device",
      dataIndex: "userLoginDevice",
      key: "userLoginDevice",
    },
    {
      title: "User Login IP",
      dataIndex: "userLoginIp",
      key: "userId",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      render: (remark: string) => <>{remark ? remark : "-"}</>,
    },
    {
      title: "Created Date",
      dataIndex: "createAt",
      key: "createAt",
      render: (date: Date) => (
        <>{date ? <>{convertToLocaleDate(date)}</> : "-"}</>
      ),
    },
  ];

  useEffect(() => {
    getUserLoginHistoryList();
  }, [page, startDate, endDate, searchText]);

  const getUserLoginHistoryList = async () => {
    const inputData = {
      offset,
      limit: pageSize,
      startDate,
      endDate,
      searchText,
    };
    await getUserLoginHistoryListApi(inputData)
      .then((response: UserLoginHistoryResponseTypeList) => {
        const responseData: UserLoginHistoryResponseDataTypeList =
          response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setLoginHistory(responseData.data);
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handlePageChange = (page: number) => {
    setOffset(CalculateOffset(page, pageSize));
    setPage(page);
  };

  const onChangeStartDate: DatePickerProps["onChange"] = (date, dateString) => {
    setStartDate(dateString);
    setOffset(0);
    setPage(1);
  };
  const onChangeEndDate: DatePickerProps["onChange"] = (date, dateString) => {
    setEndDate(dateString);
    setOffset(0);
    setPage(1);
  };

  return (
    <>
      <Container>
        <Title>Login History</Title>
        <Wrapper>
          <Input
            placeholder="Search"
            style={{ width: "25%" }}
            size="large"
            onChange={(e) => {
              setSearchText(e.target.value);
              setOffset(0);
              setPage(1);
            }}
          />
          <DatePicker
            placeholder="Start Date"
            size="large"
            onChange={onChangeStartDate}
            style={{ margin: "0px 5px" }}
          />
          <DatePicker
            placeholder="End Date"
            size="large"
            onChange={onChangeEndDate}
          />
        </Wrapper>
        <TableContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
          <Table
            rowKey={"id"}
            columns={columns}
            dataSource={loginHistory}
            scroll={{ x: 100 }}
            pagination={{
              defaultPageSize: pageSize,
              showSizeChanger: false,
              total: total,
              defaultCurrent: page,
              current: page,
              onChange: (page) => handlePageChange(page),
            }}
            footer={() => (
              <p>
                Total <b>{total}</b> records found.
              </p>
            )}
          />
        </TableContainer>
      </Container>
    </>
  );
};

export default LoginHistory;
