import React, { useState } from "react";
import { Alert, Button, Image, Space } from "antd";
import {
  Badge,
  BtnWrapper,
  Card,
  CardContainer,
  CardContent,
  EditButton,
  ImgWrapper,
  PostOwnerWrapper,
  RemoveButton,
  Text,
} from "./RequestRoomElements";
import {
  RequestRoomCardProps,
  RequestRoomDeleteDataType,
  RequestRoomUpdateStatus,
  RoomRequestResponseDataType,
  RoomRequestResponseType,
} from "../../types/RequestRoomDataType";
import {
  APPROVE_STATUS,
  PENDING_STATUS,
  REJECT_STATUS,
  STATUS_CODE,
} from "../../constants/GlobalConstant";
import { convertDateFormat, convertToLocaleDate } from "../../utils/DateUtil";
import RequestRoomApproveOrRejectModal from "./RequestRoomModal/RequestRoomApproveOrRejectModal";
import RequestRoomDeleteModal from "./RequestRoomModal/RequestRoomDeleteModal";
import { useNavigate } from "react-router-dom";
import { FaUserTie } from "react-icons/fa";
import { MdOutlineDateRange } from "react-icons/md";
import {
  deleteRequestRoomApi,
  updateRequestRoomApprovalStatusApi,
} from "../../services/RouteServices/RequestRoomRouteApi";
import { UserInfo } from "../../types/UserAuth";
import { getCurrentUser } from "../../utils/UserUtil";

let iconSize = 17;

const RequestRoomCard: React.FC<RequestRoomCardProps> = ({ rooms, type }) => {
  const userInfo: UserInfo = getCurrentUser();
  const navigate = useNavigate();
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [requestRoomId, setRequestRoomId] = useState<number>(0);
  const [statusType, setStatusType] = useState<string>("");
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  const handleChangeStatus = async () => {
    const updateData: RequestRoomUpdateStatus = {
      requestRoomId: requestRoomId,
      approvalStatus: statusType,
      approveUser: userInfo?.userName,
      approveUserId: userInfo?.id,
    };
    await updateRequestRoomApprovalStatusApi(updateData)
      .then((response: RoomRequestResponseType) => {
        const responseData: RoomRequestResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            window.location.reload();
            setTimeout(() => {
              setModalSuccess("");
              setShowStatusModal(false);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handleDelete = async () => {
    const deleteData: RequestRoomDeleteDataType = {
      requestRoomId: requestRoomId,
      deletedUser: userInfo.userName,
    };
    await deleteRequestRoomApi(deleteData)
      .then((response: RoomRequestResponseType) => {
        const responseData: RoomRequestResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
              setShowRemoveModal(false);
              window.location.reload();
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  return (
    <>
      <CardContainer>
        {Array.isArray(rooms) &&
          rooms.map((room) => (
            <Card
              key={room?.id}
              color={room.createdUserId === userInfo.id ? "gold" : ""}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <ImgWrapper>
                    {room?.imgUrl ? (
                      <Image
                        style={{
                          borderRadius: "10px",
                          width: "auto",
                          height: "150px",
                        }}
                        src={"data:image/png;base64," + room?.imgUrl}
                      />
                    ) : (
                      <div style={{ margin: "20px 0px" }}></div>
                    )}
                  </ImgWrapper>
                </div>

                {room?.approvalStatus === PENDING_STATUS && (
                  <Badge color="yellow">{room?.approvalStatus}</Badge>
                )}
                {room?.approvalStatus === APPROVE_STATUS &&
                  type !== "requestRooms" && (
                    <Badge color="green">{room?.approvalStatus}</Badge>
                  )}
                {room?.approvalStatus === REJECT_STATUS && (
                  <Badge color="red">{room?.approvalStatus}</Badge>
                )}
                {type === "requestRooms" && room?.anonymousName && (
                  <PostOwnerWrapper>
                    <FaUserTie
                      size={iconSize}
                      style={{
                        marginRight: "5px",
                        color: "#a59c2f",
                      }}
                    />
                    Post Owner:{" "}
                    <i
                      style={{
                        color: "#b37feb",
                        fontWeight: 600,
                        marginLeft: "0.3em",
                      }}
                    >
                      {room?.anonymousName}
                    </i>
                  </PostOwnerWrapper>
                )}
                {type === "requestRooms" &&
                  !room?.anonymousName &&
                  room?.createdUser && (
                    <PostOwnerWrapper>
                      <FaUserTie
                        size={iconSize}
                        style={{
                          marginRight: "5px",
                          color: "#a59c2f",
                        }}
                      />
                      Post Owner: {room?.createdUser}
                    </PostOwnerWrapper>
                  )}
                {type !== "requestRooms" &&
                  (room?.anonymousName || room?.createdUser) && (
                    <PostOwnerWrapper>
                      <FaUserTie
                        size={iconSize}
                        style={{
                          marginRight: "5px",
                          color: "#a59c2f",
                        }}
                      />
                      Post Owner:{" "}
                      {room?.anonymousName && room?.createdUser ? (
                        <>
                          {room?.createdUser}
                          <i
                            style={{
                              color: "#b37feb",
                              fontWeight: 600,
                              marginLeft: "0.2em",
                            }}
                          >
                            ({room?.anonymousName})
                          </i>
                        </>
                      ) : room?.createdUser && !room.anonymousName ? (
                        room?.createdUser
                      ) : (
                        "unknown"
                      )}
                    </PostOwnerWrapper>
                  )}
                <p
                  style={{
                    fontSize: "85%",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "3px",
                  }}
                >
                  <MdOutlineDateRange
                    size={iconSize}
                    style={{
                      marginRight: "5px",
                      color: "#faad14",
                    }}
                  />
                  Published Date:{" "}
                  {convertDateFormat(room?.createdAt || "") || "-"}
                </p>
                <Text>{room?.details}</Text>
                {room?.remark && (
                  <Alert
                    message={room?.remark}
                    style={{ width: "100%" }}
                    type="success"
                    showIcon
                  />
                )}

                {type === "manageRequestRooms" &&
                  room?.updatedAt &&
                  room?.updatedUser && (
                    <div
                      style={{
                        padding: "10px 30px",
                        fontStyle: "italic",
                        paddingBottom: "0px",
                      }}
                    >
                      <h5>
                        Last updated -{" "}
                        {convertToLocaleDate(new Date(room?.updatedAt))}
                      </h5>
                      <h5>Last updated user - {room?.updatedUser}</h5>
                    </div>
                  )}
                {type === "manageRequestRooms" && (
                  <div
                    style={{
                      padding: "0px 30px",
                      fontStyle: "italic",
                    }}
                  >
                    {room?.approvalStatus !== PENDING_STATUS && (
                      <h5>Approve user - {room?.approveUser}</h5>
                    )}
                  </div>
                )}
                <BtnWrapper>
                  {type !== "requestRooms" && (
                    <>
                      <EditButton
                        onClick={() =>
                          navigate(
                            {
                              pathname: "/request-rooms/edit",
                            },
                            { state: { room: room } }
                          )
                        }
                      >
                        Edit
                      </EditButton>
                      <RemoveButton
                        onClick={() => {
                          setRequestRoomId(room?.id || 0);
                          setShowRemoveModal(true);
                        }}
                        color="red"
                      >
                        Delete
                      </RemoveButton>
                    </>
                  )}
                  {type === "manageRequestRooms" &&
                    room?.approvalStatus === PENDING_STATUS && (
                      <Space style={{ margin: "5px" }}>
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: "green",
                            borderColor: "green",
                          }}
                          onClick={() => {
                            setShowStatusModal(true);
                            setRequestRoomId(room?.id || 0);
                            setStatusType(APPROVE_STATUS);
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: "red",
                            borderColor: "red",
                          }}
                          onClick={() => {
                            setShowStatusModal(true);
                            setRequestRoomId(room?.id || 0);
                            setStatusType(REJECT_STATUS);
                          }}
                        >
                          Reject
                        </Button>
                      </Space>
                    )}
                </BtnWrapper>
              </CardContent>
            </Card>
          ))}
      </CardContainer>

      {/* Room approve or reject modal */}
      <RequestRoomApproveOrRejectModal
        showStatusModal={showStatusModal}
        modalError={modalError}
        modalSuccess={modalSuccess}
        handleChangeStatus={handleChangeStatus}
        onCancel={() => {
          setShowStatusModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        requestRoomId={requestRoomId}
        statusType={statusType}
      />

      {/* Delete Modal */}
      <RequestRoomDeleteModal
        removeRequestRoomId={requestRoomId}
        showRemoveModal={showRemoveModal}
        onCancel={() => {
          setShowRemoveModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        error={modalError}
        success={modalSuccess}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default RequestRoomCard;
