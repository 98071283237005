import { Link } from "react-router-dom";
import styled from "styled-components";

export const AlertText = styled.div`
  width: 100%;
  position: absolute;
  top: 2%;
`;

export const Container = styled.div`
  // padding-top: 40px;
  background-color: #1e1e1e;
  color: #fff;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
`;

export const Title = styled.h2`
  color: #fff;
`;
export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
  height: 100vh;
`;

export const Image = styled.img`
  height: auto;
  width: 100px;
  margin-bottom: 20px;
  border-radius: 10px;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px 0px;
  position: relative;
  width: 40vh;
`;

export const Input = styled.input`
  position: relative;
  outline: none;
  border: none;
  width: 100%;
  padding: 17px;
  padding-left: 50px;
  color: grey;
  border-radius: 10px;
`;

export const Icon = styled.div`
  position: absolute;
  z-index: 999;
  color: grey;
  top: 14px;
  left: 3%;
`;

export const Button = styled.button`
  width: 25%;
  height: 50px;
  outline: none;
  border: none;
  background-color: dodgerblue;
  color: #fff;
  margin-top: 30px;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #007aec;
  }
  @media screen and (max-width: 768px) {
    width: 40%;
  }
  @media screen and (max-width: 425px) {
    width: 50%;
  }
`;

export const Error = styled.small`
  color: red;
  font-weight: 500;
  padding-top: 5px;
  align-self: flex-start;
`;

export const LoginLink = styled.small`
  margin-top: 20px;
  color: #98fb98;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

export const GoogleLogin = styled.button`
  width: 25%;
  height: 50px;
  outline: none;
  border: none;
  background-color: #fff;
  color: #000;
  margin-top: 30px;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background-color: #e0e0e0;
  }
  @media screen and (max-width: 950px) {
    width: 35%;
  }
  @media screen and (max-width: 768px) {
    width: 40%;
  }
  @media screen and (max-width: 600px) {
    width: 50%;
  }
  @media screen and (max-width: 450px) {
    width: 70%;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
  }
`;
export const GoogleImg = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;

export const HomeLink = styled(Link)`
  color: #fff;
  text-decoration: underline;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

export const FooterText = styled.div`
  position: fixed;
  bottom: 2%;
  text-align: center;
  width: 100%;
`;
