import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import { STATUS_CODE } from "../../../../constants/GlobalConstant";
import { getRoomByIdApi } from "../../../../services/RouteServices/RoomRouteApi";
import {
  RoomCardDuplicateModalProps,
  RoomDataType,
} from "../../../../types/RoomDataType";
import {
  RoomResponseDataType,
  RoomResponseType,
} from "../../../../types/RoomResponseType";
import { convertToLocaleDate } from "../../../../utils/DateUtil";
import { htmlToImageConvert } from "../../../../utils/DownloadUtil";
import AlertMessage from "../../AlertMessage/AlertMessage";
import RoomCardContent from "../RoomCardComponents/RoomCardContent";
import RoomCardFooter from "../RoomCardComponents/RoomCardFooter";
import RoomCardImages from "../RoomCardComponents/RoomCardImages";
import {
  Card,
  CardContent,
  Desc,
  DownloadIcon,
  DownloadWrapper,
  ImgContainer,
  PostButton,
} from "../RoomCardElements";
import RoomCardDetailModal from "./RoomCardDetailModal";

const RoomCardDuplicateModal: React.FC<RoomCardDuplicateModalProps> = ({
  roomId,
  roomType,
  open,
  onCancel,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [room, setRoom] = useState<RoomDataType | undefined>();
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getRoomById();
  }, [roomId]);

  const getRoomById = async () => {
    if(roomId){
      const inputData = { id: roomId };
      await getRoomByIdApi(inputData)
        .then((response: RoomResponseType) => {
          const responseData: RoomResponseDataType = response?.data;
          if (responseData) {
            if (responseData?.status === STATUS_CODE.successCode) {
              setRoom(responseData.data);
              setModalError("");
              setModalSuccess(responseData?.message);
              console.log(responseData.message);
              setTimeout(() => {
                setModalSuccess("");
              }, 3000);
            } else {
              setModalError(responseData?.message);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setModalError(err?.response?.data?.message || err?.message);
        });
    }
  };

  return (
    <>
      <Modal
        title="Duplicate Room"
        open={open}
        onCancel={onCancel}
        footer={null}
      >
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {modalSuccess && (
          <AlertMessage
            message={modalSuccess}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <Card ref={elementRef}>
          <CardContent>
            <ImgContainer>
              {room && (
                <RoomCardImages
                  room={room || undefined}
                  roomType={roomType || ""}
                />
              )}
            </ImgContainer>
            <div style={{ width: "90%" }}>
              <Desc>
                <DownloadWrapper>
                  <p
                    style={{
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    {room?.mrtInMrtLine?.mrtName}
                  </p>
                  <DownloadIcon
                    onClick={() =>
                      htmlToImageConvert(
                        elementRef.current,
                        room?.mrtInMrtLine?.mrtName
                      )
                    }
                  />
                </DownloadWrapper>
                {room && (
                  <RoomCardContent room={room} roomType={roomType || ""} />
                )}
              </Desc>
              {roomType === "manageRooms" &&
                room?.updatedAt &&
                room?.updatedUser && (
                  <div
                    style={{
                      padding: "10px 30px",
                      fontStyle: "italic",
                    }}
                  >
                    <h5>
                      Last updated -{" "}
                      {convertToLocaleDate(new Date(room?.updatedAt))}
                    </h5>
                    <h5>Last updated user - {room?.updatedUser}</h5>
                  </div>
                )}
              <div style={{ margin: "10px 30px" }}>
                <PostButton onClick={() => setModalOpen(true)}>
                  Details...
                </PostButton>
              </div>
              {room && <RoomCardFooter room={room} />}
            </div>
          </CardContent>
        </Card>
      </Modal>

      <RoomCardDetailModal
        open={modalOpen}
        data={room}
        onCancel={() => setModalOpen(false)}
      />
    </>
  );
};

export default RoomCardDuplicateModal;
