import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  ListWrapper,
  List,
  ListItem,
  CardWrapper,
  ProductContainer,
  CardItem,
  Text,
  TextWrapper,
  EmailText,
  WelcomeContainer,
  RoomButton,
  Wrapper,
} from "./HomeElements";
import {
  HOME_IMG,
  OFFICIAL_TEAM_NAME,
  STATUS_CODE,
} from "../../constants/GlobalConstant";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Loading from "../Shared/Loading/Loading";
import { UserInfo } from "../../types/UserAuth";
import { getAdvertisement, getCurrentUser } from "../../utils/UserUtil";
import { getLocalCurrentDateOnlyStr } from "../../utils/DateUtil";
import { Alert, Empty } from "antd";
import { getMaintenanceConfigApi } from "../../services/RouteServices/MaintenanceConfigRouteApi";
import {
  MaintenanceConfigResponseData,
  MaintenanceConfigResponseDataType,
  MaintenanceConfigResponseType,
} from "../../types/MaintenanceConfigDataType";

const Home = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const userInfo: UserInfo = getCurrentUser();
  const [maintenanceConfig, setMaintenanceConfig] =
    useState<MaintenanceConfigResponseData>();
  const advertisement = getAdvertisement();

  useEffect(() => {
    getMaintenanceConfigList();
    setTimeout(() => {
      setLoading(false);
    }, 300);

    const cUser = userInfo?.userName ? userInfo?.userName : "unknown";
    const userAccessLog =
      cUser + " is accessing Home page at " + getLocalCurrentDateOnlyStr();
  }, []);

  const getMaintenanceConfigList = async () => {
    await getMaintenanceConfigApi()
      .then((response: MaintenanceConfigResponseType) => {
        const responseData: MaintenanceConfigResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setMaintenanceConfig(responseData.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading) {
    // return <Loading />;
  }

  return (
    <>
      {maintenanceConfig && maintenanceConfig.active == true && (
        <Alert
          type="error"
          style={{ fontSize: "100%", textAlign: "center" }}
          message={maintenanceConfig?.details}
          banner
        />
      )}
      <Container>
        {Object.keys(advertisement).length !== 0 && advertisement?.checkedAd ? (
          <Card
            backgroundImageUrl={
              "data:image/png;base64," + advertisement?.imgUrl
            }
          >
            <ListWrapper>
              <TextWrapper>
                <Text
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "gold",
                  }}
                >
                  {advertisement.details}
                </Text>
              </TextWrapper>
            </ListWrapper>
            <ProductContainer>
              <WelcomeContainer></WelcomeContainer>
            </ProductContainer>
          </Card>
        ) : (
          <Card backgroundImageUrl={HOME_IMG}>
            <ListWrapper>
              <List>
                <ListItem>
                  <b>Founded in May 2022, {OFFICIAL_TEAM_NAME}</b> is a team
                  that organized by volunteers.
                </ListItem>
                <ListItem>
                  Our main purpose is to support digital platforms for burmese
                  in Singapore.
                </ListItem>
              </List>
            </ListWrapper>
            <ProductContainer>
              <WelcomeContainer>
                <Wrapper>
                  <RoomButton onClick={() => navigate("room")}>
                    Rooms <AiOutlineArrowRight />
                  </RoomButton>
                </Wrapper>
                <TextWrapper>
                  <Text>
                    We warmly welcome your advices,
                    <br /> feedbacks and suggestions.
                  </Text>
                  <Text>
                    Email -{" "}
                    <a href="mailto:admin@burmeseinsingapore.com">
                      <EmailText>
                        <b>admin@burmeseinsingapore.com</b>
                      </EmailText>
                    </a>
                  </Text>
                </TextWrapper>
              </WelcomeContainer>
            </ProductContainer>
          </Card>
        )}
      </Container>
    </>
  );
};

export default Home;
