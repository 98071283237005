
export const getPublicIp = async () => {
    let ipAddress : string = "";
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        ipAddress = data?.ip;
        console.log(ipAddress);

        return ipAddress;
    } catch (error) {
        console.log(error);
        return "";       
    }    
}