import { Button, Space } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PENDING_STATUS,
  DUPLICATE_STATUS,
  RESERVE_STATUS,
  APPROVE_STATUS,
} from "../../../../constants/GlobalConstant";
import { RoomCardActionButtonsProps } from "../../../../types/RoomDataType";
import {
  ActionButtonWrapper,
  EditButton,
  ModalBtnWrapper,
  PostButton,
  RemoveButton,
} from "../RoomCardElements";

const RoomCardActionButtons: React.FC<RoomCardActionButtonsProps> = ({
  handleDetailButton,
  roomType,
  room: item,
  handleApproveButton,
  handleRejectButton,
  handleRemoveButton,
  handleShowDuplicate,
  handleReserveButton,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <ModalBtnWrapper>
        <PostButton onClick={handleDetailButton}>Details...</PostButton>
        <ActionButtonWrapper>
          <EditButton
            onClick={() =>
              navigate(
                {
                  pathname: "/my-rooms/edit",
                },
                { state: { room: item } }
              )
            }
          >
            Edit
          </EditButton>
          <RemoveButton onClick={handleRemoveButton} color="red">
            Delete
          </RemoveButton>
        </ActionButtonWrapper>
      </ModalBtnWrapper>
      {roomType === "manageRooms" &&
        (item?.approvalStatus === PENDING_STATUS ||
          item?.approvalStatus === DUPLICATE_STATUS) && (
          <Space style={{ margin: "5px" }}>
            <Button
              type="primary"
              style={{
                backgroundColor: "green",
                borderColor: "green",
              }}
              onClick={handleApproveButton}
            >
              Approve
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: "red",
                borderColor: "red",
              }}
              onClick={handleRejectButton}
            >
              Reject
            </Button>
          </Space>
        )}
      <div style={{ marginTop: "5px" }}>
        {roomType === "manageRooms" &&
          item?.approvalStatus === DUPLICATE_STATUS && (
            <Button
              type="primary"
              style={{
                backgroundColor: "#b37feb",
                borderColor: "#b37feb",
                borderRadius: "10px",
              }}
              onClick={handleShowDuplicate}
            >
              Show duplicate room
            </Button>
          )}
      </div>
      <div style={{ marginTop: "5px" }}>
        {roomType !== "rooms" &&
          item?.approvalStatus === APPROVE_STATUS && (
            <Button
              type="primary"
              style={{
                backgroundColor: "#f759ab",
                borderColor: "#f759ab",
                borderRadius: "10px",
              }}
              onClick={handleReserveButton}
            >
             Reserve
            </Button>
          )}
      </div>
    </>
  );
};

export default RoomCardActionButtons;
