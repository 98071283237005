import { FC, useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import Drawer from "./Drawer/Drawer";
import { Outlet } from "react-router-dom";
import { NodeProps } from "../../types/CommonType";
import Sidebar from "./Sidebar/Sidebar";
import { DrawerWrapper, Wrapper } from "./LayoutElements";
import { randomImageUtil } from "../../utils/RandomImageUtil";

const Layout: FC<NodeProps> = ({ transparent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [profileImg, setProfileImg] = useState("");

  const isToggle = () => {
    setIsOpen(!isOpen);
  };
  const drawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    setProfileImg(randomImageUtil());
  }, []);

  return (
    <>
      <Navbar
        transparent={transparent}
        toggle={isToggle}
        drawerToggle={drawerToggle}
        isOpen={drawerOpen}
        profileImg={profileImg}
      />
      <Sidebar isOpen={isOpen} toggle={isToggle} profileImg={profileImg} />
      {!transparent ? (
        <>
          <Drawer isOpen={drawerOpen} toggle={drawerToggle} />
          <DrawerWrapper isOpen={drawerOpen}>
            <Outlet />
            <Footer />
          </DrawerWrapper>
        </>
      ) : (
        <Wrapper>
          <Outlet />
          <Footer />
        </Wrapper>
      )}
    </>
  );
};

export default Layout;
