import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const DrawerMenu = (props: any) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <NavLink
          onClick={props.handleClick}
          end
          to={props.to}
          style={({ isActive }) => ({
            textDecoration: "none",
            fontWeight: "bold",
            width: "100%",
            padding: "10px 0px",
            display: "flex",
            margin: "1px",
            borderRadius: "10px",
            paddingLeft: "20px",
            background: isActive ? "#262626" : "",
            color: isActive ? "#8fd071" : "#434343",
          })}
        >
          <div style={{ marginRight: "20px", marginTop: "2px" }}>
            {props.icon}
          </div>
          {props.name}
        </NavLink>
      ) : (
        <NavLink
          end
          to={props.to}
          style={({ isActive }) => ({
            textDecoration: "none",
            fontWeight: "bold",
            width: "100%",
            padding: "10px 0px",
            display: "flex",
            margin: "1px",
            borderRadius: "10px",
            paddingLeft: "20px",
            background: isActive ? "#262626" : "",
            color: isActive ? "#8fd071" : "#434343",
          })}
        >
          <div style={{ marginRight: "20px", marginTop: "2px" }}>
            {props.icon}
          </div>
          {props.name}
        </NavLink>
      )}
    </>
  );
};

export default DrawerMenu;
