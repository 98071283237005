import styled from "styled-components";

export const Container = styled.div`
  margin: 30px;

  @media screen and (max-width: 600px) {
    margin: 12px;
  }
`;

export const Title = styled.h1`
  color: #fff;
`;

export const SelectWrapper = styled.div`
  width: 20%;

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 400px) {
    width: 50%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  margin-left: 20px;
`;
