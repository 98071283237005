import React, { useState } from "react";
import { DatePicker, DatePickerProps, Modal, Upload, UploadFile } from "antd";
import TextArea from "antd/lib/input/TextArea";
import {
  ALLOW_IMG_EXTENSION_LIST,
  STATUS_CODE,
} from "../../../constants/GlobalConstant";
import {
  AdvertisementAddModalProps,
  AdvertisementResponseDataType,
  AdvertisementResponseType,
} from "../../../types/AdvertisementDataType";
import {
  Button,
  ButtonWrapper,
  InputContainer,
  InputLabel,
  ModalBtn,
  Required,
} from "../AdvertisementElements";
import { Input as AntInput } from "antd";
import { AiOutlineCheckSquare } from "react-icons/ai";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile, UploadProps } from "antd/lib/upload";
import { RangePickerProps } from "antd/lib/date-picker";
import dayjs from "dayjs";
import { UserInfo } from "../../../types/UserAuth";
import { getCurrentUser } from "../../../utils/UserUtil";
import { addAdvertisementApi } from "../../../services/RouteServices/AdvertisementRouteApi";

const AdvertisementAddModal: React.FC<AdvertisementAddModalProps> = ({
  onCancel,
  showModal,
}) => {
  const userInfo: UserInfo = getCurrentUser();
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [details, setDetails] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [expiredDate, setExpiredDate] = useState<string>("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [date, setDate] = useState<any>(null);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      }
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleImgChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    let isValid = true;
    newFileList?.forEach((file) => {
      const fileName = file?.name?.toLowerCase();
      const fileExtension = fileName.substring(fileName.lastIndexOf("."));
      if (!ALLOW_IMG_EXTENSION_LIST.includes(fileExtension)) {
        isValid = false;
      }
    });

    if (isValid) {
      setModalError("");
      setFileList(newFileList);
    } else {
      setModalError(
        "Invalid file type. Please upload only .gif, .png, .jpeg, or .jpg files."
      );
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().endOf("day");
  };

  const onChangeExpiredDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    setDate(date);
    setExpiredDate(dateString);
  };

  const handleAddAdvertisement = async () => {
    let imgUrl;
    if (fileList.length !== 0) {
      imgUrl = await getBase64(fileList[0]?.originFileObj as RcFile);
    }
    const addAdData = {
      remark,
      details,
      imgUrl: imgUrl || "",
      expiredDate,
      createdUser: userInfo.userName,
    };
    await addAdvertisementApi(addAdData)
      .then((response: AdvertisementResponseType) => {
        const responseData: AdvertisementResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              clearInputValues();
              onCancel();
            }, 500);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const clearInputValues = () => {
    setModalSuccess("");
    setModalError("");
    setDetails("");
    setExpiredDate("");
    setFileList([]);
    setDate(null);
    setRemark("");
  };

  return (
    <>
      <Modal
        title="Add New Advertisement"
        open={showModal}
        onCancel={onCancel}
        footer={null}
      >
        <InputContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
        </InputContainer>
        <InputContainer>
          <InputLabel>Images</InputLabel>
          <Upload
            accept={ALLOW_IMG_EXTENSION_LIST}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleImgChange}
            beforeUpload={() => false}
            maxCount={1}
          >
            {fileList?.length >= 1 ? null : uploadButton}
          </Upload>

          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={() => setPreviewOpen(false)}
            style={{ width: "50%" }}
          >
            <img
              alt="uploadImage"
              style={{ width: "100%" }}
              src={previewImage}
            />
          </Modal>
        </InputContainer>
        <InputContainer>
          <InputLabel>
            Details<Required>*</Required>
          </InputLabel>
          <TextArea
            rows={3}
            placeholder="Details"
            maxLength={50}
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>
            Expired Date<Required>*</Required>
          </InputLabel>
          <div>
            <DatePicker
              size={"large"}
              disabledDate={disabledDate}
              onChange={onChangeExpiredDate}
              value={date}
            />
          </div>
        </InputContainer>
        <InputContainer>
          <InputLabel>Remark</InputLabel>
          <AntInput
            size="large"
            placeholder="Remark"
            prefix={<AiOutlineCheckSquare />}
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            maxLength={50}
          />
        </InputContainer>
        <ButtonWrapper>
          <Button onClick={onCancel}>Cancel</Button>
          <ModalBtn onClick={handleAddAdvertisement}>Add</ModalBtn>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default AdvertisementAddModal;
