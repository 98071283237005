import { useContext } from "react";
import { BrowserRouter, Route, Routes as Router } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import Home from "../components/Home/Home";
import Login from "../components/Login/Login";
import Room from "../components/Room/Room";
import PrivateRoute from "./PrivateRoute";
import { UserAuthContext } from "../contexts/UserAuthContext";
import { UserAuthType } from "../types/UserAuth";
import Layout from "../components/Layout/Layout";
import PostRoom from "../components/Room/PostRoom/PostRoom";
import User from "../components/User/User";
import MRTLine from "../components/MRT/MRTLine";
import MRTLineDetail from "../components/MRT/MRTLineDetail/MRTLineDetail";
import Config from "../components/Config/Config";
import Setting from "../components/Setting/Setting";
import Role from "../components/Role/Role";
import RoomDetail from "../components/RoomDetail/RoomDetail";
import EditMyRooms from "../components/MyRooms/EditMyRooms/EditMyRooms";
import MyRooms from "../components/MyRooms/MyRooms";
import ManageRooms from "../components/ManageRooms/ManageRooms";
import Report from "../components/Report/Report";
import LoginHistory from "../components/LoginHistory/LoginHistory";
import UserCheckRoute from "./UserCheckRoute";
import {
  approveRequestRoomCheckRoute,
  approveRoomCheckRoute,
  CheckRootUserRoute,
} from "./RoleCheckRoute";
import Content from "../components/Content/Content";
import Event from "../components/Event/Event";
import RequestRoom from "../components/RequestRoom/RequestRoom";
import MyRequestRoom from "../components/MyRequestRoom/MyRequestRoom";
import PostRequestRoom from "../components/RequestRoom/PostRequestRoom/PostRequestRoom";
import ManageRequestRooms from "../components/ManageRequestRooms/ManageRequestRooms";
import EditRequestRoom from "../components/RequestRoom/EditRequestRoom/EditRequestRoom";
import Advertisement from "../components/Advertisement/Advertisement";

const Routes = () => {
  const { isAuth } = useContext(UserAuthContext) as UserAuthType;

  return (
    <>
      <BrowserRouter>
        <Router>
          <Route path="/login" element={<Login />} />

          <Route element={<Layout transparent={!isAuth()} />}>
            <Route path="/" element={<Home />} />
            <Route path="/room" element={<Room />} />
            <Route path="/room/details/" element={<RoomDetail />} />
            <Route path="/requestRoom" element={<RequestRoom />} />

            <Route element={<PrivateRoute />}>
              <Route element={<UserCheckRoute />}>
                <Route path="users" element={<User />} />
                <Route path="mrt-lines">
                  <Route index element={<MRTLine />} />
                  <Route path="detail" element={<MRTLineDetail />} />
                </Route>
                <Route path="role" element={<Role />} />
                <Route path="/report" element={<Report />} />
                <Route path="config" element={<Config />} />
                <Route path="/login-history" element={<LoginHistory />} />
                <Route path="events" element={<Event />} />
              </Route>
              <Route element={approveRoomCheckRoute()}>
                <Route path="/manage-rooms" element={<ManageRooms />} />
              </Route>
              <Route path="contents" element={<Content />} />

              <Route path="setting" element={<Setting />} />
              <Route path="/room/post" element={<PostRoom />} />
              <Route path="/my-rooms" element={<MyRooms />} />

              <Route path="/my-rooms/edit" element={<EditMyRooms />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/my-request-rooms" element={<MyRequestRoom />} />
              <Route path="/requestRoom/post" element={<PostRequestRoom />} />
              <Route element={approveRequestRoomCheckRoute()}>
                <Route
                  path="/manage-request-rooms"
                  element={<ManageRequestRooms />}
                />
              </Route>
              <Route path="/request-rooms/edit" element={<EditRequestRoom />} />
              <Route element={CheckRootUserRoute()}>
                <Route path="/advertisements" element={<Advertisement />} />
              </Route>
            </Route>
          </Route>
        </Router>
      </BrowserRouter>
    </>
  );
};

export default Routes;
