import {
  ALL_STATUS,
  APPROVE_STATUS,
  DUPLICATE_STATUS,
  PENDING_STATUS,
  REJECT_STATUS,
} from "./GlobalConstant";

export const ApprovalStatusOptions = [
  { value: ALL_STATUS, label: ALL_STATUS },
  { value: APPROVE_STATUS, label: APPROVE_STATUS },
  { value: REJECT_STATUS, label: REJECT_STATUS },
  { value: PENDING_STATUS, label: PENDING_STATUS },
  { value: DUPLICATE_STATUS, label: DUPLICATE_STATUS },
];
