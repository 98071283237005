import React from "react";
import { FaUserAlt, FaPhone } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { ORG_AND_WATERMARK_TEXT } from "../../../../constants/GlobalConstant";
import { RoomCardFooterProps } from "../../../../types/RoomDataType";
import { FooterWrapper, IconContainer, WhatsAppIconContainer, Wrapper } from "../RoomCardElements";

const RoomCardFooter: React.FC<RoomCardFooterProps> = ({ room: item }) => {
  return (
    <>
      <FooterWrapper>
        <Wrapper>
          <IconContainer>
            <FaUserAlt
              style={{
                color: "white",
              }}
            />
          </IconContainer>
          <div>{item?.gender || "-"}</div>
        </Wrapper>
        <Wrapper>
          <WhatsAppIconContainer>
            <BsWhatsapp />
          </WhatsAppIconContainer>
          <div>
            <a
              style={{
                color: "black",
                textDecoration: "none",
              }}
              // href={`tel:${item?.phoneNo || ""}}`}
              href={`https://wa.me/${item?.phoneNo || ""}?text=hello, I saw your advertisement at ${ORG_AND_WATERMARK_TEXT}.`}
            >
              {item?.phoneNo || "-"}
            </a>
          </div>
        </Wrapper>
      </FooterWrapper>
    </>
  );
};

export default RoomCardFooter;
