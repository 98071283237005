export const GenderOption = [
  {
    value: "",
    label: "Select Gender",
  },
  {
    value: "Male Only",
    label: "Male Only",
  },
  {
    value: "Female Only",
    label: "Female Only",
  },
  {
    value: "Male/Female",
    label: "Male/Female",
  },
  {
    value: "Couple Only",
    label: "Couple Only",
  },
  {
    value: "Any Gender",
    label: "Any Gender",
  },
];
