import { Modal } from "antd";
import React from "react";
import { RoomCardDeleteDataType } from "../../../../types/RoomDataType";
import AlertMessage from "../../AlertMessage/AlertMessage";
import { ButtonWrapper, DeleteBtn, ModalButton } from "../RoomCardElements";

const RoomCardDeleteModal: React.FC<RoomCardDeleteDataType> = ({
  removeRoomId,
  onCancel,
  open,
  handleDelete,
  error,
  success,
}) => {
  return (
    <>
      <Modal title="Delete Room" open={open} onCancel={onCancel} footer={null}>
        {error && (
          <AlertMessage
            message={error}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {success && (
          <AlertMessage
            message={success}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <h2>
          Are you sure you want to delete room ID [
          <span style={{ color: "red" }}>{removeRoomId}</span>]?
        </h2>
        <ButtonWrapper>
          <ModalButton onClick={onCancel}>Cancel</ModalButton>
          <DeleteBtn onClick={handleDelete}>Delete</DeleteBtn>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default RoomCardDeleteModal;
