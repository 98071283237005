import { RoleResponseData } from "./RoleResponseType";

export interface UserInfo {
  userName: string;
  loginType: string;
  id?: number;
  name?: string;
  refreshToken?: string;
  email?: string;
  googleImgUrl?: string;
  password?: string;
  roleId?: number;
  roleName?: string;
  isApproveRoom?: boolean;
  userLoginIp?: string;
  userLoginDevice?: string;
  lastActive?: any;
  activeStatus?: string;
  role?: RoleResponseData;
}

export type UserAuthType = {
  userInfo: UserInfo;
  getUserInfoFromLocal: () => UserInfo;
  updateUserInfo: (userInfo: UserInfo) => void;
  handleLoginInContext: (loginValue: any) => void;
  handleLogout: (isReload?: boolean | undefined) => void;
  isAuth: () => boolean;
};

export const DefaultUserInfo: UserInfo = {
  id: 0,
  name: "",
  userName: "",
  refreshToken: "",
  email: "",
  googleImgUrl: "",
  loginType: "",
  roleId: 0,
};

export interface UserPermission {
  isApproveRoom?: boolean;
  isApproveRequestRoom?: boolean;
}
