import { Checkbox, Modal } from "antd";
import React from "react";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { RiShieldUserLine } from "react-icons/ri";
import { isSpecialUser, isNormalRole } from "../../../utils/UserUtil";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import {
  ButtonWrapper,
  EditModalBtn,
  InputBox,
  InputContainer,
  InputLabel,
  Required,
  Button,
} from "../RoleElements";
import { Input as AntInput } from "antd";
import { RoleEditModalProps } from "../../../types/RoleDataType";

const RoleEditModal: React.FC<RoleEditModalProps> = ({
  showEditModal,
  modalError,
  modalSuccess,
  onCancel,
  handleChangeName,
  handleRemark,
  id,
  name,
  remark,
  handleEdit,
  onChangeCheck,
  isApproveRoom,
  onChangeApproveRequestCheck,
  isApproveRequestRoom,
  manageContent,
  onChangeManageContent,
}) => {
  return (
    <Modal
      title="Edit Role"
      open={showEditModal}
      onCancel={onCancel}
      footer={null}
    >
      <InputContainer>
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {modalSuccess && (
          <AlertMessage
            message={modalSuccess}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
      </InputContainer>
      <InputContainer>
        <InputLabel>
          Role Name<Required>*</Required>
        </InputLabel>
        <AntInput
          size="large"
          placeholder="Role Name"
          prefix={<RiShieldUserLine />}
          onChange={handleChangeName}
          value={name}
          maxLength={50}
        />
      </InputContainer>
      <InputContainer>
        <InputLabel>Remark</InputLabel>
        <AntInput
          size="large"
          placeholder="Remark"
          prefix={<AiOutlineCheckSquare />}
          onChange={handleRemark}
          value={remark}
          maxLength={50}
        />
      </InputContainer>
      {isSpecialUser() && isNormalRole(id) && (
        <>
          <InputContainer>
            <InputLabel>Permission</InputLabel>
            <InputBox>
              <Checkbox
                style={{
                  paddingTop: "10px",
                  paddingLeft: "10px",
                  fontSize: "120%",
                }}
                onChange={onChangeCheck}
                checked={isApproveRoom}
              >
                Approve room
              </Checkbox>
              <div>
                <Checkbox
                  style={{
                    paddingTop: "10px",
                    paddingLeft: "10px",
                    fontSize: "120%",
                  }}
                  onChange={onChangeApproveRequestCheck}
                  checked={isApproveRequestRoom}
                >
                  Approve request room
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  style={{
                    paddingTop: "10px",
                    paddingLeft: "10px",
                    fontSize: "120%",
                  }}
                  onChange={onChangeManageContent}
                  checked={manageContent}
                >
                  Manage content
                </Checkbox>
              </div>
            </InputBox>
          </InputContainer>
        </>
      )}
      <ButtonWrapper>
        <Button onClick={onCancel}>Cancel</Button>
        <EditModalBtn onClick={handleEdit}>Update</EditModalBtn>
      </ButtonWrapper>
    </Modal>
  );
};

export default RoleEditModal;
