import React from "react";
import { NavLink } from "react-router-dom";

const NavMenu = (props: any) => {
  return (
    <>
      <NavLink
        end
        to={props.to}
        style={({ isActive }) => ({
          color: "#fff",
          textDecoration: "none",
          fontWeight: "bold",
          height: "95%",
          padding: "0px 0.8rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: isActive ? "4px solid #8fd071" : "",
          //   backgroundColor: isActive ? "#8fd071" : "",
        })}
      >
        {props.name}
      </NavLink>
    </>
  );
};

export default NavMenu;
