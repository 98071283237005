import React from "react";
import { Modal, Select, Input as AntInput } from "antd";
import { AiOutlineUser, AiOutlineMail } from "react-icons/ai";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import {
  Button,
  ButtonWrapper,
  InputContainer,
  InputLabel,
  ModalBtn,
  Required,
} from "../UserElements";
import { UserEditModalDataType } from "../../../types/UserDataType";

const { Option } = Select;

const UserEditModal: React.FC<UserEditModalDataType> = ({
  showEditModal,
  onCancel,
  error,
  success,
  handleChangeUsername,
  username,
  handleChangeName,
  name,
  handleChangeEmail,
  email,
  selectedOption,
  handleEdit,
  handleChange,
  roles,
}) => {
  return (
    <>
      <Modal
        title="Edit User"
        open={showEditModal}
        onCancel={onCancel}
        footer={null}
      >
        <InputContainer>
          {error && (
            <AlertMessage
              message={error}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {success && (
            <AlertMessage
              message={success}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
        </InputContainer>
        <InputContainer>
          <InputLabel>
            Username<Required>*</Required>
          </InputLabel>
          <AntInput
            size="large"
            placeholder="Username"
            prefix={<AiOutlineUser />}
            onChange={handleChangeUsername}
            value={username}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Name</InputLabel>
          <AntInput
            size="large"
            placeholder="Name"
            prefix={<AiOutlineUser />}
            onChange={handleChangeName}
            value={name}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Email</InputLabel>
          <Required>*</Required>
          <AntInput
            size="large"
            placeholder="Email"
            prefix={<AiOutlineMail />}
            onChange={handleChangeEmail}
            value={email}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>
            Role<Required>*</Required>
          </InputLabel>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={selectedOption ? selectedOption.value : 0}
            onChange={handleChange}
          >
            <Option value={0}>Select Role</Option>
            {Array.isArray(roles) &&
              roles.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
          </Select>
        </InputContainer>
        <ButtonWrapper>
          <Button onClick={onCancel}>Cancel</Button>
          <ModalBtn color="blue" onClick={handleEdit}>
            Update
          </ModalBtn>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default UserEditModal;
