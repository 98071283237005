import React, { FC, useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { UserAuthType } from "../../types/UserAuth";
import { TbUserCircle } from "react-icons/tb";
import { RiLockPasswordLine } from "react-icons/ri";
import { withFormik, FormikProps, Field } from "formik";
import * as Yup from "yup";
import { LoginFormprops, LoginUserValues } from "../../types/LoginUserType";
import {
  AlertText,
  Container,
  Card,
  InputContainer,
  Icon,
  Input,
  Button,
  Error,
  Image,
  LoginLink,
  GoogleLogin,
  GoogleImg,
  HomeLink,
  FooterText,
} from "./LoginElements";
import { GoogleLogin as Google } from "react-google-login";
import { gapi, loadAuth2 } from "gapi-script";
import {
  CLIENT_ID,
  LOGIN_TYPE,
  OFFICIAL_TEAM_NAME,
  STATUS_CODE,
} from "../../constants/GlobalConstant";
import { loginApi } from "../../services/RouteServices/AuthRouteApi";
import { UserInfo } from "../../types/UserAuth";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import { typeAlert } from "../../types/AlertMessageType";
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  mobileModel,
} from "react-device-detect";
import { getPublicIp } from "../../utils/IPUtil";

const LogoImg = "logo960.JPG";
const googleImg = "google.png";

const Login: FC = () => {
  const { userInfo, handleLoginInContext, handleLogout } = useContext(
    UserAuthContext
  ) as UserAuthType;

  const [isGoogle, setIsGoogle] = useState(true);
  const [isVisibleAlert, setIsVisibleAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<typeAlert>("info");
  const [username,setUsername] = useState("");
  const [password,setPassword] = useState("");

  useEffect(() => {
    console.log(userInfo);
    const setAuth2 = async () => {
      const auth2 = await loadAuth2(gapi, CLIENT_ID, "");
      // gapi.auth2.getAuthInstance();
    };
    setAuth2();
  }, []);

  const onSuccess = (res: any) => {
    // console.log("google login success", res.profileObj);
    getPublicIp().then((ip : string) => {
      const loginValue: UserInfo = {
        name: res.profileObj.name,
        userName: res.profileObj.name,
        email: res.profileObj.email,
        googleImgUrl: res.profileObj.imageUrl,
        loginType: LOGIN_TYPE.googleType,
        userLoginIp: ip || "unknown",
        userLoginDevice: `browser - ${browserName}, version - ${browserVersion}, os - ${osName}, v - ${osVersion}, mobile - ${mobileModel}`,
      };
      loginHandler(loginValue);
    });
  };

  const onFailure = (err: any) => {
    console.log("failed:", err);
  };


  const InnerForm = (props: FormikProps<LoginUserValues>) => {
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
      props;

    return (
      <>
        <AlertText>
          <AlertMessage
            visible={isVisibleAlert}
            message={alertMessage}
            typeAlert={alertType}
          />
        </AlertText>
        <form onSubmit={handleSubmit}>
          <Container>
            <Card>
              <Image src={LogoImg} alt="logo" />
              {/* <Title>LOGIN</Title> */}
              <HomeLink to={"/"}>Home</HomeLink>
              {!isGoogle ? (
                <>
                  <InputContainer>
                    <Icon>
                      <TbUserCircle size={"25px"} />
                    </Icon>
                    <Input
                      placeholder="email/username"
                      defaultValue={values.username}
                      // autoFocus
                      name="username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.username && errors.username && (
                      <Error>{errors.username}</Error>
                    )}
                  </InputContainer>
                  <InputContainer>
                    <Icon>
                      <RiLockPasswordLine size={"25px"} />
                    </Icon>
                    <Input
                      type={"password"}
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                   
                    {touched.password && errors.password && (
                      <Error>{errors.password}</Error>
                    )}
                  </InputContainer>
                  <Button type="submit">Login</Button>
                  <LoginLink onClick={() => setIsGoogle(!isGoogle)}>
                    Google Login
                  </LoginLink>
                </>
              ) : (
                <>
                  <Google
                    clientId={CLIENT_ID}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                    cookiePolicy={"single_host_origin"}
                    // isSignedIn={true}
                    render={(renderProps) => (
                      <GoogleLogin onClick={renderProps.onClick}>
                        <GoogleImg src={googleImg} alt="google" />
                        Login with Google
                      </GoogleLogin>
                    )}
                  />
                </>
              )}
            </Card>
          </Container>
          <FooterText>
            Developed by <i>"{OFFICIAL_TEAM_NAME}"</i> T
            <span onClick={() => setIsGoogle(!isGoogle)}>e</span>am
          </FooterText>
        </form>
      </>
    );
  };

  const LoginForm = withFormik<LoginFormprops, LoginUserValues>({
    mapPropsToValues: (props) => ({
      username: props.initialUsername|| username || "",
      password: props.initialPassword || password || "",
    }),
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Username/email is required!"),
      password: Yup.string().required("Password is required!"),
    }),
    handleSubmit({ username, password }: LoginUserValues) {
      getPublicIp().then((ip : string) => {
        const loginValue: UserInfo = {
          userName: username || "",
          password: password,
          email: username,
          loginType: LOGIN_TYPE.localType,
          userLoginIp: ip || "unknown",
          userLoginDevice: `browser - ${browserName}, version - ${browserVersion}, os - ${osName}, v - ${osVersion}, mobile - ${mobileModel}`,
        };
        loginHandler(loginValue);
        setUsername(username || "");
        setPassword(password || "")
      });
    },
  })(InnerForm);

  const loginHandler = async (loginVal: UserInfo) => {
    await loginApi(loginVal)
      .then((response) => {
        if (response?.data?.status) {
          const resData = response.data;
          if (resData.status === STATUS_CODE.successCode) {
            handleLoginInContext(resData?.data);
            showAlertDetail(true, resData.message, "success");
          } else if (resData.status === STATUS_CODE.blockCode) {
            showAlertDetail(true, resData.message, "warning");
            handleLogout(false);
          } else if (resData.status === STATUS_CODE.badRequestErrorCode) {
            showAlertDetail(true, resData.message, "warning");
            handleLogout(false);
          } else {
            showAlertDetail(true, resData.message, "warning");
            handleLogout(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        showAlertDetail(
          true,
          error?.response?.data?.message || error?.message,
          "warning"
        );
        handleLogout(false);
      });
  };

  const showAlertDetail = (
    isVisible: boolean,
    message: string,
    type: typeAlert
  ) => {
    setIsVisibleAlert(true);
    setAlertMessage(message);
    setAlertType(type);
  };

  return (
    <>
      <LoginForm />
    </>
  );
};

export default Login;
