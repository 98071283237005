import React, { useEffect, useState } from "react";
import { DatePicker, DatePickerProps, Input, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import Card from "../Shared/Card/Card";
import { UserDataType } from "../../types/UserDataType";
import {
  Container,
  Title,
  CardContainer,
  CardItem,
  TableContainer,
  RemoveButton,
  EditButton,
  StatusButton,
  InputWrapper,
} from "./UserElements";
import {
  deleteUserApi,
  getCountApi,
  getTotalCountApi,
  getUserListApi,
  updateUserApi,
  updateUserStatusApi,
} from "../../services/RouteServices/UserRouteApi";
import { PAGE_SIZE, STATUS, STATUS_CODE } from "../../constants/GlobalConstant";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import {
  UserResponseDataType,
  UserResponseDataTypeList,
  UserResponseType,
  UserResponseTypeList,
} from "../../types/UserResponseType";
import {
  DeleteUserData,
  EditUserData,
  EditUserStatusData,
  userRoleOptionType,
} from "../../types/UserDataType";
import { calcuateFromNowDate, convertToLocaleDate } from "../../utils/DateUtil";
import { getRoleListApi } from "../../services/RouteServices/RoleRouteApi";
import {
  RoleResponseData,
  RoleResponseDataTypeList,
  RoleResponseTypeList,
} from "../../types/RoleResponseType";
import { getCurrentUserOnly } from "../../utils/UserUtil";
import UserEditModal from "./UserModal/UserEditModal";
import UserDeleteModal from "./UserModal/UserDeleteModal";
import UserStatusModal from "./UserModal/UserStatusModal";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";

let roles: userRoleOptionType[] = [];

const User: React.FC = () => {
  const userName: string = getCurrentUserOnly();
  const [userData, setUserData] = useState([]);
  const [total, setTotal] = useState<number>(0);
  const [activeCount, setActiveCount] = useState<number>(0);
  const [blockCount, setBlockCount] = useState<number>(0);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [page, setPage] = useState<number>(1);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [roleId, setRoleId] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [updated, setUpdated] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [removeUsername, setRemoveUsername] = useState<string>("");
  const [roleList, setRoleList] = useState<RoleResponseData[]>([]);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  const columns: ColumnsType<UserDataType> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role: any) => (
        <>
          {role.name ? (
            <>{role.name}</>
          ) : (
            <span style={{ color: "red" }}>Invalid</span>
          )}
        </>
      ),
    },
    {
      title: "Active Status",
      dataIndex: "activeStatus",
      key: "activeStatus",
    },
    {
      title: "Created User",
      dataIndex: "createdUser",
      key: "createdUser",
      render: (user: string) => <>{user ? user : "-"}</>,
    },
    {
      title: "Updated User",
      dataIndex: "updatedUser",
      key: "updatedUser",
      render: (user: string) => <>{user ? user : "-"}</>,
    },
    {
      title: "Joined Date",
      dataIndex: "createdAt",
      key: "JoinedAt",
      render: (date: Date) => (
        <>{date ? <>{calcuateFromNowDate(date)}</> : "-"}</>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: Date) => (
        <>{date ? <>{convertToLocaleDate(date)}</> : "-"}</>
      ),
    },
    {
      title: "Updated Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: Date) => (
        <>{date ? <>{convertToLocaleDate(date)}</> : "-"}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          <RemoveButton
            onClick={() => {
              setShowRemoveModal(true);
              setUserId(record.id);
              setRemoveUsername(record.userName);
            }}
          />
          <StatusButton
            onClick={() => {
              setStatus(record.activeStatus);
              setShowStatusModal(true);
              setUserId(record.id);
            }}
          />
          <EditButton
            onClick={() => {
              setShowEditModal(true);
              setUsername(record.userName);
              setName(record.name);
              setEmail(record.email);
              setRoleId(record.roleId);
              setUserId(record.id);
            }}
          />
        </>
      ),
    },
  ];

  const selectedOption = roles.find((role) => role.value === roleId);

  useEffect(() => {
    getUserList();
    getTotalCount();
    getCount();
    getRoleList();
  }, [page, updated, deleted, searchText, startDate, endDate]);

  useEffect(() => {
    Array.isArray(roleList) &&
      roleList.map((role: RoleResponseData) => {
        const option: userRoleOptionType = {
          value: 0,
          label: "",
        };
        option.value = role.id;
        option.label = role.name;
        if (!roles.some((role) => role.value === option.value)) {
          roles.push(option);
        }
      });
  }, [roleList]);

  const getTotalCount = async () => {
    const total = await getTotalCountApi();
    setTotal(total.data.data);
  };
  const getCount = async () => {
    const activeCount = await getCountApi({ status: STATUS.activeStatus });
    const blockCount = await getCountApi({ status: STATUS.blockStatus });
    setActiveCount(activeCount.data.data);
    setBlockCount(blockCount.data.data);
  };

  const getUserList = async () => {
    const inputData = {
      offset,
      limit: pageSize,
      searchText,
      startDate,
      endDate,
    };
    await getUserListApi(inputData)
      .then((response: UserResponseTypeList) => {
        const responseData: UserResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setUserData(responseData.data);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const getRoleList = async () => {
    const inputData = { offset, limit: pageSize };
    await getRoleListApi(inputData)
      .then((response: RoleResponseTypeList) => {
        const responseData: RoleResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setRoleList(responseData.data);
            setModalError("");
            // setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handlePageChange = (page: number) => {
    setOffset(CalculateOffset(page, pageSize));
    setPage(page);
  };

  const handleChange = (value: number) => {
    setRoleId(value);
  };

  // const validateInput = () => {
  //   let isValidSuccess = false;
  //   setModalSuccess("");
  //   if (!username) {
  //     setModalError("Username is required!");
  //   } else if (username.length > 25) {
  //     setModalError("Username cannot be exceed 25 characters!");
  //   } else if (!email) {
  //     setModalError("Email is required!");
  //   } else if (roleId === 0) {
  //     setModalError("Please select role!");
  //   } else {
  //     isValidSuccess = true;
  //   }
  //   return isValidSuccess;
  // };

  const handleStatusChange = (value: string) => {
    setStatus(value);
  };

  const handleStatusUpdate = async () => {
    const editUserStatus: EditUserStatusData = {
      id: userId,
      activeStatus: status,
      updatedUser: userName,
    };
    await updateUserStatusApi(editUserStatus)
      .then((response: UserResponseType) => {
        const responseData: UserResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowStatusModal(false);
              setUpdated(!updated);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handleEdit = async () => {
    // if (validateInput()) {
    // if (roles.some((role) => role.value === roleId)) {
    const editUserData: EditUserData = {
      id: userId,
      userName: username,
      name,
      email,
      roleId,
      updatedUser: userName,
    };
    await updateUserApi(editUserData)
      .then((response: UserResponseType) => {
        const responseData: UserResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowEditModal(false);
              setUpdated(!updated);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
    // } else {
    //   setModalError("Invalid role id!");
    // }
    // }
  };

  const handleDelete = async () => {
    const deleteData: DeleteUserData = {
      userId,
      deletedUser: userName,
    };
    await deleteUserApi(deleteData)
      .then((response: UserResponseType) => {
        const responseData: UserResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowRemoveModal(false);
              setDeleted(!deleted);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const onChangeStartDate: DatePickerProps["onChange"] = (date, dateString) => {
    setStartDate(dateString);
    setOffset(0);
    setPage(1);
  };
  const onChangeEndDate: DatePickerProps["onChange"] = (date, dateString) => {
    setEndDate(dateString);
    setOffset(0);
    setPage(1);
  };

  return (
    <>
      <Container>
        <Title>Users</Title>
        <CardContainer>
          <CardItem>
            <Card backgroundColor="#bde1e9" color="black">
              <h2>Total</h2>
              <p>{total}</p>
            </Card>
          </CardItem>
          <CardItem>
            <Card backgroundColor="#abddad" color="black">
              <h2>Active</h2>
              <p>{activeCount}</p>
            </Card>
          </CardItem>
          <CardItem>
            <Card backgroundColor="#c97438" color="white">
              <h2>Block</h2>
              <p>{blockCount}</p>
            </Card>
          </CardItem>
        </CardContainer>
        <InputWrapper>
          <Input
            placeholder="Search"
            style={{ width: "25%" }}
            size="large"
            onChange={(e) => {
              setSearchText(e.target.value);
              setOffset(0);
              setPage(1);
            }}
          />
          <DatePicker
            placeholder="Start Date"
            size="large"
            onChange={onChangeStartDate}
            style={{ margin: "0px 5px" }}
          />
          <DatePicker
            placeholder="End Date"
            size="large"
            onChange={onChangeEndDate}
          />
        </InputWrapper>
        <TableContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
          <Table
            rowKey={"id"}
            columns={columns}
            dataSource={userData}
            scroll={{ x: 100 }}
            pagination={{
              defaultPageSize: pageSize,
              showSizeChanger: false,
              total: total,
              defaultCurrent: page,
              current: page,
              onChange: (page) => handlePageChange(page),
            }}
            footer={() => (
              <p>
                Total <b>{total}</b> records found.
              </p>
            )}
          />
        </TableContainer>
      </Container>

      {/* Edit Modal */}
      <UserEditModal
        onCancel={() => {
          setShowEditModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        error={modalError}
        success={modalSuccess}
        showEditModal={showEditModal}
        handleChangeUsername={(e) => setUsername(e.target.value)}
        username={username}
        handleChangeName={(e) => setName(e.target.value)}
        name={name}
        handleChangeEmail={(e) => setEmail(e.target.value)}
        email={email}
        handleChange={handleChange}
        roles={roles}
        handleEdit={handleEdit}
        selectedOption={selectedOption}
      />
      {/* Delete Modal */}
      <UserDeleteModal
        onCancel={() => {
          setShowRemoveModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        showRemoveModal={showRemoveModal}
        error={modalError}
        success={modalSuccess}
        handleDelete={handleDelete}
        removeUsername={removeUsername}
      />

      {/* Status Modal */}
      <UserStatusModal
        showStatusModal={showStatusModal}
        modalError={modalError}
        modalSuccess={modalSuccess}
        onCancel={() => {
          setShowStatusModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        status={status}
        handleStatusChange={handleStatusChange}
        handleStatusUpdate={handleStatusUpdate}
      />
    </>
  );
};

export default User;
