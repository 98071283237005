import { Pagination } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ALL_STATUS,
  ROOM_PAGE_SIZE,
  STATUS_CODE,
} from "../../constants/GlobalConstant";
import { getRequestRoomListApi } from "../../services/RouteServices/RequestRoomRouteApi";
import {
  RoomRequestResponseData,
  RoomRequestResponseDataTypeList,
  RoomRequestResponseTypeList,
} from "../../types/RequestRoomDataType";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";
import { randomImageUtil } from "../../utils/RandomImageUtil";
import RequestRoomCard from "../RequestRoom/RequestRoomCard";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import RoomCardRandomImgShow from "../Shared/RoomCard/RoomCardComponents/RoomCardRandomImgShow";
import { CardContainer, Container, Title } from "./ManageRequestRoomsElements";

const ManageRequestRooms: React.FC = () => {
  const navigate = useNavigate();
  const [requestRooms, setRequestRooms] = useState<RoomRequestResponseData[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(ROOM_PAGE_SIZE);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [justShowImg, setJustShowImg] = useState(randomImageUtil());

  useEffect(() => {
    getRequestRoomList();
  }, []);

  const getRequestRoomList = async () => {
    const inputData = {
      offset,
      limit: pageSize,
      approvalStatus: ALL_STATUS,
    };
    await getRequestRoomListApi(inputData)
      .then((response: RoomRequestResponseTypeList) => {
        const responseData: RoomRequestResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setRequestRooms(responseData.data);
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 100);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setOffset(CalculateOffset(page, pageSize));
  };

  return (
    <>
      <Container>
        <Title>Manage Request Rooms</Title>
        {requestRooms.length === 0 ? (
          <RoomCardRandomImgShow showImage={justShowImg} />
        ) : (
          <>
            {modalError && (
              <AlertMessage
                message={modalError}
                visible={true}
                typeAlert={"error"}
                width={"60%"}
              />
            )}
            {modalSuccess && (
              <AlertMessage
                message={modalSuccess}
                visible={true}
                typeAlert={"success"}
                width={"60%"}
              />
            )}
            <RequestRoomCard rooms={requestRooms} type="manageRequestRooms" />
            {requestRooms?.length !== 0 && (
              <Pagination
                defaultCurrent={currentPage}
                current={currentPage}
                defaultPageSize={pageSize}
                showSizeChanger={false}
                onChange={(page) => handlePageChange(page)}
                total={total}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default ManageRequestRooms;
