import React, { useEffect, useState } from "react";
import Card from "../Shared/Card/Card";
import {
  Container,
  Title,
  CardContainer,
  CardItem,
  ContentWrapper,
  ProfileWrapper,
  Image,
  ProfileContent,
  DiamondWrapper,
  DiamondIcon,
} from "./DashboardElements";
import { getCurrentUser } from "../../utils/UserUtil";
import { getRoomListByUserIdApi } from "../../services/RouteServices/RoomRouteApi";
import {
  RoomResponseDataTypeList,
  RoomResponseTypeList,
} from "../../types/RoomResponseType";
import {
  ROOT_USER_ID,
  STATUS_CODE,
  TOTAL_REQUEST_ROOM_LIMIT,
  TOTAL_ROOM_LIMIT_FOR_EACH_USER,
  TOTAL_ROOM_LIMIT_FOR_ROOT_USER,
} from "../../constants/GlobalConstant";
import { UserInfo } from "../../types/UserAuth";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import { useNavigate } from "react-router-dom";
import { IoCreateSharp } from "react-icons/io5";
import {
  convertToLocaleDate,
  getLocalCurrentDateOnlyStr,
} from "../../utils/DateUtil";
import { FaUserCircle } from "react-icons/fa";
import { getUserByIdApi } from "../../services/RouteServices/UserRouteApi";
import {
  UserResponseDataType,
  UserResponseType,
} from "../../types/UserResponseType";
import {
  RoomRequestResponseDataTypeList,
  RoomRequestResponseTypeList,
} from "../../types/RequestRoomDataType";
import { getRequestRoomListByUserIdApi } from "../../services/RouteServices/RequestRoomRouteApi";

const Dashboard: React.FC = () => {
  const userInfo: UserInfo = getCurrentUser();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [userPoint, setUserPoint] = useState<string>("");
  const [requestRoomTotal, setRequestRoomTotal] = useState<number>(0);

  useEffect(() => {
    getRoomListByUserId();
    getUserById();
    getRequestRoomListByUserId();

    const cUser = userInfo?.userName ? userInfo?.userName : "unknown";
    const userAccessLog =
      cUser + " is accessing Dashboard page at " + getLocalCurrentDateOnlyStr();
    console.log(userAccessLog);
  }, []);

  const getRequestRoomListByUserId = async () => {
    const inputData = {
      offset,
      limit: pageSize,
      userId: userInfo.id,
    };
    await getRequestRoomListByUserIdApi(inputData)
      .then((response: RoomRequestResponseTypeList) => {
        const responseData: RoomRequestResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setRequestRoomTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 100);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const getRoomListByUserId = async () => {
    const inputData = { userId: userInfo.id, offset, limit: pageSize };
    await getRoomListByUserIdApi(inputData)
      .then((response: RoomResponseTypeList) => {
        const responseData: RoomResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const getUserById = async () => {
    const inputData = { id: userInfo?.id || 0 };
    await getUserByIdApi(inputData)
      .then((response: UserResponseType) => {
        const responseData: UserResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            const user = responseData.data;
            setUserPoint(user?.userPoints);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Container>
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"60%"}
          />
        )}
        <Title>Dashboard</Title>
        <CardContainer>
          <CardItem>
            <Card color="lightcoral">
              <h2>Rooms</h2>
              <ContentWrapper>
                {userInfo.id === ROOT_USER_ID ? (
                  <p onClick={() => navigate("/room")}>
                    {total}/{TOTAL_ROOM_LIMIT_FOR_ROOT_USER}
                  </p>
                ) : (
                  <p onClick={() => navigate("/room")}>
                    {total}/{TOTAL_ROOM_LIMIT_FOR_EACH_USER}
                  </p>
                )}
                <IoCreateSharp
                  onClick={() => navigate("/room/post")}
                  size={30}
                  color={"green"}
                />
              </ContentWrapper>
            </Card>
          </CardItem>
          <CardItem>
            <Card color="#ff7a45">
              <h2>Request Rooms</h2>
              <ContentWrapper>
                <p onClick={() => navigate("/requestRoom")}>
                  {requestRoomTotal}/{TOTAL_REQUEST_ROOM_LIMIT}
                </p>
              </ContentWrapper>
            </Card>
          </CardItem>
          <CardItem>
            <Card color="forestgreen">
              <h2>Events</h2>
              <p>{0}</p>
            </Card>
          </CardItem>
        </CardContainer>
        <ProfileWrapper>
          <Card>
            <ProfileContent>
              {userInfo.googleImgUrl ? (
                <Image src={userInfo.googleImgUrl} alt="profileImg" />
              ) : (
                <FaUserCircle size={85} color="#003eb3" />
              )}
              <div>
                <DiamondWrapper>
                  <DiamondIcon />
                  <h3 style={{ marginBottom: "0px" }}>{userPoint || "0"}</h3>
                </DiamondWrapper>
                <h4>{userInfo.userName}</h4>
                <h4>{userInfo.email}</h4>
                <h5 style={{ color: "#595959" }}>Role: {userInfo.roleName}</h5>
                <h5 style={{ color: "#595959" }}>
                  Last Login: {convertToLocaleDate(userInfo.lastActive)}
                </h5>
              </div>
            </ProfileContent>
          </Card>
        </ProfileWrapper>
      </Container>
    </>
  );
};

export default Dashboard;
