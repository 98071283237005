import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { ROOM_PAGE_SIZE, STATUS_CODE } from "../../constants/GlobalConstant";
import {
  RoomRequestResponseData,
  RoomRequestResponseDataTypeList,
  RoomRequestResponseTypeList,
} from "../../types/RequestRoomDataType";
import {
  CardContainer,
  Container,
  Header,
  Title,
} from "./MyRequestRoomElements";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";
import { UserInfo } from "../../types/UserAuth";
import { getCurrentUser } from "../../utils/UserUtil";
import { getRequestRoomListByUserIdApi } from "../../services/RouteServices/RequestRoomRouteApi";
import RoomCardRandomImgShow from "../Shared/RoomCard/RoomCardComponents/RoomCardRandomImgShow";
import { randomImageUtil } from "../../utils/RandomImageUtil";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import RequestRoomCard from "../RequestRoom/RequestRoomCard";
import RoomCard from "../Shared/RoomCard/RoomCard";

const MyRequestRoom: React.FC = () => {
  const navigate = useNavigate();
  const userInfo: UserInfo = getCurrentUser();
  const [requestRooms, setRequestRooms] = useState<RoomRequestResponseData[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(ROOM_PAGE_SIZE);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [justShowImg, setJustShowImg] = useState(randomImageUtil());

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setOffset(CalculateOffset(page, pageSize));
  };

  useEffect(() => {
    getRequestRoomListByUserId();
  }, []);

  const getRequestRoomListByUserId = async () => {
    const inputData = {
      offset,
      limit: pageSize,
      userId: userInfo.id,
    };
    await getRequestRoomListByUserIdApi(inputData)
      .then((response: RoomRequestResponseTypeList) => {
        const responseData: RoomRequestResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setRequestRooms(responseData.data);
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 100);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  return (
    <Container>
      <Header>
        <Title>My Request Rooms</Title>
      </Header>
      {requestRooms.length === 0 ? (
        <RoomCardRandomImgShow showImage={justShowImg} />
      ) : (
        <>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"60%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"60%"}
            />
          )}
          <RequestRoomCard rooms={requestRooms} />

          {requestRooms?.length !== 0 && (
            <Pagination
              defaultCurrent={currentPage}
              current={currentPage}
              defaultPageSize={pageSize}
              showSizeChanger={false}
              onChange={(page) => handlePageChange(page)}
              total={total}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default MyRequestRoom;
