import { Input, Modal, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import {
  Container,
  Title,
  TableContainer,
  Wrapper,
  Button,
  InputContainer,
  InputLabel,
  Required,
  ButtonWrapper,
  ModalBtn,
  DetailButton,
  EditButton,
  TableBtnWrapper,
  RemoveButton,
  DeleteBtn,
  EditModalBtn,
} from "./MRTLineElements";
import {
  DeleteMRTLineData,
  EditMRTLineData,
  MRTLineData,
  MRTLineList,
} from "../../types/MRTLineDataType";
import { Input as AntInput } from "antd";
import { BiTrain } from "react-icons/bi";
import { MdOutlineColorLens, MdOutlineDescription } from "react-icons/md";
import { AiOutlineCheckSquare, AiOutlineArrowRight } from "react-icons/ai";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import { useNavigate } from "react-router-dom";
import {
  addMrtLineApi,
  deleteMrtInMrtLineByMrtLineIdApi,
  deleteMrtLineApi,
  getMrtListApi,
  updateMrtLineApi,
} from "../../services/RouteServices/MrtLineRouteApi";
import { PAGE_SIZE, STATUS_CODE } from "../../constants/GlobalConstant";
import {
  MRTResponseDataType,
  MRTResponseDataTypeList,
  MRTResponseType,
  MRTResponseTypeList,
} from "../../types/MRTLineResponseType";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";
import { getCurrentUserOnly } from "../../utils/UserUtil";

const MRTLine: React.FC = () => {
  const navigate = useNavigate();
  const userName: string = getCurrentUserOnly();
  const [mrtLines, setMrtLines] = useState([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mrtLineName, setMrtLineName] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [colorCode, setColorCode] = useState<string>("");
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editId, setEditId] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [page, setPage] = useState<number>(1);
  const [updated, setUpdated] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [removeMrtId, setRemoveMrtId] = useState<number>(0);
  const [removeMrtName, setRemoveMrtName] = useState<string>("");
  const [deleted, setDeleted] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const columns: ColumnsType<MRTLineList> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "MRT Line Name",
      dataIndex: "mrtLineName",
      key: "mrtLineName",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      render: (remark: string) => <div>{remark ? remark : "-"}</div>,
    },
    {
      title: "Color Code",
      dataIndex: "colorCode",
      key: "colorCode",
      onCell: (record: any) => ({
        style: { background: record.colorCode },
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (desc: string) => <div>{desc ? desc : "-"}</div>,
    },
    {
      title: "Action",
      key: "action",
      render: (record: any) => (
        <>
          <TableBtnWrapper>
            <EditButton
              onClick={() => {
                setShowEditModal(true);
                setModalSuccess("");
                setEditId(record.id);
                setMrtLineName(record.mrtLineName);
                setRemark(record.remark);
                setDescription(record.description);
                setColorCode(record.colorCode);
              }}
            />
            <RemoveButton
              onClick={() => {
                setShowRemoveModal(true);
                setRemoveMrtId(record.id);
                setRemoveMrtName(record.mrtLineName);
              }}
            />
            <DetailButton
              onClick={() => {
                navigate(
                  {
                    pathname: "detail",
                    search: `?id=${record.id}`,
                  },
                  { state: record }
                );
              }}
            >
              Detail
              <AiOutlineArrowRight />
            </DetailButton>
          </TableBtnWrapper>
        </>
      ),
    },
  ];

  const getMrtList = async () => {
    const inputData = { offset, limit: pageSize, searchText };
    await getMrtListApi(inputData)
      .then((response: MRTResponseTypeList) => {
        const responseData: MRTResponseDataTypeList = response?.data;
        setTotal(responseData.total);
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setMrtLines(responseData.data);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handleAdd = async () => {
    // if (validateInput()) {
    // pass all validation to save
    const newMrtData: MRTLineData = {
      mrtLineName,
      remark,
      description,
      colorCode,
      createdUser: userName,
    };
    await addMrtLineApi(newMrtData)
      .then((response: MRTResponseType) => {
        const responseData: MRTResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowModal(false);
              setAdded(!added);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
    // }
  };

  const handleEdit = async () => {
    // if (validateInput()) {
    // pass all validation to save
    const editMrtData: EditMRTLineData = {
      id: editId,
      mrtLineName,
      remark,
      description,
      colorCode,
      updatedUser: userName,
    };
    //edit mrt line
    await updateMrtLineApi(editMrtData)
      .then((response: MRTResponseType) => {
        const responseData: MRTResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowEditModal(false);
              setUpdated(!updated);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
    // }
  };

  const handleDelete = async () => {
    const deleteData: DeleteMRTLineData = {
      mrtLineId: removeMrtId,
      deletedUser: userName,
    };
    await deleteMrtLineApi(deleteData)
      .then((response: MRTResponseType) => {
        const responseData: MRTResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            deleteMrtInMrtLineByMrtLineIdApi(deleteData)
              .then((response) => {
                if (response?.status === STATUS_CODE.successCode) {
                  setModalError("");
                  setModalSuccess(responseData?.message);
                  setTimeout(() => {
                    setShowRemoveModal(false);
                    setDeleted(!deleted);
                  }, 1000);
                }
              })
              .catch((err) => {
                console.log(err);
                setModalError(err?.response?.data?.message || err?.message);
              });
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  useEffect(() => {
    getMrtList();
  }, [page, updated, deleted, added, searchText]);

  // const validateInput = () => {
  //   let isValidSuccess = false;
  //   setModalSuccess("");
  //   if (!mrtLineName) {
  //     setModalError("MRT Line Name is required!");
  //   } else if (mrtLineName.length > 50) {
  //     setModalError("MRT Line Name cannot be exceed 50 characters!");
  //   } else if (!colorCode) {
  //     setModalError("Color code is required!");
  //   } else if (colorCode.length > 50) {
  //     setModalError("Color code cannot be exceed 50 characters!");
  //   } else {
  //     isValidSuccess = true;
  //   }
  //   return isValidSuccess;
  // };

  const handleCancel = () => {
    clearInputValues();
    setShowModal(false);
  };

  const clearInputValues = () => {
    setModalSuccess("");
    setModalError("");
    setMrtLineName("");
    setRemark("");
    setDescription("");
    setColorCode("");
  };

  const handlePageChange = (page: number) => {
    setOffset(CalculateOffset(page, pageSize));
    setPage(page);
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Title>MRT Lines</Title>
          <ModalBtn
            onClick={() => {
              setShowModal(true);
              clearInputValues();
            }}
          >
            Add New
          </ModalBtn>
        </Wrapper>
        <Input
          placeholder="Search"
          style={{ width: "25%", marginBottom: "5px" }}
          size="large"
          onChange={(e) => {
            setSearchText(e.target.value);
            setOffset(0);
            setPage(1);
          }}
        />
        <TableContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
          <Table
            rowKey={"id"}
            columns={columns}
            dataSource={mrtLines}
            scroll={{ x: 100 }}
            pagination={{
              defaultPageSize: pageSize,
              showSizeChanger: false,
              total: total,
              defaultCurrent: page,
              onChange: (page) => handlePageChange(page),
            }}
            footer={() => (
              <p>
                Total <b>{total}</b> records found.
              </p>
            )}
          />
        </TableContainer>
      </Container>

      {/* Add Modal */}
      <Modal
        title="Add New MRT Line"
        open={showModal}
        onCancel={handleCancel}
        footer={null}
      >
        <InputContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
        </InputContainer>
        <InputContainer>
          <InputLabel>
            MRT Line Name<Required>*</Required>
          </InputLabel>
          <AntInput
            size="large"
            placeholder="MRT Line Name"
            prefix={<BiTrain />}
            onChange={(e) => setMrtLineName(e.target.value)}
            value={mrtLineName}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Remark</InputLabel>
          <AntInput
            size="large"
            placeholder="Remark"
            prefix={<AiOutlineCheckSquare />}
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Description</InputLabel>
          <AntInput
            size="large"
            placeholder="Description"
            prefix={<MdOutlineDescription />}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>
            Color Code <Required>*</Required>
          </InputLabel>
          <AntInput
            size="large"
            placeholder="Color Code"
            prefix={<MdOutlineColorLens />}
            onChange={(e) => setColorCode(e.target.value)}
            value={colorCode}
            maxLength={50}
          />
        </InputContainer>
        <ButtonWrapper>
          <Button onClick={handleCancel}>Cancel</Button>
          <ModalBtn onClick={handleAdd}>Add</ModalBtn>
        </ButtonWrapper>
      </Modal>

      {/* Edit Modal */}
      <Modal
        title="Edit MRT Line"
        open={showEditModal}
        onCancel={() => {
          setShowEditModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        footer={null}
      >
        <InputContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
        </InputContainer>
        <InputContainer>
          <InputLabel>
            MRT Line Name<Required>*</Required>
          </InputLabel>
          <AntInput
            size="large"
            placeholder="MRT Line Name"
            prefix={<BiTrain />}
            onChange={(e) => setMrtLineName(e.target.value)}
            value={mrtLineName}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Remark</InputLabel>
          <AntInput
            size="large"
            placeholder="Remark"
            prefix={<AiOutlineCheckSquare />}
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Description</InputLabel>
          <AntInput
            size="large"
            placeholder="Description"
            prefix={<MdOutlineDescription />}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>
            Color Code <Required>*</Required>
          </InputLabel>
          <AntInput
            size="large"
            placeholder="Color Code"
            prefix={<MdOutlineColorLens />}
            onChange={(e) => setColorCode(e.target.value)}
            value={colorCode}
            maxLength={50}
          />
        </InputContainer>
        <ButtonWrapper>
          <Button
            onClick={() => {
              setShowEditModal(false);
              setModalSuccess("");
              setModalError("");
            }}
          >
            Cancel
          </Button>
          <EditModalBtn onClick={handleEdit}>Update</EditModalBtn>
        </ButtonWrapper>
      </Modal>

      {/* Delete Modal */}
      <Modal
        title="Delete MRT Line"
        open={showRemoveModal}
        onCancel={() => {
          setShowRemoveModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        footer={null}
      >
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {modalSuccess && (
          <AlertMessage
            message={modalSuccess}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <h2>
          Are you sure you want to delete [
          <span style={{ color: "red" }}>{removeMrtName}</span>]?
        </h2>
        <ButtonWrapper>
          <Button
            onClick={() => {
              setShowRemoveModal(false);
              setModalSuccess("");
              setModalError("");
            }}
          >
            Cancel
          </Button>
          <DeleteBtn onClick={handleDelete}>Delete</DeleteBtn>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default MRTLine;
