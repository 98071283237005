import { IoMdDownload } from "react-icons/io";
import styled from "styled-components";
import { FaFileCsv } from "react-icons/fa";

export const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
`;

export const ChartContainer = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const DownloadIconWrapper = styled.div`
  display: flex;
  justify-self: flex-end;
  margin-right: 10px;
`;
export const DownloadIcon = styled(IoMdDownload)`
  font-size: 150%;
  color: #2f54eb;
  cursor: pointer;

  &:hover {
    color: #1d39c4;
  }
`;

export const CSVDownloadIcon = styled(FaFileCsv)`
  font-size: 140%;
  color: #2f54eb;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    color: #1d39c4;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

export const BadgeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;
