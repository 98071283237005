import moment from "moment";

export const PROFILES = [
  "/images/picture1.jpeg",
  "/images/picture2.jpeg",
  "/images/picture3.jpeg",
  "/images/picture4.jpeg",
  "/images/picture5.jpeg",
  "/images/picture6.jpeg",
  "/images/picture7.jpeg",
  "/images/picture8.jpeg",
  "/images/picture9.jpeg",
];

export const HOME_IMG = "/images/bagan1.jpg";

// export const CLIENT_ID = "787919263746-179p91e84tlhp7ifak36k447h193abfq.apps.googleusercontent.com";

export const CLIENT_ID =
  "848845258354-kcnvncld6enh9hti7uhqfn4c0tp7ehtv.apps.googleusercontent.com";

export const LOGIN_TYPE = {
  googleType: "googleLogin",
  localType: "localLogin",
};

export const STATUS_CODE = {
  successCode: 200,
  blockCode: 600,
  internalServerErrorCode: 500,
  unauthorizedCode: 401,
  badRequestErrorCode: 400,
};

export const PAGE_SIZE = 5;
export const ROOM_PAGE_SIZE = 9;
export const ICON_CONTAINER_COLOR = "#758970";

export const STATUS = {
  activeStatus: "allow",
  blockStatus: "block",
  deactivateStatus: "deactivate",
  deleteStatus: "delete",
};
export const CHECK_SPECIAL_ROLE_ID = 2;
export const NORMAL_ROLE_ID = 3;
export const UPLOADABLE_IMG_MAX_LIMIT = 4;
export const UPLOADABLE_REQUEST_ROOM_IMG_LIMIT = 1;
export const ALLOW_IMG_EXTENSION_LIST = ".gif,.png,.jpeg,.jpg";

export const APPROVE_STATUS = "approve";
export const REJECT_STATUS = "reject";
export const PENDING_STATUS = "pending";
export const DUPLICATE_STATUS = "duplicate";
export const RESERVE_STATUS = "reserve";
export const ALL_STATUS = "all";

export const ANONYMOUS_USER = "anonymous";

export const ORG_AND_WATERMARK_TEXT = "Salone Room";
export const OFFICIAL_TEAM_NAME = "Place For Burmese";

export const CURRENT_MONTH = moment().format("MMMM");
export const CURRENT_YEAR = moment().format("YYYY");

export const TOTAL_ROOM_LIMIT_FOR_EACH_USER = 2;
export const TOTAL_ROOM_LIMIT_FOR_ROOT_USER = 100;
export const TOTAL_REQUEST_ROOM_LIMIT = 1;

export const ROOT_USER_ID = 1;

export const LOG_NEW_LEVEL = "new";
export const LOG_INFO_LEVEL = "info";
export const LOG_DEBUG_LEVEL = "debug";
export const LOG_WARN_LEVEL = "warn";
export const LOG_ALERT_LEVEL = "alert";
