import styled from "styled-components";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import { ICON_CONTAINER_COLOR } from "../../constants/GlobalConstant";
import { IoMdDownload } from "react-icons/io";
import { AiFillFilePdf } from "react-icons/ai";

export const Container = styled.div`
  margin: 30px;

  @media screen and (max-width: 600px) {
    margin: 12px;
  }
`;

export const Card = styled.div`
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
`;

export const ImgContainer = styled.div`
  width: 100%;
`;

export const ImgWrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;
  text-align: center;
  height: 400px;

  @media screen and (max-width: 600px) {
    height: 300px;
  }
`;

export const Image = styled.img`
  width: auto;
  height: auto;
`;

export const LeftArrow = styled(BsArrowLeftCircle)`
  position: absolute;
  z-index: 8;
  left: 0;
  top: 40%;
  margin-left: 10px;
  cursor: pointer;
  font-size: 120%;
  color: grey;
`;

export const RightArrow = styled(BsArrowRightCircle)`
  position: absolute;
  z-index: 8;
  right: 0;
  top: 40%;
  margin-right: 10px;
  cursor: pointer;
  font-size: 120%;
  color: grey;
`;

export const ContentStyle = styled.div`
  width: 100%;
  color: #fff;
  lineheight: 160px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyContentStyle = styled.div`
  margin: 0;
  height: 100px;
  width: 100%;
  color: #fff;
  lineheight: 160px;
  position: relative;
`;

export const ContentContainer = styled.div`
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.div`
  display: flex;
  // justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  background-color: ${ICON_CONTAINER_COLOR};
  padding: 3px 10px;
  border-radius: 10px;
  color: #fff;
  // height: 30px;
`;

export const Label = styled.div`
  font-size: 90%;
  font-weight: 600;
  padding: 10px;
`;

export const Text = styled.div`
  font-size: 90%;
  overflow-wrap: anywhere;
  white-space: pre-line;
`;

export const Button = styled.div`
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #848884;
  color: #fff;
  padding: 8px 15px;
  border-radius: 10px;
  margin: 5px;
  font-weight: 500;
  max-width: 100px;
  font-size: 90%;

  &:hover {
    background-color: #71797e;
    color: #fff;
  }
`;

export const RoomDetails = styled.div`
  margin-left: 30px;
  padding: 40px 0px;
  color: black;
  overflow-wrap: anywhere;
  white-space: pre-line;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DownloadIcon = styled(IoMdDownload)`
  font-size: 190%;
  color: #2f54eb;
  cursor: pointer;
  margin-right: 10%;

  &:hover {
    color: #1d39c4;
  }
`;

export const PdfDownloadIcon = styled(AiFillFilePdf)`
  font-size: 190%;
  color: #2f54eb;
  cursor: pointer;
  margin-right: 10%;

  &:hover {
    color: #1d39c4;
  }
`;

export const DownloadIconWrapper = styled.div`
  display: flex;
  margin-right: 10%;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const TitleWrapper =styled.div`
  padding: 20px 30px;
`