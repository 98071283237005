import React, {FC, ReactNode} from "react";
import { Container } from "./CardElements";
import {CardProps} from "../../../types/CommonType";

const Card : FC<CardProps> = ({children, backgroundColor, color}) => {
  return (
    <>
      <Container backgroundColor={backgroundColor} color={color}>
        {children}
      </Container>
    </>
  );
}

export default Card;
