import {
  GetRoomById,
  GetRoomDistinctNameData,
  GetRoomListByMonthData,
  GetRoomListByYearData,
  RoomDataType,
  RoomDeleteDataType,
  RoomListByUserIdData,
  RoomPaginationData,
  RoomUpdateStatus,
} from "../../types/RoomDataType";
import http from "../axiosService";

export const addRoomApi = async (inputData: RoomDataType) => {
  return await http.post("/room/add", inputData);
};

export const updateRoomApi = async (inputData: RoomDataType) => {
  return await http.post("/room/update", inputData);
};

export const updateRoomApprovalStatusApi = async (
  inputData: RoomUpdateStatus
) => {
  return await http.post("/room/update/approvalStatus", inputData);
};

export const deleteRoomApi = async (inputData: RoomDeleteDataType) => {
  return await http.post("/room/delete", inputData);
};

export const getRoomListApi = async (inputData: RoomPaginationData) => {
  return await http.post("/room/get/list", inputData);
};

export const getRoomListDistinctNameApi = async (
  inputData: GetRoomDistinctNameData
) => {
  return await http.post("/room/get/list/distinctName", inputData);
};

export const getRoomListByMonthApi = async (
  inputData: GetRoomListByMonthData
) => {
  return await http.post("/room/get/listByMonth", inputData);
};

export const getRoomListByYearApi = async (
  inputData: GetRoomListByYearData
) => {
  return await http.post("/room/get/listByYear", inputData);
};

export const getRoomListByUserIdApi = async (
  inputData: RoomListByUserIdData
) => {
  return await http.post("/room/get/listByUserId", inputData);
};

export const getRoomByIdApi = async (inputData: GetRoomById) => {
  return await http.post("/room/getById", inputData);
};
