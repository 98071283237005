import { FC } from "react";
import { Text } from "./AlertMessageElements";
import { Alert, Space } from "antd";
import { MessageAlertProp } from "../../../types/AlertMessageType";

const AlertMessage = ({
  visible,
  message,
  typeAlert,
  width = "50%",
}: MessageAlertProp) => {
  return (
    <>
      {visible && (
        <Text>
          <Space
            direction="vertical"
            style={{ width: width, marginBottom: "5px" }}
          >
            <Alert
              message={message}
              type={typeAlert || ""}
              // closable
              showIcon
            />
          </Space>
        </Text>
      )}
    </>
  );
};

export default AlertMessage;
