import React from "react";
import ReactLoading from "react-loading";

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "60vh",
      }}
    >
      <ReactLoading
        type={"cylon"}
        color={"dodgerblue"}
        height={120}
        width={120}
      />
    </div>
  );
};

export default Loading;
