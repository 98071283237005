import moment from "moment";
import "moment-timezone";

const currentTimezone = moment.tz.guess();

export const convertToLocaleDate = (date: Date) => {
  let dateFormat = null;
  if (date) {
    dateFormat = moment(date).tz(currentTimezone).format("MMM D, YYYY LTS");
    // dateFormat = moment(date).tz(currentTimezone).format("YYYY-MM-DD HH:mm:ss");
  }
  return dateFormat;
};

export const getLocalCurrentDateOnlyStr = (): string => {
  const currentDate = moment();
  const formattedDate = currentDate.tz(currentTimezone).format("YYYY-MM-DD");
  return formattedDate;
};

export const convertDateFormat = (date: string): string => {
  let resDate: string = "";
  if (date) {
    resDate = moment(date).format("D MMM YYYY");
  }
  return resDate;
};

export const manageStartDateFormat = (startDateStr: string): string => {
  const startDate = moment(startDateStr);
  const todayDate = getLocalCurrentDateOnlyStr();

  let resDate: string = "";
  if (startDate.isBefore(todayDate)) {
    resDate = "immediately";
  } else if (startDate.isSame(todayDate)) {
    resDate = "today";
  } else {
    resDate = convertDateFormat(startDateStr || "");
  }
  return resDate;
};

export const calcuateFromNowDate = (inputDate: Date) => {
  return moment(inputDate).fromNow();
};

export const getCurrentYear = () => {
  const currentDate = moment();
  const currentYear = currentDate.year();
  return currentYear;
};
