import React from "react";
import { Alert, Modal } from "antd";
import { BiCurrentLocation } from "react-icons/bi";
import { BsMailbox2, BsFillTelephoneFill } from "react-icons/bs";
import { FaUserAlt, FaUserTie } from "react-icons/fa";
import { MdOutlineAttachMoney, MdOutlineDateRange, MdTitle } from "react-icons/md";
import { RoomCardDetailDataType } from "../../../../types/RoomDataType";
import { convertDateFormat } from "../../../../utils/DateUtil";
import {
  Content,
  ContentContainer,
  ContentWrapper,
  Text,
  IconContainerTwo,
  Label,
  RoomDetails,
  AlertWrapper,
  TitleWrapper,
} from "../RoomCardElements";

const iconSize = 17;

const RoomCardDetailModal: React.FC<RoomCardDetailDataType> = ({
  data: room,
  open,
  onCancel,
}) => {
  return (
    <>
      <Modal
        title={room?.mrtInMrtLine?.mrtName}
        footer={false}
        open={open}
        width={"85%"}
        style={{ fontSize: "90%" }}
        onCancel={onCancel}
        className="detailModal"
      >
        <TitleWrapper>
            <Text style={{color:'forestgreen'}}>{room?.title || "-"}</Text>
        </TitleWrapper>
        <ContentContainer>
          <ContentWrapper>
            <Content>
              <IconContainerTwo>
                <MdOutlineAttachMoney size={iconSize} />
              </IconContainerTwo>
              <Label>Price:</Label>
              <Text>S$ {room?.price || "-"}</Text>
            </Content>
            <Content>
              <IconContainerTwo>
                <MdOutlineDateRange size={iconSize} />
              </IconContainerTwo>
              <Label>Start Date:</Label>
              <Text>{convertDateFormat(room?.startDate || "") || "-"}</Text>
            </Content>
            <Content>
              <IconContainerTwo>
                <BsMailbox2 size={iconSize} />
              </IconContainerTwo>
              <Label>Postal Code:</Label>
              <Text>{room?.postalCode || "-"}</Text>
            </Content>
            <Content>
              <IconContainerTwo>
                <BsFillTelephoneFill size={iconSize} />
              </IconContainerTwo>
              <Label>Phone No:</Label>
              <Text>{room?.phoneNo || "-"}</Text>
            </Content>
            <Content>
              <IconContainerTwo>
                <FaUserAlt size={iconSize} />
              </IconContainerTwo>
              <Label>Gender:</Label>
              <Text>{room?.gender || "-"}</Text>
            </Content>
            <Content>
              <IconContainerTwo>
                <BiCurrentLocation size={iconSize} />
              </IconContainerTwo>
              <Label>Address:</Label>
              <Text>{room?.address || "-"}</Text>
            </Content>
            <Content>
              <IconContainerTwo>
                <FaUserTie size={iconSize} />
              </IconContainerTwo>
              <Label>Post Owner:</Label>
              <Text>
                {room?.anonymousName
                  ? room?.anonymousName
                  : room?.createdUser
                  ? room?.createdUser
                  : "unknown"}
              </Text>
            </Content>
          </ContentWrapper>
        </ContentContainer>
        {room?.importantNote && (
          <AlertWrapper>
            <Alert
              message="Important Note"
              description={room?.importantNote}
              type="info"
              showIcon
              style={{ width: "100%" }}
            />
          </AlertWrapper>
        )}
        <RoomDetails>{room?.details || "-"}</RoomDetails>
      </Modal>
    </>
  );
};

export default RoomCardDetailModal;
