import {
  AdvertisementDataType,
  AdvertisementPaginationData,
} from "../../types/AdvertisementDataType";
import http from "../axiosService";

export const addAdvertisementApi = async (inputData: AdvertisementDataType) => {
  return await http.post("/advertisement/add", inputData);
};

export const getAdvertisementListApi = async (
  inputData: AdvertisementPaginationData
) => {
  return await http.post("/advertisement/get/list", inputData);
};
