import { Modal } from "antd";
import React from "react";
import { RequestRoomApproveOrRejectModalProps } from "../../../types/RequestRoomDataType";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import {
  ApproveBtn,
  ButtonWrapper,
  DeleteBtn,
  ModalButton,
} from "../RequestRoomElements";

const RequestRoomApproveOrRejectModal: React.FC<
  RequestRoomApproveOrRejectModalProps
> = ({
  requestRoomId,
  showStatusModal,
  onCancel,
  modalError,
  modalSuccess,
  handleChangeStatus,
  statusType,
}) => {
  return (
    <>
      <Modal
        title="Update Request Room Status"
        open={showStatusModal}
        onCancel={onCancel}
        footer={null}
      >
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {modalSuccess && (
          <AlertMessage
            message={modalSuccess}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <h2>
          Are you sure you want to {statusType} request room ID [
          <span style={{ color: "red" }}>{requestRoomId}</span>]?
        </h2>
        <ButtonWrapper>
          <ModalButton onClick={onCancel}>Cancel</ModalButton>
          {statusType === "approve" ? (
            <ApproveBtn onClick={handleChangeStatus}>Confirm</ApproveBtn>
          ) : (
            <DeleteBtn onClick={handleChangeStatus}>Confirm</DeleteBtn>
          )}
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default RequestRoomApproveOrRejectModal;
