import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { NodeProps } from "../../../types/CommonType";

export const SidebarContainer = styled.aside<NodeProps>`
  position: fixed;
  z-index: 999;
  width: 350px;
  height: 100%;
  background: #1e1e1e;
  display: grid;
  align-items: center;
  top: 0;
  opacity: 0.95;
  transition: 0.3s ease-in-out;
  right: ${({ isOpen }) => (isOpen ? "0" : "-1000px")};

  @media screen and (min-width: 769px) {
    right: ${({ isOpen }) => (isOpen ? "-1000px" : "")};
  }

  @media screen and (max-width: 425px) {
    width: 80%;
  }
`;
export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  background: transparent;
  /* transform: translate(-100%, 80px); */
`;
export const CloseIcon = styled(AiOutlineClose)`
  color: #fff;
  cursor: pointer;
  font-size: 150%;
`;

export const SidebarMenuContainer = styled.div`
  display: grid;
  grid-template-rows: 400px;
  grid-template-columns: 1fr;
`;

export const SidebarItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SideBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SideBtn = styled.button`
  color: #fff;
  background: #ff4d4f;
  padding: 0.8rem 2rem;
  border-radius: 10px;
  font-size: 80%;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    background: #fff;
    transition: 0.2s ease-in-out;
    color: red;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

export const Text = styled.p`
  margin-top: 20px;
  margin-left: 15px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const SignInBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid dodgerblue;
  padding: 10px 20px;
  border-radius: 50px;
  color: dodgerblue;
  font-weight: bold;
  cursor: pointer;
`;
