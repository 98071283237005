import { Input, Modal, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import {
  Container,
  Title,
  TableContainer,
  Wrapper,
  Button,
  InputContainer,
  InputLabel,
  Required,
  ButtonWrapper,
  ModalBtn,
  RemoveButton,
  EditButton,
  DeleteBtn,
} from "./MRTLineDetailElements";
import {
  DeleteMRTLineDetailData,
  EditMRTLineDetailData,
  MRTLineDetailData,
  MRTLineList,
} from "../../../types/MRTLineDataType";
import { Input as AntInput } from "antd";
import { BiTrain } from "react-icons/bi";
import { AiOutlineCheckSquare } from "react-icons/ai";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import {
  addMrtInMrtLineApi,
  deleteMrtInMrtLineListApi,
  getMrtInMrtLineListApi,
  updateMrtInMrtLineListApi,
} from "../../../services/RouteServices/MrtLineDetailRouteApi";
import {
  MRTInMrtLineResponseDataType,
  MRTInMrtLineResponseDataTypeList,
  MRTInMrtLineResponseType,
  MRTInMrtLineResponseTypeList,
} from "../../../types/MRTInMrtLineResponseType";
import { PAGE_SIZE, STATUS_CODE } from "../../../constants/GlobalConstant";
import { CalculateOffset } from "../../../utils/CalculateOffsetUtil";
import { getCurrentUserOnly } from "../../../utils/UserUtil";

const MRTLineDetail: React.FC = () => {
  const userName: string = getCurrentUserOnly();
  const location = useLocation();
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const [mrtLineId, setMrtLineId] = useState(Number(searchParam.get("id")));
  const [mrtLineList, setMrtLineList] = useState([]);
  const [data, setData] = useState(location.state);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [mrtName, setMrtName] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [removeMrtId, setRemoveMrtId] = useState<number>(0);
  const [removeMrtName, setRemoveMrtName] = useState<string>("");
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [added, setAdded] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");

  const handleBack = () => {
    navigate("/mrt-lines");
  };

  const columns: ColumnsType<MRTLineList> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "MRT Name",
      dataIndex: "mrtName",
      key: "mrtName",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      render: (remark: string) => <>{remark ? remark : "-"}</>,
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
      render: (desc: string) => <>{desc ? desc : "-"}</>,
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          <RemoveButton
            onClick={() => {
              setShowRemoveModal(true);
              setRemoveMrtId(record.id);
              setRemoveMrtName(record.mrtName);
            }}
          />
          <EditButton
            onClick={() => {
              setShowEditModal(true);
              setMrtName(record.mrtName);
              setRemark(record.remark);
              setId(record.id);
            }}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    getMrtInMrtLineList();
  }, [page, added, deleted, updated, searchText]);

  const getMrtInMrtLineList = async () => {
    const inputData = { offset, limit: pageSize, mrtLineId, searchText };
    await getMrtInMrtLineListApi(inputData)
      .then((response: MRTInMrtLineResponseTypeList) => {
        const responseData: MRTInMrtLineResponseDataTypeList = response?.data;
        setTotal(responseData.total);
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setMrtLineList(responseData.data);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handleAdd = async () => {
    // if (validateInput()) {
    // pass all validation to save
    const newMrtData: MRTLineDetailData = {
      mrtName,
      remark,
      mrtLineId,
      createdUser: userName,
    };
    await addMrtInMrtLineApi(newMrtData)
      .then((response: MRTInMrtLineResponseType) => {
        const responseData: MRTInMrtLineResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowModal(false);
              setAdded(!added);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
    // }
  };
  const handleEdit = async () => {
    // if (validateInput()) {
    const editMrtDetailData: EditMRTLineDetailData = {
      id,
      mrtName,
      remark,
      mrtLineId,
      updatedUser: userName,
    };
    await updateMrtInMrtLineListApi(editMrtDetailData)
      .then((response: MRTInMrtLineResponseType) => {
        const responseData: MRTInMrtLineResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowEditModal(false);
              setUpdated(!updated);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
    // }
  };

  const handleDelete = async () => {
    const deleteData: DeleteMRTLineDetailData = {
      mrtInMrtLineId: removeMrtId,
      deletedUser: userName,
    };
    await deleteMrtInMrtLineListApi(deleteData)
      .then((response: MRTInMrtLineResponseType) => {
        const responseData: MRTInMrtLineResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowRemoveModal(false);
              setDeleted(!deleted);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  // const validateInput = () => {
  //   let isValidSuccess = false;
  //   if (!mrtName) {
  //     setModalError("MRT Name is required!");
  //   } else if (mrtName.length > 50) {
  //     setModalError("MRT Name cannot be exceed 50 characters!");
  //   } else {
  //     isValidSuccess = true;
  //   }
  //   return isValidSuccess;
  // };

  const handleCancel = () => {
    clearInputValues();
    setShowModal(false);
  };

  const handleEditCancel = () => {
    setModalError("");
    setShowEditModal(false);
  };

  const handleRemoveCancel = () => {
    setShowRemoveModal(false);
  };

  const clearInputValues = () => {
    setModalError("");
    setMrtName("");
    setRemark("");
  };

  const handlePageChange = (page: number) => {
    setOffset(CalculateOffset(page, pageSize));
    setPage(page);
  };

  return (
    <>
      <Container>
        <ModalBtn color={"blue"} onClick={handleBack}>
          <AiOutlineArrowLeft />
          Back
        </ModalBtn>
        <Wrapper>
          <Title>MRT Lines Detail- {data?.mrtLineName}</Title>
          {data?.mrtLineName && (
            <ModalBtn
              onClick={() => {
                setShowModal(true);
                clearInputValues();
              }}
            >
              Add New
            </ModalBtn>
          )}
        </Wrapper>
        <Input
          placeholder="Search"
          style={{ width: "25%", marginBottom: "5px" }}
          size="large"
          onChange={(e) => {
            setSearchText(e.target.value);
            setOffset(0);
            setPage(1);
          }}
        />
        <TableContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
          <Table
            rowKey={"id"}
            columns={columns}
            dataSource={mrtLineList}
            scroll={{ x: 100 }}
            pagination={{
              defaultPageSize: pageSize,
              total: total,
              showSizeChanger: false,
              defaultCurrent: page,
              onChange: (page) => handlePageChange(page),
            }}
            footer={() => (
              <p>
                Total <b>{total}</b> records found.
              </p>
            )}
          />
        </TableContainer>
      </Container>

      {/* Add Modal */}
      <Modal
        title="Add New MRT Line"
        open={showModal}
        onCancel={handleCancel}
        footer={null}
      >
        <InputContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
        </InputContainer>
        <InputContainer>
          <InputLabel>
            MRT Name<Required>*</Required>
          </InputLabel>
          <AntInput
            size="large"
            placeholder="MRT Name"
            prefix={<BiTrain />}
            onChange={(e) => setMrtName(e.target.value)}
            value={mrtName}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Remark</InputLabel>
          <AntInput
            size="large"
            placeholder="Remark"
            prefix={<AiOutlineCheckSquare />}
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            maxLength={50}
          />
        </InputContainer>
        <ButtonWrapper>
          <Button onClick={handleCancel}>Cancel</Button>
          <ModalBtn onClick={handleAdd}>Add</ModalBtn>
        </ButtonWrapper>
      </Modal>

      {/* Edit Modal */}
      <Modal
        title="Edit MRT Line"
        open={showEditModal}
        onCancel={handleEditCancel}
        footer={null}
      >
        <InputContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
        </InputContainer>
        <InputContainer>
          <InputLabel>
            MRT Name<Required>*</Required>
          </InputLabel>
          <AntInput
            size="large"
            placeholder="MRT Line Name"
            prefix={<BiTrain />}
            value={mrtName}
            onChange={(e) => setMrtName(e.target.value)}
            maxLength={50}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Remark</InputLabel>
          <AntInput
            size="large"
            placeholder="Remark"
            prefix={<AiOutlineCheckSquare />}
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            maxLength={50}
          />
        </InputContainer>
        <ButtonWrapper>
          <Button onClick={handleEditCancel}>Cancel</Button>
          <ModalBtn onClick={handleEdit} color="blue">
            Update
          </ModalBtn>
        </ButtonWrapper>
      </Modal>

      {/* Delete Modal */}
      <Modal
        title="Delete MRT Line"
        open={showRemoveModal}
        onCancel={handleRemoveCancel}
        footer={null}
      >
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {modalSuccess && (
          <AlertMessage
            message={modalSuccess}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <h2>
          Are you sure you want to delete [
          <span style={{ color: "red" }}>{removeMrtName}</span>]?
        </h2>
        <ButtonWrapper>
          <Button onClick={handleRemoveCancel}>Cancel</Button>
          <DeleteBtn onClick={handleDelete}>Delete</DeleteBtn>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default MRTLineDetail;
