import { Navigate, Outlet } from "react-router-dom";
import { ROOT_USER_ID } from "../constants/GlobalConstant";
import { UserInfo, UserPermission } from "../types/UserAuth";
import { getCurrentUser, getUserPermission } from "../utils/UserUtil";

export const approveRoomCheckRoute = () => {
  const permission: UserPermission = getUserPermission();

  if (permission?.isApproveRoom === true) {
    <Outlet />;
  } else {
    return <Navigate to={"/"} replace />;
  }
  return <Outlet />;
};

export const approveRequestRoomCheckRoute = () => {
  const permission: UserPermission = getUserPermission();

  if (permission?.isApproveRequestRoom === true) {
    <Outlet />;
  } else {
    return <Navigate to={"/"} replace />;
  }
  return <Outlet />;
};

export const CheckRootUserRoute = () => {
  const userInfo: UserInfo = getCurrentUser();

  if (userInfo?.roleId === ROOT_USER_ID) {
    <Outlet />;
  } else {
    return <Navigate to={"/"} replace />;
  }
  return <Outlet />;
};
