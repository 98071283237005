import React from "react";
import { Modal } from "antd";
import { RequestRoomDeleteModalProps } from "../../../types/RequestRoomDataType";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import { ButtonWrapper, DeleteBtn, ModalButton } from "../RequestRoomElements";

const RequestRoomDeleteModal: React.FC<RequestRoomDeleteModalProps> = ({
  onCancel,
  error,
  success,
  removeRequestRoomId,
  handleDelete,
  showRemoveModal,
}) => {
  return (
    <>
      <Modal
        title="Delete Request Room"
        open={showRemoveModal}
        onCancel={onCancel}
        footer={null}
      >
        {error && (
          <AlertMessage
            message={error}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {success && (
          <AlertMessage
            message={success}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <h2>
          Are you sure you want to delete request room ID [
          <span style={{ color: "red" }}>{removeRequestRoomId}</span>]?
        </h2>
        <ButtonWrapper>
          <ModalButton onClick={onCancel}>Cancel</ModalButton>
          <DeleteBtn onClick={handleDelete}>Delete</DeleteBtn>
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default RequestRoomDeleteModal;
