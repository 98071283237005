import {
  DeleteRoleData,
  EditRoleData,
  RoleData,
  RolePaginationData,
} from "../../types/RoleDataType";
import http from "../axiosService";

export const addRoleApi = async (inputData: RoleData) => {
  return await http.post("/role/add", inputData);
};

export const getRoleListApi = async (inputData: RolePaginationData) => {
  return await http.post("/role/get/list", inputData);
};

export const updateRoleApi = async (editData: EditRoleData) => {
  return await http.post("/role/update", editData);
};

export const deleteRoleApi = async (deleteData: DeleteRoleData) => {
  return await http.post("/role/delete", deleteData);
};
