import styled from "styled-components";
import { NodeProps } from "../../../types/CommonType";

export const SidebarContainer = styled.aside<NodeProps>`
  position: fixed;
  z-index: 9;
  width: 250px;
  height: 100%;
  background: #d9d9d9;
  margin-top: 70px;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  transition: 0.3s ease-in-out;
  left: ${({ isOpen }) => (isOpen ? "0" : "-1000px")};
  max-height: calc(100vh - 70px);

  @media screen and (min-width: 768px) {
    left: ${({ isOpen }) => (isOpen ? "-1000px" : "0")};
  }
`;

export const SideContentContainer = styled.div`
  height: 100%;
  padding: 15px;
`;

export const SidebarMenu = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;

  // display: grid;
  // grid-template-rows: 100px;
  // grid-template-columns: 1fr;
  // margin-top: 110px;
  // padding-top: 20px;
`;

export const SidebarItem = styled.div`
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 6px;
`;

export const MenuHeader = styled.span`
  color: #7f7979;
  font-weight: 500;
  font-size: 75%;
`;
