import styled from "styled-components";
import { NodeProps } from "../../../types/CommonType";
import { Link } from "react-router-dom";
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from "react-icons/ai";

export const Nav = styled.div<NodeProps>`
  background-color: ${(props) => (props.transparent ? "#313131" : "#1e4872")};
  color: #fff;
  // height: 70px;
  // margin-top: -70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding: 0 40px;
  // max-width: 1100px;
  height: 70px;
`;

export const Logo = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  overflow: hidden;
  // margin-left: 10px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LogoLink = styled(Link)``;

export const Title = styled.h3`
  color: #8fd071;
  margin-left: 8px;
  margin-bottom: 0px;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const AvatarMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 5px;
`;

export const NavItemContainer = styled.ul`
  color: #fff;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ProfileContainer = styled.ul`
  color: #fff;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
`;

export const NavItem = styled(Link)`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;
  &:hover {
    color: #98fb98;
  }
`;

export const NavItemList = styled.li`
  // margin-right: 15px;
  // padding-right: 10px;
  cursor: pointer;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid dodgerblue;
  padding: 8px 15px;
  border-radius: 50px;
  margin-left: 20px;
  color: dodgerblue;
  font-weight: bold;
`;

export const Text = styled.small`
  padding-left: 8px;
`;

export const MobileLogoWrapper = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
    color: #fff;
  }
`;

export const OpenIcon = styled(AiOutlineMenuUnfold)`
  font-size: 150%;
  cursor: pointer;
  margin-left: 5px;
  &:hover {
    color: #98fb98;
  }
`;

export const CloseIcon = styled(AiOutlineMenuFold)`
  font-size: 150%;
  cursor: pointer;
  margin-left: 5px;
  &:hover {
    color: #98fb98;
  }
`;
