import React, { useEffect, useState } from "react";
import { Modal, Switch, Upload, UploadFile, UploadProps } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { RcFile } from "antd/lib/upload";
import { PlusOutlined } from "@ant-design/icons";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  ALLOW_IMG_EXTENSION_LIST,
  ANONYMOUS_USER,
  ROOM_PAGE_SIZE,
  STATUS_CODE,
  TOTAL_REQUEST_ROOM_LIMIT,
  UPLOADABLE_REQUEST_ROOM_IMG_LIMIT,
} from "../../../constants/GlobalConstant";
import {
  addRequestRoomApi,
  getRequestRoomListByUserIdApi,
} from "../../../services/RouteServices/RequestRoomRouteApi";
import {
  RoomRequestResponseDataType,
  RoomRequestResponseDataTypeList,
  RoomRequestResponseType,
  RoomRequestResponseTypeList,
} from "../../../types/RequestRoomDataType";
import { UserInfo } from "../../../types/UserAuth";
import { getCurrentUser } from "../../../utils/UserUtil";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import {
  InputContainer,
  InputLabel,
  Required,
  Wrapper,
  Button,
  CancelButton,
  Title,
  ButtonWrapper,
  Container,
  CardWrapper,
  AlertWrapper,
  AlertContainer,
  AlertPage,
  AlertButton,
} from "./PostRequestRoomElements";
import { Input as AntInput } from "antd";
import Card from "../../Shared/Card/Card";
import { IoWarningOutline } from "react-icons/io5";
import { BsArrowRightShort } from "react-icons/bs";

const PostRequestRoom: React.FC = () => {
  const navigate = useNavigate();
  const userInfo: UserInfo = getCurrentUser();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [details, setDetails] = useState<string>("");
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [anonymousUser, setAnonymousUser] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(ROOM_PAGE_SIZE);
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    getRequestRoomListByUserId();
  }, []);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      }
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleImgChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    let isValid = true;
    newFileList?.forEach((file) => {
      const fileName = file?.name?.toLowerCase();
      const fileExtension = fileName.substring(fileName.lastIndexOf("."));
      if (!ALLOW_IMG_EXTENSION_LIST.includes(fileExtension)) {
        isValid = false;
      }
    });

    if (isValid) {
      setModalError("");
      setFileList(newFileList);
    } else {
      setModalError(
        "Invalid file type. Please upload only .gif, .png, .jpeg, or .jpg files."
      );
    }
  };

  const getRequestRoomListByUserId = async () => {
    const inputData = {
      offset,
      limit: pageSize,
      userId: userInfo.id,
    };
    await getRequestRoomListByUserIdApi(inputData)
      .then((response: RoomRequestResponseTypeList) => {
        const responseData: RoomRequestResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 100);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handleAddRequestRoom = async () => {
    let imgUrl;
    if (fileList.length !== 0) {
      imgUrl = await getBase64(fileList[0]?.originFileObj as RcFile);
    }
    const addRequestRoomData = {
      remark,
      details,
      imgUrl: imgUrl,
      createdUserId: userInfo.id,
      createdUser: userInfo.userName,
      anonymousName: anonymousUser ? ANONYMOUS_USER : "",
    };
    await addRequestRoomApi(addRequestRoomData)
      .then((response: RoomRequestResponseType) => {
        const responseData: RoomRequestResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setRemark("");
              setDetails("");
              setFileList([]);
              setModalSuccess("");
              navigate("/requestRoom");
            }, 500);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handleChangeAnonymousUser = (checked: boolean) => {
    setAnonymousUser(checked);
  };

  if (total >= TOTAL_REQUEST_ROOM_LIMIT) {
    return (
      <AlertWrapper>
        <AlertContainer>
          <AlertPage>
            <div
              style={{
                display: "flex",
              }}
            >
              <IoWarningOutline
                color="#ffc53d"
                size={30}
                style={{ marginRight: "5px" }}
              />
              <p style={{ color: "#000", opacity: "0.85" }}>
                You can't post a request room because you exceed allowed request
                room limit!
              </p>
            </div>
            <AlertButton onClick={() => navigate("/my-request-rooms")}>
              your request rooms
              <BsArrowRightShort size={30} />
            </AlertButton>
          </AlertPage>
        </AlertContainer>
      </AlertWrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <Title>Post Request Room</Title>
        <Container>
          <CardWrapper>
            <Card>
              <InputContainer>
                {modalError && (
                  <AlertMessage
                    message={modalError}
                    visible={true}
                    typeAlert={"error"}
                    width={"100%"}
                  />
                )}
                {modalSuccess && (
                  <AlertMessage
                    message={modalSuccess}
                    visible={true}
                    typeAlert={"success"}
                    width={"100%"}
                  />
                )}
              </InputContainer>
              <InputContainer>
                <InputLabel>Images</InputLabel>
                <Upload
                  accept={ALLOW_IMG_EXTENSION_LIST}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleImgChange}
                  beforeUpload={() => false}
                  maxCount={UPLOADABLE_REQUEST_ROOM_IMG_LIMIT}
                >
                  {fileList?.length >= UPLOADABLE_REQUEST_ROOM_IMG_LIMIT
                    ? null
                    : uploadButton}
                </Upload>

                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={() => setPreviewOpen(false)}
                  style={{ width: "50%" }}
                >
                  <img
                    alt="uploadImage"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </InputContainer>
              <InputContainer>
                <InputLabel>
                  Details<Required>*</Required>
                </InputLabel>
                <TextArea
                  rows={4}
                  placeholder="eg.. Bedok မှာ couple နေဖို့ အခန်းလေး ရှိရင် ပြောပါအုံး 
                            $ 680 လောက်ဆို အဆင်ပြေ ပါတယ်"
                  maxLength={200}
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>Remark</InputLabel>
                <AntInput
                  size="large"
                  placeholder="Remark"
                  prefix={<AiOutlineCheckSquare />}
                  onChange={(e) => setRemark(e.target.value)}
                  value={remark}
                  maxLength={50}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>Post as Anonymous</InputLabel>
                <div>
                  <Switch
                    defaultChecked={anonymousUser}
                    onChange={handleChangeAnonymousUser}
                  />
                </div>
              </InputContainer>
              <ButtonWrapper>
                <CancelButton
                  onClick={() => {
                    setRemark("");
                    setDetails("");
                    setFileList([]);
                    navigate("/requestRoom");
                  }}
                >
                  Cancel
                </CancelButton>
                <Button onClick={handleAddRequestRoom}>Submit</Button>
              </ButtonWrapper>
            </Card>
          </CardWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

export default PostRequestRoom;
