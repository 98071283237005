import styled from "styled-components";

export const CardContainer = styled.div`
  width: 50%;
  margin: 5px;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  outline: none;
  border: none;
  background-color: dodgerblue;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;
  font-size: 70%;

  &:hover {
    background-color: #007aec;
    color: #fff;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 0px 20px;
  display: flex;
  justify-content: flex-end;
`;

export const CardWrapper = styled.div`
  display: flex;
`;
