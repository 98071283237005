import {
  AddEventLogData,
  EventLogPaginationData,
} from "../../types/EventDataType";
import http from "../axiosService";

export const getEventLogListApi = async (inputData: EventLogPaginationData) => {
  return await http.post("/eventLog/get/list", inputData);
};

export const addEventLogApi = async (inputData: AddEventLogData) => {
  return await http.post("/eventLog/add", inputData);
};
