import { Modal } from "antd";
import React from "react";
import { RoomApproveOrRejectProps } from "../../../../types/RoomDataType";
import AlertMessage from "../../AlertMessage/AlertMessage";
import {
  ApproveBtn,
  ButtonWrapper,
  DeleteBtn,
  ModalButton,
  ReserveBtn,
} from "../RoomCardElements";

const RoomApproveOrRejectModal: React.FC<RoomApproveOrRejectProps> = ({
  showStatusModal,
  modalError,
  modalSuccess,
  type,
  onCancel,
  handleChangeStatus,
  roomId,
}) => {
  return (
    <>
      <Modal
        title="Update Room Status"
        open={showStatusModal}
        onCancel={onCancel}
        footer={null}
      >
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {modalSuccess && (
          <AlertMessage
            message={modalSuccess}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <h2>
          Are you sure you want to {type} room ID [
          <span style={{ color: "red" }}>{roomId}</span>]?
        </h2>
        <ButtonWrapper>
          <ModalButton onClick={onCancel}>Cancel</ModalButton>
            {type === "approve" ? (
              <ApproveBtn onClick={handleChangeStatus}>Confirm</ApproveBtn>
            ) : type === "reserve" ? (
              <ReserveBtn onClick={handleChangeStatus}>Confirm</ReserveBtn>
              ) : (
              <DeleteBtn onClick={handleChangeStatus}>Confirm</DeleteBtn>
            )}
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default RoomApproveOrRejectModal;
