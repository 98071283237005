import { Modal } from "antd";
import React from "react";
import { STATUS } from "../../../constants/GlobalConstant";
import { SettingModalDataType } from "../../../types/SettingModalDataType";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import { Button, ButtonWrapper, DeleteBtn, ModalBtn } from "../SettingElements";

const SettingModal: React.FC<SettingModalDataType> = ({
  showModal,
  modalError,
  modalSuccess,
  status,
  onCancel,
  handleStatusUpdate,
}) => {
  return (
    <>
      <Modal title="Confirm" open={showModal} onCancel={onCancel} footer={null}>
        {modalError && (
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"100%"}
          />
        )}
        {modalSuccess && (
          <AlertMessage
            message={modalSuccess}
            visible={true}
            typeAlert={"success"}
            width={"100%"}
          />
        )}
        <h2>Are you sure you want to {status} your account?</h2>
        <ButtonWrapper>
          <Button onClick={onCancel}>Cancel</Button>
          {status === STATUS.deactivateStatus ? (
            <ModalBtn onClick={handleStatusUpdate}>Confirm</ModalBtn>
          ) : (
            <DeleteBtn onClick={handleStatusUpdate}>Confirm</DeleteBtn>
          )}
        </ButtonWrapper>
      </Modal>
    </>
  );
};

export default SettingModal;
