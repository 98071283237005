import React from "react";
import { NavLink } from "react-router-dom";

const SidebarMenu = (props: any) => {
  return (
    <>
      <NavLink
        onClick={props.handleClick}
        end
        to={props.to}
        style={({ isActive }) => ({
          textDecoration: "none",
          color: "#fff",
          width: "45%",
          height: "10%",
          fontWeight: "bold",
          marginBottom: "2.5rem",
          transition: "0.2s ease-in-out",
          cursor: "pointer",
          textAlign: "center",
          borderBottom: isActive ? "4px solid #8fd071" : "",
        })}
      >
        {props.name}
      </NavLink>
    </>
  );
};

export default SidebarMenu;
