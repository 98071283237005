import styled from "styled-components";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";

export const Container = styled.div`
  background-color: #1e1e1e;
  color: #fff;
  padding: 30px;
  height: calc(100vh-70px);
`;

export const Title = styled.h2`
  color: #fff;
`;

export const TableContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  // margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  background: #636464;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  color: #fff;

  &:hover {
    // background: #007aec;
    color: #fff;
  }
`;

export const InputContainer = styled.div`
  width: 95%;
  margin: 10px;
`;

export const InputLabel = styled.small`
  font-weight: 500;
`;

export const Required = styled.b`
  color: red;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
`;
export const ModalBtn = styled.button`
  outline: none;
  border: none;
  background-color: #389e0d;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #237804;
    color: #fff;
  }
`;

export const DetailButton = styled.button`
  outline: none;
  border: 1px solid dodgerblue;
  background-color: #fff;
  color: dodgerblue;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #389e0d;
    border: 1px solid #389e0d;
    color: #fff;
  }
`;

export const TableBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditButton = styled(AiOutlineEdit)`
  color: dodgerblue;
  cursor: pointer;
  font-size: 190%;
  margin-right: 10px;

  &:hover {
    color: #007aec;
  }
`;

export const RemoveButton = styled(RiDeleteBin6Line)`
  color: red;
  cursor: pointer;
  font-size: 150%;
  margin-right: 20px;

  &:hover {
    color: #da2a3f;
  }
`;

export const DeleteBtn = styled.button`
  outline: none;
  border: none;
  background-color: red;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #da2a3f;
    color: #fff;
  }
`;

export const EditModalBtn = styled.button`
  outline: none;
  border: none;
  background-color: dodgerblue;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #007aec;
    color: #fff;
  }
`;

export const InputBox = styled.div`
  border: 1px solid grey;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

export const CardWrapper = styled.div`
  width: 60vh;
`;

export const CancelButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #848884;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 5px;
  font-weight: 500;
  font-size: 80%;

  &:hover {
    background-color: #71797e;
    color: #fff;
  }
`;

export const AlertWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
`;
export const AlertContainer = styled.div`
  width: 70%;
  height: 40%;
  @media screen and (max-width: 550px) {
    width: 90%;
  }
`;
export const AlertPage = styled.div`
  background-color: #fffbe6;
  padding: 30px 40px;
  border-radius: 10px;
`;

export const AlertButton = styled.div`
  font-size: 80%;
  color: #fff;
  cursor: pointer;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  background-color: #1890ff;

  &:hover {
    background-color: #2f54eb;
  }

  @media screen and (max-width: 700px) {
    width: 70%;
  }
`;
