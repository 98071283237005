import styled from "styled-components";
import { FaFacebook } from "react-icons/fa";

export const Container = styled.div`
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  padding: 0px 40px;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }

  @media screen and (max-width: 375px) {
    padding: 0px 0px;
  }
`;

export const Content = styled.div`
  text-align: center;
  padding: 20px;
`;

export const TempContent = styled.span`
  color: #9d8529;
`;

export const FooterLink = styled.a``;

export const Icon = styled(FaFacebook)`
  font-size: 30px;
  color: #3b5998;
  cursor: pointer;
  &:hover {
    color: #4267b2;
  }
`;
