import { Alert } from "antd";
import React from "react";
import { BsMailbox2 } from "react-icons/bs";
import { FaUserTie } from "react-icons/fa";
import { MdOutlineDateRange } from "react-icons/md";
import { RoomCardContentProps } from "../../../../types/RoomDataType";
import {
  convertDateFormat,
  manageStartDateFormat,
} from "../../../../utils/DateUtil";
import { PostOwnerWrapper } from "../RoomCardElements";

const iconSize = 17;
const RoomCardContent: React.FC<RoomCardContentProps> = ({
  room: item,
  roomType,
}) => {
  return (
    <>
      {item?.title && (
        <p
          style={{
            fontSize: "85%",
            display: "flex",
            alignItems: "center",
            color: "forestgreen",
            overflow: "hidden",
          }}
        >
          {item?.title || "-"}
        </p>
      )}
      <p
        style={{
          fontSize: "85%",
          display: "flex",
          alignItems: "center",
          marginBottom: "3px",
        }}
      >
        <MdOutlineDateRange
          size={iconSize}
          style={{
            marginRight: "5px",
            color: "green",
          }}
        />
        Start Date: {manageStartDateFormat(item?.startDate) || "-"}
      </p>
      {item?.postalCode && (
        <p
          style={{
            fontSize: "85%",
            display: "flex",
            alignItems: "center",
            marginBottom: "3px",
          }}
        >
          <BsMailbox2
            size={iconSize}
            style={{
              marginRight: "5px",
              color: "#cd50a7",
            }}
          />
          Postal Code: {item?.postalCode || "-"}
        </p>
      )}
      {roomType === "rooms" && item?.anonymousName && (
        <PostOwnerWrapper>
          <FaUserTie
            size={iconSize}
            style={{
              marginRight: "5px",
              color: "#a59c2f",
            }}
          />
          Post Owner:{" "}
          <i
            style={{
              color: "#b37feb",
              fontWeight: 600,
              marginLeft: "0.3em",
            }}
          >
            {item?.anonymousName}
          </i>
        </PostOwnerWrapper>
      )}

      {roomType === "rooms" && !item?.anonymousName && item?.createdUser && (
        <PostOwnerWrapper>
          <FaUserTie
            size={iconSize}
            style={{
              marginRight: "5px",
              color: "#a59c2f",
            }}
          />
          Post Owner: {item?.createdUser}
        </PostOwnerWrapper>
      )}
      {roomType !== "rooms" && (item?.anonymousName || item?.createdUser) && (
        <PostOwnerWrapper>
          <FaUserTie
            size={iconSize}
            style={{
              marginRight: "5px",
              color: "#a59c2f",
            }}
          />
          Post Owner:{" "}
          {item?.anonymousName && item?.createdUser ? (
            <>
              {item?.createdUser}
              <i
                style={{
                  color: "#b37feb",
                  fontWeight: 600,
                  marginLeft: "0.2em",
                }}
              >
                ({item?.anonymousName})
              </i>
            </>
          ) : item?.createdUser && !item.anonymousName ? (
            item?.createdUser
          ) : (
            "unknown"
          )}
        </PostOwnerWrapper>
      )}
      <p
        style={{
          fontSize: "85%",
          display: "flex",
          alignItems: "center",
          marginBottom: "3px",
        }}
      >
        <MdOutlineDateRange
          size={iconSize}
          style={{
            marginRight: "5px",
            color: "#faad14",
          }}
        />
        Published Date: {convertDateFormat(item?.createdAt || "") || "-"}
      </p>
      {item?.importantNote && (
        <div style={{ margin: "20px 0px" }}>
          <Alert
            message="Important Note"
            description={item?.importantNote}
            type="info"
            showIcon
          />
        </div>
      )}
      <p
        style={{
          marginTop: "10px",
          padding: "10px",
          textAlign: "justify",
        }}
      >
        {item?.details?.slice(0, 150) +
          (item?.details?.length > 150 ? "............" : "")}
      </p>
    </>
  );
};

export default RoomCardContent;
