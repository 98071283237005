import axios from "axios";
import { BASE_URL } from "../constants/ApiConstant";
import { STATUS_CODE } from "../constants/GlobalConstant";
import { getCurrentUser } from "../utils/UserUtil";

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.defaults.headers.common["SecretToken"] =
  "Bear " + process.env.REACT_APP_API_KEY;

const refreshToken = getCurrentUser()?.refreshToken || "";
axios.defaults.headers.common["refreshToken"] = refreshToken;

// Encode the string using Base64
const encodedString = btoa(refreshToken);
axios.defaults.headers.common["OwnRefreshToken"] = encodedString;

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error?.response?.status === STATUS_CODE.unauthorizedCode) {
      localStorage.removeItem("userAuth");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
