import styled from "styled-components";

export const Container = styled.div`
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StepWrapper = styled.div`
  width: 30%;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 50%;
  }
`;
