import React from "react";
import UserReportBarChart from "./UserReportBarChart";
import { Container } from "./ReportElements";
import RoomReportBarChart from "./RoomReportBarChart";
import { getCurrentYear } from "../../utils/DateUtil";
import moment from "moment";
import { MonthOption, YearOption } from "../../types/ReportPropsDataType";

const Report: React.FC = () => {
  const labels = moment.months();

  let yearOption: YearOption[] = [];
  for (let i = 0; i <= 10; i++) {
    const year = getCurrentYear() - i;
    const yearString = moment().year(year).format("YYYY");
    yearOption.push({ value: yearString, label: yearString });
  }

  let monthOption: MonthOption[] = [];
  for (let month = 0; month < 12; month++) {
    const monthString = moment().month(month).format("MMMM");
    monthOption.push({ value: monthString, label: monthString });
  }

  const getLabelForMonth = (month: string, year: string) => {
    const labelForMonth: string[] = [];
    const monthIndex = moment().month(month).month();
    const yearIndex = Number(year);
    const startDate = moment([yearIndex, monthIndex, 1]);
    const numberOfDays = startDate.daysInMonth();
    for (let day = 1; day <= numberOfDays; day++) {
      const currentDate = moment([yearIndex, monthIndex, day]);
      const formattedDate = currentDate.format("YYYY-MM-DD");
      labelForMonth.push(formattedDate);
    }
    return labelForMonth;
  };

  return (
    <>
      <h2 style={{ color: "#fff", padding: "30px", paddingBottom: "0px" }}>
        Report
      </h2>
      <Container>
        <UserReportBarChart
          labels={labels}
          getLabelForMonth={getLabelForMonth}
          monthOption={monthOption}
          yearOption={yearOption}
        />
      </Container>
      <Container>
        <RoomReportBarChart
          labels={labels}
          getLabelForMonth={getLabelForMonth}
          monthOption={monthOption}
          yearOption={yearOption}
        />
      </Container>
    </>
  );
};

export default Report;
