import styled from "styled-components";

export const Container = styled.div`
  margin: 15px;

  @media screen and (max-width: 600px) {
    margin: 12px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 15px;

  @media screen and (max-width: 470px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5px;
  }
`;

export const Title = styled.h2`
  color: #fff;
`;

export const PostButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background-color: dodgerblue;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 5px;

  &:hover {
    background-color: #3d6009;
    color: #fff;
  }

  @media screen and (max-width: 425px) {
    padding: 10px 25px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  // align-items: center;
  margin-left: 20px;
  flex-wrap: wrap;
`;

export const SelectWrapper = styled.div`
  width: 28%;
  margin-right: 5px;

  @media screen and (max-width: 500px) {
    width: 200px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 341px) {
    width: 200px;
    margin-bottom: 10px;
  }
`;
export const AutoCompleteWrapper = styled.div`
  width: 20%;
  margin-right: 3px;

  @media screen and (max-width: 500px) {
    width: 200px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 341px) {
    width: 200px;
    margin-bottom: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const Button = styled.div`
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #389e0d;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;

  &:hover {
    background-color: #2f54eb;
    color: #fff;
  }

  @media screen and (max-width: 425px) {
    padding: 10px 25px;
  }
`;
export const ModalBtn = styled.button`
  outline: none;
  border: none;
  background-color: #389e0d;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #237804;
    color: #fff;
  }
`;

export const ModalBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
`;
