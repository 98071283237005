import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { PAGE_SIZE, STATUS_CODE } from "../../constants/GlobalConstant";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import {
  Container,
  EditButton,
  ModalBtn,
  RemoveButton,
  TableBtnWrapper,
  TableContainer,
  Title,
  Wrapper,
} from "./RoleElements";
import {
  DeleteRoleData,
  EditRoleData,
  RoleData,
  RoleList,
} from "../../types/RoleDataType";
import {
  addRoleApi,
  deleteRoleApi,
  getRoleListApi,
  updateRoleApi,
} from "../../services/RouteServices/RoleRouteApi";
import {
  RoleResponseDataType,
  RoleResponseDataTypeList,
  RoleResponseType,
  RoleResponseTypeList,
} from "../../types/RoleResponseType";
import { convertToLocaleDate } from "../../utils/DateUtil";
import { getCurrentUserOnly } from "../../utils/UserUtil";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import RoleDeleteModal from "./RoleModal/RoleDeleteModal";
import RoleEditModal from "./RoleModal/RoleEditModal";
import RoleAddModal from "./RoleModal/RoleAddModal";
import { CalculateOffset } from "../../utils/CalculateOffsetUtil";

const Role: React.FC = () => {
  const userName: string = getCurrentUserOnly();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [roleList, setRoleList] = useState<RoleList[]>([]);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [removeRoleName, setRemoveRoleName] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [remark, setRemark] = useState<string>("");
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [isApproveRoom, setIsApproveRoom] = useState<boolean>(false);
  const [manageContent, setManageContent] = useState<boolean>(false);
  const [isApproveRequestRoom, setIsApproveRequestRoom] =
    useState<boolean>(false);

  const columns: ColumnsType<RoleList> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      render: (remark: string) => <>{remark ? remark : "-"}</>,
    },
    {
      title: "Created User",
      dataIndex: "createdUser",
      key: "createdUser",
      render: (user: string) => <>{user ? user : "-"}</>,
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: Date) => (
        <>{date ? <>{convertToLocaleDate(date)}</> : "-"}</>
      ),
    },
    {
      title: "Updated User",
      dataIndex: "updatedUser",
      key: "updatedUser",
      render: (user: string) => <>{user ? user : "-"}</>,
    },
    {
      title: "Updated Date",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: Date) => (
        <>{date ? <>{convertToLocaleDate(date)}</> : "-"}</>
      ),
    },
    {
      title: "Deleted User",
      dataIndex: "deletedUser",
      key: "deletedUser",
      render: (user: string) => <>{user ? user : "-"}</>,
    },
    {
      title: "Deleted Date",
      dataIndex: "deletedAt",
      key: "deletedAt",
      render: (date: Date) => (
        <>{date ? <>{convertToLocaleDate(date)}</> : "-"}</>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          <TableBtnWrapper>
            <EditButton
              onClick={() => {
                setShowEditModal(true);
                setModalSuccess("");
                setId(record.id);
                setName(record.name);
                setRemark(record.remark);
                setIsApproveRoom(record?.isApproveRoom);
              }}
            />
            <RemoveButton
              onClick={() => {
                setShowRemoveModal(true);
                setId(record.id);
                setRemoveRoleName(record.name);
              }}
            />
          </TableBtnWrapper>
        </>
      ),
    },
  ];

  useEffect(() => {
    getRoleList();
  }, [page, added, updated, deleted]);

  const getRoleList = async () => {
    const inputData = { offset, limit: pageSize };
    await getRoleListApi(inputData)
      .then((response: RoleResponseTypeList) => {
        const responseData: RoleResponseDataTypeList = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setRoleList(responseData.data);
            setTotal(responseData.total);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const handleAdd = async () => {
    // if (validateInput()) {
    const newRoleData: RoleData = {
      name,
      remark,
      createdUser: userName,
    };
    await addRoleApi(newRoleData)
      .then((response: RoleResponseType) => {
        const responseData: RoleResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowModal(false);
              setAdded(!added);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
    // }
  };

  const handleEdit = async () => {
    // if (validateInput()) {
    const editRoleData: EditRoleData = {
      id,
      name,
      remark,
      updatedUser: userName,
      isApproveRoom,
      isApproveRequestRoom,
      manageContent,
    };
    await updateRoleApi(editRoleData)
      .then((response: RoleResponseType) => {
        const responseData: RoleResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowEditModal(false);
              setUpdated(!updated);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
    // }
  };

  const handleDelete = async () => {
    const deleteData: DeleteRoleData = {
      roleId: id,
      deletedUser: userName,
    };
    await deleteRoleApi(deleteData)
      .then((response: RoleResponseType) => {
        const responseData: RoleResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowRemoveModal(false);
              setDeleted(!deleted);
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  // const validateInput = () => {
  //   let isValidSuccess = false;
  //   setModalSuccess("");
  //   if (!name) {
  //     setModalError("Role name is required!");
  //   } else if (name.length > 25) {
  //     setModalError("Role name cannot be exceed 25 characters!");
  //   } else {
  //     isValidSuccess = true;
  //   }
  //   return isValidSuccess;
  // };
  const clearInputValues = () => {
    setModalSuccess("");
    setModalError("");
    setName("");
    setRemark("");
  };

  const onChangeCheck = (e: CheckboxChangeEvent) => {
    setIsApproveRoom(e.target.checked);
  };

  const onChangeApproveRequestCheck = (e: CheckboxChangeEvent) => {
    setIsApproveRequestRoom(e.target.checked);
  };

  const onChangeManageContent = (e: CheckboxChangeEvent) => {
    setManageContent(e.target.checked);
  };

  const handleCancel = () => {
    clearInputValues();
    setShowModal(false);
  };
  const handlePageChange = (page: number) => {
    setOffset(CalculateOffset(page,pageSize));
    setPage(page);
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Title>Role List</Title>
          <ModalBtn
            onClick={() => {
              setShowModal(true);
              clearInputValues();
            }}
          >
            Add New
          </ModalBtn>
        </Wrapper>
        <TableContainer>
          {modalError && (
            <AlertMessage
              message={modalError}
              visible={true}
              typeAlert={"error"}
              width={"100%"}
            />
          )}
          {modalSuccess && (
            <AlertMessage
              message={modalSuccess}
              visible={true}
              typeAlert={"success"}
              width={"100%"}
            />
          )}
          <Table
            rowKey={"id"}
            columns={columns}
            dataSource={roleList}
            scroll={{ x: 100 }}
            pagination={{
              defaultPageSize: pageSize,
              showSizeChanger: false,
              total: total,
              defaultCurrent: page,
              current: page,
              onChange: (page) => handlePageChange(page),
            }}
            footer={() => (
              <p>
                Total <b>{total}</b> records found.
              </p>
            )}
          />
        </TableContainer>
      </Container>

      {/* Add Modal */}
      <RoleAddModal
        showModal={showModal}
        modalError={modalError}
        modalSuccess={modalSuccess}
        name={name}
        remark={remark}
        handleChangeName={(e) => setName(e.target.value)}
        handleChangeRemark={(e) => setRemark(e.target.value)}
        handleAdd={handleAdd}
        handleCancel={handleCancel}
      />

      {/* Edit Modal */}
      <RoleEditModal
        showEditModal={showEditModal}
        modalError={modalError}
        modalSuccess={modalSuccess}
        onCancel={() => {
          setShowEditModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        id={id}
        name={name}
        remark={remark}
        isApproveRequestRoom={isApproveRequestRoom}
        isApproveRoom={isApproveRoom}
        handleChangeName={(e) => setName(e.target.value)}
        handleRemark={(e) => setRemark(e.target.value)}
        handleEdit={handleEdit}
        onChangeApproveRequestCheck={onChangeApproveRequestCheck}
        onChangeCheck={onChangeCheck}
        onChangeManageContent={onChangeManageContent}
        manageContent={manageContent}
      />

      {/* Delete Modal */}
      <RoleDeleteModal
        showRemoveModal={showRemoveModal}
        modalError={modalError}
        modalSuccess={modalSuccess}
        onCancel={() => {
          setShowRemoveModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        removeRoleName={removeRoleName}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Role;
