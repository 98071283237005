import React from "react";
import { MdOutlineAttachMoney } from "react-icons/md";
import { APPROVE_STATUS, PENDING_STATUS, REJECT_STATUS, RESERVE_STATUS } from "../../../../constants/GlobalConstant";
import { RoomCardImagesProps } from "../../../../types/RoomDataType";
import { UserInfo } from "../../../../types/UserAuth";
import { getCurrentUser } from "../../../../utils/UserUtil";
import ImageCarousel from "../../ImageCarousel/ImageCarousel";
import {
  Badge,
  EmptyContentStyle,
  IconWrapper,
  Price,
} from "../RoomCardElements";

const RoomCardImages: React.FC<RoomCardImagesProps> = ({
  room: item,
  roomType,
}) => {
  const userInfo: UserInfo = getCurrentUser();
  return (
    <>
      <div style={{ position: "relative" }}>
        {!item?.imgUrl1 &&
        !item?.imgUrl2 &&
        !item?.imgUrl3 &&
        !item?.imgUrl4 ? (
          <EmptyContentStyle></EmptyContentStyle>
        ) : (
          <ImageCarousel
            images={[item.imgUrl1, item.imgUrl2, item.imgUrl3, item.imgUrl4]}
          />
        )}
      </div>
      <Price>
        <IconWrapper>
          <MdOutlineAttachMoney />
          {item?.price || "-"}
        </IconWrapper>
      </Price>
      {item?.approvalStatus === PENDING_STATUS && (
        <Badge color="yellow">{item?.approvalStatus}</Badge>
      )}
      {/* {roomType === "rooms" && item?.createdUserId === userInfo.id && (
        <Badge color="green">your room</Badge>
      )} */}
      {item?.approvalStatus === APPROVE_STATUS && roomType !== "rooms" && (
        <Badge color="green">{item?.approvalStatus}</Badge>
      )}
      {item?.approvalStatus === REJECT_STATUS && (
        <Badge color="red">{item?.approvalStatus}</Badge>
      )}
      {item?.approvalStatus === RESERVE_STATUS && (
        <Badge color="pink">{item?.approvalStatus}</Badge>
      )}
      {item?.approvalStatus !== REJECT_STATUS &&
        item?.approvalStatus !== APPROVE_STATUS &&
        item?.approvalStatus !== PENDING_STATUS &&
        item?.approvalStatus !== RESERVE_STATUS &&
        (
          <Badge>{item?.approvalStatus || "unknown"}</Badge>
        )}
    </>
  );
};

export default RoomCardImages;
