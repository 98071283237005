import styled from "styled-components";

export const Container = styled.div`
  background-color: #1e1e1e;
  color: #fff;
  padding: 30px;
  height: calc(100vh-70px);
`;

export const Title = styled.h2`
  color: #fff;
`;

export const TableContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-top: 5px;
  border-radius: 10px;
  // margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  // justify-content: space-between;
  align-items: center;
`;
