import React, { useState } from "react";
import { Card } from "antd";
import { FiAlertTriangle } from "react-icons/fi";
import { STATUS, STATUS_CODE } from "../../constants/GlobalConstant";
import { updateUserStatusApi } from "../../services/RouteServices/UserRouteApi";
import { UserInfo } from "../../types/UserAuth";
import { EditUserStatusData } from "../../types/UserDataType";
import {
  UserResponseDataType,
  UserResponseType,
} from "../../types/UserResponseType";
import { getCurrentUser } from "../../utils/UserUtil";
import { CardContent, DeactivateButton, DeleteButton } from "./SettingElements";
import SettingModal from "./SettingModal/SettingModal";

const Setting: React.FC = () => {
  const userInfo: UserInfo = getCurrentUser();
  const [id, setId] = useState<number>(userInfo?.id || 0);
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");

  const handleStatusUpdate = async () => {
    const editUserStatus: EditUserStatusData = {
      id,
      activeStatus: status,
      updatedUser: userInfo.userName,
    };
    await updateUserStatusApi(editUserStatus)
      .then((response: UserResponseType) => {
        const responseData: UserResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setShowModal(false);
              setModalSuccess("");
            }, 1000);
          } else {
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  return (
    <>
      <div style={{ padding: "30px", height: "75vh" }}>
        <h1 style={{ color: "#fff" }}>Setting</h1>
        <Card style={{ backgroundColor: "#1f1f1f", borderColor: "#595959" }}>
          <CardContent>
            <div style={{ display: "flex" }}>
              <FiAlertTriangle size={35} color={"#d1961d"} />
              <h2 style={{ color: "white", marginLeft: "10px" }}>
                Do you want to deactivate your account?
              </h2>
            </div>
            <DeactivateButton
              onClick={() => {
                setShowModal(true);
                setStatus(STATUS.deactivateStatus);
              }}
            >
              Deactivate
            </DeactivateButton>
          </CardContent>
        </Card>
        <div style={{ margin: "20px" }}></div>
        <Card style={{ backgroundColor: "#1f1f1f", borderColor: "#595959" }}>
          <CardContent>
            <div style={{ display: "flex" }}>
              <FiAlertTriangle size={35} color={"red"} />
              <h2 style={{ color: "white", marginLeft: "10px" }}>
                Do you want to delete your account?
              </h2>
            </div>
            <DeleteButton
              onClick={() => {
                setShowModal(true);
                setStatus(STATUS.deleteStatus);
              }}
            >
              Delete
            </DeleteButton>
          </CardContent>
        </Card>
      </div>

      {/* Modal */}
      <SettingModal
        showModal={showModal}
        modalError={modalError}
        modalSuccess={modalSuccess}
        onCancel={() => {
          setShowModal(false);
          setModalSuccess("");
          setModalError("");
        }}
        status={status}
        handleStatusUpdate={handleStatusUpdate}
      />
    </>
  );
};

export default Setting;
