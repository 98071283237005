import styled from "styled-components";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineEdit } from "react-icons/ai";

export const Button = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background: #636464;
  border-radius: 10px;
  // margin-bottom: 10px;
  color: #fff;
  &:hover {
    color: #fff;
  }
`;

export const Container = styled.div`
  background-color: #1e1e1e;
  color: #fff;
  padding: 30px;
  height: calc(100vh-70px);
`;

export const Title = styled.h2`
  color: #fff;
`;

export const TableContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  // margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const InputContainer = styled.div`
  width: 95%;
  margin: 10px;
`;

export const InputLabel = styled.small`
  font-weight: 500;
`;

export const Required = styled.b`
  color: red;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
`;
export const ModalBtn = styled.button`
  outline: none;
  border: none;
  background-color: ${(props) =>
    props.color === "blue" ? "dodgerblue" : "green"};
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.color === "blue" ? "#007aec" : "#237804"};
    color: #fff;
  }
`;

export const RemoveButton = styled(RiDeleteBin6Line)`
  color: red;
  cursor: pointer;
  font-size: 150%;
  margin-right: 20px;

  &:hover {
    color: #da2a3f;
  }
`;

export const EditButton = styled(AiOutlineEdit)`
  color: dodgerblue;
  cursor: pointer;
  font-size: 170%;

  &:hover {
    color: #007aec;
  }
`;

export const DeleteBtn = styled.button`
  outline: none;
  border: none;
  background-color: red;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #da2a3f;
    color: #fff;
  }
`;
