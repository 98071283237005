import React from "react";

const Content: React.FC = () => {
  return (
    <div style={{ margin: "30px" }}>
      <h1 style={{ color: "#fff" }}>Contents</h1>
    </div>
  );
};

export default Content;
