import styled from "styled-components";
import { ICON_CONTAINER_COLOR } from "../../../constants/GlobalConstant";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
export const CardContainer = styled.div`
  width: 90%;
  margin-top: 10px;
  margin: 30px;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 50px;

  @media screen and (max-width: 425px) {
    padding-left: 10px;
  }
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
`;

export const WrapperFull = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  margin-top: 25px;
`;

export const ContentBlock = styled.div`
  display: block;
  margin-top: 25px;
`;

export const Label = styled.div`
  font-weight: bold;
  display: flex;
  margin-right: 5px;
`;

export const Value = styled.div`
  inline-size: auto;
  overflow-wrap: anywhere;
  white-space: break-spaces;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #848884;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 5px;
  font-weight: 500;

  &:hover {
    background-color: #71797e;
    color: #fff;
  }
`;

export const SubmitButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(143, 208, 113);
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 5px;
  font-weight: 500;

  &:hover {
    background-color: #8a9a5b;
    color: #fff;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 10px;
`;

export const IconContainer = styled.div`
  background-color: ${ICON_CONTAINER_COLOR};
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #fff;
  margin-right: 10px;
`;

export const IconContainerTwo = styled.div`
  background-color: ${(props) =>
    props.color === "yellow" ? "#faad14" : `${ICON_CONTAINER_COLOR}`};
  padding: 6px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 10px;
  height: 36px;
`;
