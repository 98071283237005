import styled from "styled-components";
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import { ICON_CONTAINER_COLOR } from "../../../constants/GlobalConstant";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdDownload } from "react-icons/io";

export const Container = styled.div`
  margin: 30px;

  @media screen and (max-width: 600px) {
    margin: 12px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 15px;
`;

export const Title = styled.h1`
  color: #fff;
`;

export const CardItem = styled.div`
  margin: 10px;
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
  }
`;
export const Card = styled.div`
  background-color: ${(props) =>
    props.color === "gold" ? "#fff7e6" : "#f0f0f0"};
  // background-color: #f0f0f0;
  color: #000;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0px;
`;

export const ImgContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const ContentStyle = styled.div`
  color: #fff;
  textalign: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgWrapper = styled.div`
  border-radius: 20px;
  overflow: hidden;
  display:flex,
  justify-content:center;
  align-items:center;
  width:40%;
  // height:auto;
  position:relative;
  margin:20px;

  @media screen and (max-width: 600px) {
    width:60%;
  }
`;

export const Image = styled.img`
  width: auto;
  height: auto;
`;

export const Desc = styled.div`
  padding: 10px 20px;
`;

export const Button = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #fff;
  color: dodgerblue;
  padding: 0;
  margin: 0;
  padding-top: 5px;
`;

export const PostButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${(props) =>
    props.color === "red" ? "red" : "dodgerblue"};
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;

  &:hover {
    background-color: ${(props) =>
      props.color === "red" ? "#da2a3f" : "#3d6009"};
    // background-color: #3d6009;
    color: #fff;
  }

  @media screen and (max-width: 425px) {
    padding: 10px 25px;
  }
`;

export const ScrollWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  background-color: dodgerblue;
  color: #fff;
  padding: 18px 16px;
  border-radius: 10px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`;

export const Price = styled.div`
  position: absolute;
  top: 7%;
  left: 5%;
  z-index: 2;
  // transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #262626;
  color: #fff;
  opacity: 0.8;
  border-radius: 10px;
`;

export const IconWrapper = styled.div`
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

export const Text = styled.div`
  color: #000;
  font-size: 120%;
`;

export const LeftArrow = styled(BsArrowLeftCircle)`
  position: absolute;
  z-index: 8;
  left: 0;
  top: 40%;
  margin-left: 10px;
  cursor: pointer;
  font-size: 120%;
  color: grey;
`;

export const RightArrow = styled(BsArrowRightCircle)`
  position: absolute;
  z-index: 8;
  right: 0;
  top: 40%;
  margin-right: 10px;
  cursor: pointer;
  font-size: 120%;
  color: grey;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  width: 100%;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const IconContainer = styled.div`
  background-color: #0958d9;
  color: #fff;
  padding: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

export const WhatsAppIconContainer = styled.div`
  background-color: #075E54;
  color: #fff;
  padding: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

export const IconContainerTwo = styled.div`
  background-color: ${ICON_CONTAINER_COLOR};
  padding: 6px 10px;
  border-radius: 10px;
  color: #fff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyContentStyle = styled.div`
  margin: 0;
  height: 100px;
  width: 100%;
  color: #fff;
  lineheight: 160px;
  textalign: center;
  position: relative;
`;

export const RandomImageContainer = styled.div`
  background-color: #262626;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 10px;
  position: relative;
`;
export const CardWrapper = styled.div`
  width: 450px;
  height: 450px;
  border-radius: 10px;
  padding: 20px 10px;
  position: relative;
`;

export const ContentContainer = styled.div`
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 90%;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const Content = styled.div`
  display: flex;
  // justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 110%;

  @media screen and (max-width: 600px) {
    font-size: 90%;
  }
`;

export const Label = styled.div`
  font-size: 120%;
  font-weight: 600;
  padding: 10px;
`;

export const RoomDetails = styled.div`
  margin-left: 30px;
  padding: 40px 0px;
  color: black;
  overflow-wrap: anywhere;
  white-space: pre-line;
  font-size: 120%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
`;

export const ModalButton = styled.button`
  outline: none;
  border: none;
  background: #636464;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  color: #fff;

  &:hover {
    // background: #007aec;
    color: #fff;
  }
`;

export const DeleteBtn = styled.button`
  outline: none;
  border: none;
  background-color: red;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #da2a3f;
    color: #fff;
  }
`;

export const ApproveBtn = styled.button`
  outline: none;
  border: none;
  background-color: green;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #3f6600;
    color: #fff;
  }
`;

export const ReserveBtn = styled.button`
  outline: none;
  border: none;
  background-color: #f759ab;
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #eb2f96;
    color: #fff;
  }
`;

export const EditButton = styled(AiOutlineEdit)`
  color: dodgerblue;
  cursor: pointer;
  font-size: 190%;
  margin-right: 10px;

  &:hover {
    color: #007aec;
  }
`;

export const RemoveButton = styled(RiDeleteBin6Line)`
  color: red;
  cursor: pointer;
  font-size: 150%;
  margin-right: 20px;

  &:hover {
    color: #da2a3f;
  }
`;

export const ModalBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  alignitems: center;

  @media screen and (max-width: 470px) {
    flex-direction: column;
  }
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 200px) {
    flex-direction: column;
  }
`;

export const AlertWrapper = styled.div`
  padding: 20px;
  width: 60%;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Badge = styled.span`
  background-color: ${(props) =>
    props.color === "green"
      ? "#bae637"
      : props.color === "yellow"
      ? "#ffe58f"
      : props.color === "red"
      ? "#ff7875"
      : props.color === "pink"
      ? "#ff85c0"
      : "#b37feb"};
  border-radius: 10px;
  opacity: 0.9;
  padding: 5px 15px;
  font-size: 90%;
  position: absolute;
  top: 7%;
  z-index: 2;
  right: 5%;
  font-weight: 500;
`;

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DownloadIcon = styled(IoMdDownload)`
  font-size: 150%;
  color: #2f54eb;
  cursor: pointer;

  &:hover {
    color: #1d39c4;
  }
`;

export const PostOwnerWrapper = styled.p`
  font-size: 85%;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;

export const TitleWrapper =styled.div`
  padding:20px;
`