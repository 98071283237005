import { DatePickerProps, Steps, UploadFile, UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useState, useEffect, FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GenderOption } from "../../../constants/GenderOptionConstant";
import {
  getAllMrtInMrtLineListApi,
  getMrtInMrtLineByIdApi,
  getMrtInMrtLineListApi,
} from "../../../services/RouteServices/MrtLineDetailRouteApi";
import { getAllMrtListApi } from "../../../services/RouteServices/MrtLineRouteApi";
import { MRTInMrtLineResponseData } from "../../../types/MRTInMrtLineResponseType";
import { PostRoomDataType } from "../../../types/PostRoomDataType";
import PostRoomForm from "../../Shared/PostRoomForm/PostRoomForm";
import { Container, StepWrapper } from "./EditMyRoomsElements";
import PostConfirm from "../../Room/PostConfirm/PostConfirm";
import moment from "moment";
import { MRTLineResponseData } from "../../../types/MRTLineResponseType";
import {
  ALLOW_IMG_EXTENSION_LIST,
  PAGE_SIZE,
} from "../../../constants/GlobalConstant";
import { getCurrentUserOnly } from "../../../utils/UserUtil";

const { Step } = Steps;

const EditMyRooms: FC = () => {
  const userName = getCurrentUserOnly();
  const navigate = useNavigate();
  const locationRoute = useLocation();
  const [room, setRoom] = useState(locationRoute.state?.room);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [location, setLocation] = useState(room?.location);
  const [gender, setGender] = useState(room?.gender);
  const [phoneNo, setPhoneNo] = useState(room?.phoneNo);
  const [postalCode, setPostalCode] = useState(room?.postalCode);
  const [address, setAddress] = useState(room?.address);
  const [price, setPrice] = useState(room?.price);
  const [detail, setDetail] = useState(room?.details);
  const [title, setTitle] = useState<string>(room?.title);
  const [importantNote, setImportantNote] = useState<string>(
    room?.importantNote
  );
  const [percent, setPercent] = useState<number>(0);
  const [current, setCurrent] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>("");
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [fileLists, setFileLists] = useState<UploadFile[]>([]);
  const [mrtLines, setMrtLines] = useState<MRTLineResponseData[]>([]);
  const [mrtInMrtLines, setMrtInMrtLines] = useState<
    MRTInMrtLineResponseData[]
  >([]);
  const [mrtInMrtLine, setMrtInMrtLine] = useState<number>(0);
  const [mrtLine, setMrtLine] = useState<number>(0);
  const [startDate, setStartDate] = useState(room?.startDate);
  const [date, setDate] = useState<moment.Moment>(
    moment(room?.startDate, "YYYY-MM-DD")
  );
  const [anonymousUser, setAnonymousUser] = useState<boolean>(
    room.anonymousName ? true : false
  );
  const [searchText, setSearchText] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
  const [filteredOptions, setFilteredOptions] = useState<
    MRTInMrtLineResponseData[]
  >([]);

  useEffect(() => {
    getAllMrtList();
    if (room) {
      let fileList: any[] = [];
      getMrtInMrtLineByIdApi({
        mrtLineId: room.mrtId,
      }).then((res) => {
        setLocation(res.data.data.mrtName);
        setMrtInMrtLine(res.data.data.id);
        const mrtLine: MRTLineResponseData | undefined = mrtLines.find(
          (mrt: MRTLineResponseData) => mrt.id === res.data.data.mrtLineId
        );
        if (mrtLine) {
          setMrtLine(mrtLine?.id);
        }
      });

      if (room.imgUrl1) {
        fileList.push({ thumbUrl: "data:image/png;base64," + room.imgUrl1 });
      }
      if (room.imgUrl2) {
        fileList.push({ thumbUrl: "data:image/png;base64," + room.imgUrl2 });
      }
      if (room.imgUrl3) {
        fileList.push({ thumbUrl: "data:image/png;base64," + room.imgUrl3 });
      }
      if (room.imgUrl4) {
        fileList.push({ thumbUrl: "data:image/png;base64," + room.imgUrl4 });
      }
      setFileLists(fileList);
    }
  }, []);

  const getAllMrtList = async () => {
    const result = await getAllMrtListApi();
    setMrtLines(result.data.data);
  };

  const onChangeDate: DatePickerProps["onChange"] = (
    date: moment.Moment | null,
    dateString
  ) => {
    // console.log(date, dateString);
    setStartDate(dateString);
    if (date !== null) {
      setDate(date);
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (file.thumbUrl) {
      setPreviewImage(file.thumbUrl);
      setPreviewOpen(true);
      return;
    }

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const inputData: PostRoomDataType = {
    location,
    mrtId: mrtInMrtLine,
    title,
    gender,
    phoneNo,
    postalCode,
    address,
    price,
    importantNote,
    detail,
    images: fileLists,
    startDate,
    anonymousUser,
  };

  const handleChange = (value: string) => {
    const isGenderExists = GenderOption.some(
      (gender) => gender.value === value
    );
    if (!isGenderExists) {
      setError("Gender is invalid!");
    } else {
      setError("");
      setGender(value);
    }
  };

  const handleMrtLine = async (value: number) => {
    setSearchText("");

    const inputData = { mrtLineId: value };
    const response = await getAllMrtInMrtLineListApi(inputData);
    setMrtInMrtLines(response.data.data);

    setFilteredOptions(response.data.data);
    setMrtInMrtLine(0);
    setLocation("");
  };

  const handleMrtInMrtLineChange = (value: number) => {
    setMrtInMrtLine(value);
    let selectedMrt: MRTInMrtLineResponseData | null = null;
    let mrt: MRTInMrtLineResponseData;
    for (mrt of mrtInMrtLines) {
      if (mrt.id === value) {
        selectedMrt = mrt;
        break;
      }
    }
    if (selectedMrt) {
      setLocation(selectedMrt.mrtName);
    } else {
      setLocation("");
      setMrtInMrtLine(0);
    }
  };

  const findMrtBySearchKey = (searchMrtText : string) => {
    let tempMatchMrtList : MRTInMrtLineResponseData[] = [];
  
    mrtInMrtLines.forEach((mrt : MRTInMrtLineResponseData) => {
      const mrtName = mrt.mrtName?.toLocaleLowerCase();
      const searchValue = searchMrtText.toLocaleLowerCase();

      if (mrtName.includes(searchValue)) {
        tempMatchMrtList.push(mrt);
      }
    });
    return tempMatchMrtList;
  };

  const handleSearchMrtLine = (value: string) => {
    if (!value) {
      setFilteredOptions(mrtInMrtLines); // if no search key, just show all mrt
    } else {
      const similiarMrtList = findMrtBySearchKey(value);
      setFilteredOptions(similiarMrtList);
    }
  };

  const handleConfirm = () => {
    setError("");
    setPercent(100);
    setCurrent(current + 1);
    setConfirm(true);
  };

  const handleConfirmCancle = () => {
    setConfirm(false);
    setCurrent(current - 1);
  };

  const handleImgChange: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    let isValid = true;
    newFileList.forEach((file) => {
      if (file.name) {
        const fileName = file?.name?.toLowerCase();
        const fileExtension = fileName.substring(fileName.lastIndexOf("."));
        if (!ALLOW_IMG_EXTENSION_LIST.includes(fileExtension)) {
          isValid = false;
        }
      }
    });

    if (isValid) {
      setError("");
      setFileLists(newFileList);
    } else {
      setError(
        "Invalid file type. Please upload only .gif, .png, .jpeg, or .jpg files."
      );
    }
  };

  const handleChangeAnonymousUser = (checked: boolean) => {
    setAnonymousUser(checked);
  };

  return (
    <Container>
      <StepWrapper>
        <Steps
          current={current}
          className="step edit-rooms"
          percent={percent}
          style={{ color: "#ffc53d" }}
          labelPlacement="vertical"
        >
          <Step title="Post" />
          <Step title="Confirm" />
        </Steps>
      </StepWrapper>
      {!confirm && (
        <PostRoomForm
          room={room}
          color="yellow"
          className={"edit-rooms"}
          error={error}
          handleMrtLine={handleMrtLine}
          mrtLines={mrtLines}
          mrtInMrtLine={mrtInMrtLine}
          handleMrtInMrtLineChange={handleMrtInMrtLineChange}
          handleSearchMrtLine={handleSearchMrtLine}
          filteredOptions={filteredOptions}
          mrtInMrtLines={mrtInMrtLines}
          gender={gender}
          handleChange={handleChange}
          genderOption={GenderOption}
          phoneNo={phoneNo}
          handlePhoneNoChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPhoneNo(e.target.value)
          }
          postalCode={postalCode}
          handlePostalCodeChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPostalCode(e.target.value)
          }
          title={title}
          handleTitleChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setTitle(e.target.value)
          }
          address={address}
          handleAddressChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAddress(e.target.value)
          }
          price={price}
          handlePriceChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPrice(e.target.value)
          }
          date={date}
          onChangeDate={onChangeDate}
          detail={detail}
          handleDetailChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setDetail(e.target.value)
          }
          importantNote={importantNote}
          handleImportantNoteChange={(
            e: React.ChangeEvent<HTMLTextAreaElement>
          ) => setImportantNote(e.target.value)}
          fileList={fileLists}
          handlePreview={handlePreview}
          handleImgChange={handleImgChange}
          previewOpen={previewOpen}
          previewTitle={previewTitle}
          previewImage={previewImage}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          handleBack={() => navigate("/my-rooms", { replace: true })}
          mrtLine={mrtLine}
          location={location}
          handleChangeAnonymousUser={handleChangeAnonymousUser}
          anonymousUser={anonymousUser}
        />
      )}
      {confirm && (
        <PostConfirm
          color="yellow"
          inputData={inputData}
          handleConfirmCancle={handleConfirmCancle}
          edit={true}
          id={room.id}
        />
      )}
    </Container>
  );
};

export default EditMyRooms;
