import { toPng } from "html-to-image";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ORG_AND_WATERMARK_TEXT } from "../constants/GlobalConstant";
import { getLocalCurrentDateOnlyStr } from "./DateUtil";

const logoImgPath = "/images/logo960.jpg";

export const htmlToImageConvert = async (
  element: HTMLDivElement | null,
  name: string | undefined
) => {
  if (!element) {
    return;
  }

  try {
    //Create an image element for the logo
    const logo = document.createElement("img");
    logo.src = logoImgPath;
    logo.style.position = "relative";
    logo.style.bottom = "10px";
    logo.style.left = "3px";
    logo.style.width = "60px";
    logo.style.height = "60px";
    logo.style.marginLeft = "20px";
    logo.style.borderRadius = "50px";
    logo.style.zIndex = "10";

    // Add the logo to the card element
    element.appendChild(logo);

    const dataUrl = await toPng(element);

    // Remove the logo from the card element
    element.removeChild(logo);

    const link = document.createElement("a");
    link.download = `Room-${name}-${getLocalCurrentDateOnlyStr()}.png`;
    link.href = dataUrl;
    link.click();
  } catch (error) {
    console.error("Error generating image", error);
  }
};

export const downloadPdfDocument = (input: any, name: string) => {
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();

    const awidth = width - 10; // Subtract any desired margins
    const aheight = height - 20;

    pdf.addImage(imgData, "PNG", 5, 0, width, aheight);

    // Set the watermark properties
    const watermarkText = ORG_AND_WATERMARK_TEXT;
    const watermarkSize = 30;

    // Calculate the position of the watermark
    const pageSize = pdf.internal.pageSize;
    const wwidth = pageSize.getWidth();
    const wheight = pageSize.getHeight();
    const xPos =
      wwidth / 2 - (pdf.getStringUnitWidth(watermarkText) * watermarkSize) / 2;
    const yPos = wheight - 10;

    // Add the watermark text to each page
    const totalPages = pdf.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.setFontSize(watermarkSize);
      pdf.setTextColor(210);
      pdf.text(watermarkText, 3, 20, { angle: 270 });
    }

    pdf.save(`Room-${name}-${getLocalCurrentDateOnlyStr()}.pdf`);
  });
};
