import { IoDiamondOutline } from "react-icons/io5";
import styled from "styled-components";

export const Container = styled.div`
  background-color: #1e1e1e;
  color: #fff;
  padding: 30px;
  height: calc(100vh-70px);
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: 425px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const CardItem = styled.div`
  flex: 1;

  &:nth-child(2) {
    margin: 10px;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }

  p {
    margin: 0px;
    font-weight: bold;
    font-size: 200%;
  }
`;

export const Title = styled.h2`
  color: #fff;
`;

export const TableContainer = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  // margin-bottom: 20px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const ProfileWrapper = styled.div`
  width: 60%;

  @media screen and (max-width: 950px) {
    width: 90%;
  }
  @media screen and (max-width: 550px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  border-radius: 20px;
`;

export const ProfileContent = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 15px 0px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const DiamondWrapper = styled.div`
  display:flex;
  align-items:center;
  background-color:#f0f0f0;
  width:60%;
  padding:5px 10px;
  border-radius:30px;
  margin-bottom:10px;

`
export const DiamondIcon = styled(IoDiamondOutline)`
  color: #ffc53d;
  font-size:150%;
  margin-right:5px;
`