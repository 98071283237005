import { Modal } from "antd";
import React from "react";
import { RoleDeleteModalProps } from "../../../types/RoleDataType";
import AlertMessage from "../../Shared/AlertMessage/AlertMessage";
import { Button, ButtonWrapper, DeleteBtn } from "../RoleElements";

const RoleDeleteModal: React.FC<RoleDeleteModalProps> = ({
  showRemoveModal,
  modalError,
  modalSuccess,
  onCancel,
  removeRoleName,
  handleDelete,
}) => {
  return (
    <Modal
      title="Delete Role"
      open={showRemoveModal}
      onCancel={onCancel}
      footer={null}
    >
      {modalError && (
        <AlertMessage
          message={modalError}
          visible={true}
          typeAlert={"error"}
          width={"100%"}
        />
      )}
      {modalSuccess && (
        <AlertMessage
          message={modalSuccess}
          visible={true}
          typeAlert={"success"}
          width={"100%"}
        />
      )}
      <h2>
        Are you sure you want to delete [
        <span style={{ color: "red" }}>{removeRoleName}</span>]?
      </h2>
      <ButtonWrapper>
        <Button onClick={onCancel}>Cancel</Button>
        <DeleteBtn onClick={handleDelete}>Delete</DeleteBtn>
      </ButtonWrapper>
    </Modal>
  );
};

export default RoleDeleteModal;
