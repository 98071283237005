import styled from "styled-components";
import { HomeCardProps } from "../../types/CommonType";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
`;

export const Card = styled.div<HomeCardProps>`
  width: 95%;
  padding: 20px 50px;
  border-radius: 20px;
  margin: 0px 30px;
  background-image: url(${({ backgroundImageUrl }) => backgroundImageUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
export const List = styled.ul`
  @media screen and (max-width: 700px) {
    list-style: none;
    padding: 0px;
  }
`;
export const ListItem = styled.li``;

export const ProductContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: flex-start;
  flex-direction: column;
  padding: 20px 20px;
  height: 60vh;
`;

export const ProductCardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 655px) {
    flex-direction: column;
  }
`;

export const CardItem = styled.div`
  flex: 1;
  &:nth-child(2) {
    margin: 0px 20px;
  }

  @media screen and (max-width: 655px) {
    flex-direction: column;
    &:nth-child(2) {
      margin: 20px 0px;
    }
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    &:nth-child(2) {
      margin: 20px 0px;
    }
  }
`;

export const Text = styled.p`
  word-wrap: break-word;
`;

export const EmailText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: #fff;
`;

export const CardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 655px) {
    flex-direction: column;
  }
`;

export const WelcomeContainer = styled.div`
  display: contents;
  opacity: 0.6;
  margin: 30px;
  padding: 15px;

  @media screen and (max-width: 760px) {
    margin: 1px;
    padding: 3px;
  }

  @media screen and (max-width: 200px) {
    display: none;
  }
`;

export const TextWrapper = styled.div`
  background-color: #4e4a4a;
  padding: 10px 30px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px 0px;
  opacity: 0.8;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RoomButton = styled.div`
  border: 5px solid black;
  background: #ffd73e33;
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%232d3561' /%3E%3Cstop offset='25%25' stop-color='%23c05c7e' /%3E%3Cstop offset='50%25' stop-color='%23f3826f' /%3E%3Cstop offset='100%25' stop-color='%23ffb961' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E")
    1;

  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #49a549;
  }
  @media screen and (max-width: 464px) {
    padding: 10px 40px;
  }
`;
