import React from "react";
import { Input as AntInput, Switch } from "antd";
import { Card, Space, Select, DatePicker, Upload, Modal } from "antd";
import {
  Button,
  ButtonWrapper,
  CancelButton,
  CardContainer,
  CardContent,
  InputContainer,
  InputLabel,
  InputWrapper,
  Required,
} from "./PostRoomFormElements";
import { PlusOutlined } from "@ant-design/icons";
import { MRTLineResponseData } from "../../../types/MRTLineResponseType";
import { MRTInMrtLineResponseData } from "../../../types/MRTInMrtLineResponseType";
import { BsFillTelephoneFill, BsMailbox2 } from "react-icons/bs";
import { ImLocation2 } from "react-icons/im";
import { MdOutlineAttachMoney } from "react-icons/md";
import AlertMessage from "../AlertMessage/AlertMessage";
import {
  ALLOW_IMG_EXTENSION_LIST,
  UPLOADABLE_IMG_MAX_LIMIT,
} from "../../../constants/GlobalConstant";
import { PostRoomFormDataType } from "../../../types/PostRoomDataType";

const { Option } = Select;
const { TextArea } = AntInput;

const PostRoomForm: React.FC<PostRoomFormDataType> = (props) => {
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <CardContainer>
      <Card>
        <CardContent>
          <InputWrapper>
            <InputContainer>
              <InputLabel>
                Title<Required>*</Required>
              </InputLabel>
              <TextArea
                rows={2}
                className={props.className}
                value={props.title}
                size="large"
                placeholder="e.g big common room for couple.."
                onChange={props.handleTitleChange}
                maxLength={100}
              />
            </InputContainer>
          </InputWrapper>
          <InputWrapper>
            <InputContainer>
              <InputLabel>
                MRT Line<Required>*</Required>
              </InputLabel>
              <div>
                <Space wrap>
                  <Select
                    defaultValue={props.mrtLine || 0}
                    style={{ width: 180 }}
                    size="large"
                    onChange={props.handleMrtLine}
                  >
                    <Option value={0}>Select MRT Line</Option>
                    {Array.isArray(props.mrtLines) &&
                      props.mrtLines.map((option: MRTLineResponseData) => (
                        <Option
                          key={option.id}
                          value={option.id}
                          style={{ backgroundColor: option.colorCode }}
                        >
                          {option.mrtLineName}
                        </Option>
                      ))}
                  </Select>

                  <Select
                    showSearch
                    style={{ width: 180 }}
                    size="large"
                    value={props.location || 0}
                    onChange={props.handleMrtInMrtLineChange}
                    onSearch={props.handleSearchMrtLine}
                    filterOption={false}
                  >
                    <Option value={0}>Select MRT List</Option>
                    {Array.isArray(props.filteredOptions) &&
                      props.filteredOptions.map(
                        (option: MRTInMrtLineResponseData) => (
                          <Option key={option.id} value={option.id}>
                            {option.mrtName}
                          </Option>
                        )
                      )}
                  </Select>
                </Space>
              </div>
            </InputContainer>
            <InputContainer>
              <InputLabel>
                Gender<Required>*</Required>
              </InputLabel>
              <Select
                size="large"
                style={{ width: "100%" }}
                value={props.gender}
                placeholder="Select Gender"
                onChange={props.handleChange}
                options={props.genderOption}
              />
            </InputContainer>
          </InputWrapper>
          <InputWrapper>
            <InputContainer>
              <InputLabel>
                Contact No<Required>*</Required>
              </InputLabel>
              <AntInput
                className={props.className}
                value={props.phoneNo}
                size="large"
                placeholder="WhatsApp No (e.g 94563291)"
                prefix={<BsFillTelephoneFill />}
                onChange={props.handlePhoneNoChange}
                maxLength={15}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Postal Code</InputLabel>
              <AntInput
                className={props.className}
                size="large"
                placeholder="e.g 120987"
                prefix={<BsMailbox2 />}
                maxLength={7}
                value={props.postalCode}
                onChange={props.handlePostalCodeChange}
              />
            </InputContainer>
          </InputWrapper>
          <InputWrapper>
            <InputContainer>
              <InputLabel>Address</InputLabel>
              <AntInput
                className={props.className}
                size="large"
                placeholder="e.g Bedok North Ave 2"
                prefix={<ImLocation2 />}
                onChange={props.handleAddressChange}
                maxLength={50}
                value={props.address}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>
                Price<Required>*</Required>
              </InputLabel>
              <AntInput
                className={props.className}
                size="large"
                placeholder="e.g 550"
                prefix={<MdOutlineAttachMoney />}
                onChange={props.handlePriceChange}
                maxLength={10}
                value={props.price}
              />
            </InputContainer>
          </InputWrapper>
          <InputWrapper>
            <InputContainer>
              <InputLabel>
                Start Date<Required>*</Required>
              </InputLabel>
              <div>
                <DatePicker
                  onChange={props.onChangeDate}
                  size="large"
                  style={{ width: 200, height: 45 }}
                  value={props.date}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <InputLabel>Post as Anonymous</InputLabel>
              <div>
                <Switch
                  defaultChecked={props.anonymousUser}
                  onChange={props.handleChangeAnonymousUser}
                />
              </div>
            </InputContainer>
          </InputWrapper>
          <InputWrapper>
            <InputContainer>
              <InputLabel>
                Important Note<Required>*</Required>
              </InputLabel>
              <TextArea
                rows={4}
                placeholder="e.g no allowed visitors, no smoking.."
                maxLength={100}
                value={props.importantNote}
                onChange={props.handleImportantNoteChange}
              />
            </InputContainer>
          </InputWrapper>
          <InputWrapper>
            <InputContainer>
              <InputLabel>
                Details<Required>*</Required>
              </InputLabel>
              <TextArea
                rows={12}
                placeholder="Details"
                maxLength={1500}
                value={props.detail}
                onChange={props.handleDetailChange}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Images</InputLabel>
              <Upload
                accept={ALLOW_IMG_EXTENSION_LIST}
                listType="picture-card"
                fileList={props.fileList}
                onPreview={props.handlePreview}
                onChange={props.handleImgChange}
                beforeUpload={() => false}
                maxCount={UPLOADABLE_IMG_MAX_LIMIT}
              >
                {props?.fileList?.length >= UPLOADABLE_IMG_MAX_LIMIT
                  ? null
                  : uploadButton}
              </Upload>

              <Modal
                open={props.previewOpen}
                title={props.previewTitle}
                footer={null}
                onCancel={props.handleCancel}
                style={{ width: "50%" }}
              >
                <img
                  alt="uploadImage"
                  style={{ width: "100%" }}
                  src={props.previewImage}
                />
              </Modal>
            </InputContainer>
          </InputWrapper>
          <InputContainer>
            {props?.error && (
              <AlertMessage
                message={props.error}
                visible={true}
                typeAlert={"error"}
                width={"100%"}
              />
            )}
          </InputContainer>
          <ButtonWrapper>
            <CancelButton onClick={props.handleBack}>Cancel</CancelButton>
            <Button onClick={props.handleConfirm}>Next</Button>
          </ButtonWrapper>
        </CardContent>
      </Card>
    </CardContainer>
  );
};

export default PostRoomForm;
