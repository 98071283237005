import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Card,
  ImgContainer,
  EmptyContentStyle,
  ContentContainer,
  Content,
  IconContainer,
  Label,
  Text,
  ContentWrapper,
  Button,
  RoomDetails,
  HeaderWrapper,
  DownloadIcon,
  PdfDownloadIcon,
  DownloadIconWrapper,
  TitleWrapper,
} from "./RoomDetailElements";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MdOutlineAttachMoney, MdOutlineDateRange } from "react-icons/md";
import { BsFillTelephoneFill, BsMailbox2 } from "react-icons/bs";
import { FaUserAlt, FaUserTie } from "react-icons/fa";
import { BiCurrentLocation } from "react-icons/bi";
import { IoIosArrowBack } from "react-icons/io";
import { getRoomByIdApi } from "../../services/RouteServices/RoomRouteApi";
import { LOG_INFO_LEVEL, STATUS_CODE } from "../../constants/GlobalConstant";
import {
  RoomResponseDataType,
  RoomResponseType,
} from "../../types/RoomResponseType";
import AlertMessage from "../Shared/AlertMessage/AlertMessage";
import ImageCarousel from "../Shared/ImageCarousel/ImageCarousel";
import {
  convertDateFormat,
  getLocalCurrentDateOnlyStr,
} from "../../utils/DateUtil";
import { Alert } from "antd";
import { AlertWrapper } from "../Shared/RoomCard/RoomCardElements";
import { htmlToImageConvert } from "../../utils/DownloadUtil";
import { PDFDownloadLink } from "@react-pdf/renderer";
import RoomPdfDocument from "../PdfDocument/RoomPdfDocument";
import { RoomDataType } from "../../types/RoomDataType";
import { addEventLog } from "../../utils/EventLogUtil";
import { getCurrentUser } from "../../utils/UserUtil";
import { UserInfo } from "../../types/UserAuth";

const RoomDetail = () => {
  const [searchParam] = useSearchParams();
  const userInfo: UserInfo = getCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [room, setRoom] = useState(location.state?.room);
  const [page, setPage] = useState<number>(location.state?.page);
  const [offset, setOffset] = useState<number>(location.state?.offset || 0);
  const [roomId, setRoomId] = useState<string>(searchParam.get("id") || "0");
  const [modalError, setModalError] = useState<string>("");
  const [modalSuccess, setModalSuccess] = useState<string>("");
  const elementRef = useRef<HTMLDivElement>(null);

  const iconSize = 17;

  useEffect(() => {
    getRoomById();
  }, [roomId]);

  const getRoomById = async () => {
    const inputData = { id: Number(roomId) };
    await getRoomByIdApi(inputData)
      .then((response: RoomResponseType) => {
        const responseData: RoomResponseDataType = response?.data;
        if (responseData) {
          if (responseData?.status === STATUS_CODE.successCode) {
            setRoom(responseData.data);
            setModalError("");
            setModalSuccess(responseData?.message);
            setTimeout(() => {
              setModalSuccess("");
            }, 3000);
          } else {
            setRoom(null);
            setModalError(responseData?.message);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setModalError(err?.response?.data?.message || err?.message);
      });
  };

  const addEventLogForDownload = async (eventLog: string) => {
    const inputData = {
      newEventLog: eventLog,
      eventLevel: LOG_INFO_LEVEL,
      createdUser: userInfo?.userName || "unknown",
    };
    await addEventLog(inputData);
  };

  return (
    <>
      {room ? (
        <Container>
          <Button
            onClick={() => {
              navigate(
                {
                  pathname: "/room",
                },
                { state: { page, offset } }
              );
            }}
          >
            <IoIosArrowBack size={iconSize} />
            Back
          </Button>
          <Card ref={elementRef}>
            <ImgContainer>
              <div style={{ position: "relative" }}>
                {!room.imgUrl1 &&
                !room.imgUrl2 &&
                !room.imgUrl3 &&
                !room.imgUrl4 ? (
                  <EmptyContentStyle></EmptyContentStyle>
                ) : (
                  <ImageCarousel
                    images={[
                      room.imgUrl1,
                      room.imgUrl2,
                      room.imgUrl3,
                      room.imgUrl4,
                    ]}
                    room="roomDetails"
                  />
                )}
              </div>
            </ImgContainer>
            <HeaderWrapper>
              <h2 style={{ marginLeft: "30px", fontSize: "130%" }}>
                {room?.mrtInMrtLine?.mrtName}
              </h2>
              <DownloadIconWrapper>
                <DownloadIcon
                  onClick={() => {
                    htmlToImageConvert(
                      elementRef.current,
                      room?.mrtInMrtLine?.mrtName
                    );
                    addEventLogForDownload(
                      `downloaded details room id ${room.id}`
                    );
                  }}
                />
                <PDFDownloadLink
                  document={<RoomPdfDocument room={room} />}
                  fileName={`${
                    room?.mrtName
                  }-${getLocalCurrentDateOnlyStr()}.pdf`}
                  onClick={() =>
                    addEventLogForDownload(
                      `downloaded pdf for details room id ${room.id}`
                    )
                  }
                >
                  <PdfDownloadIcon />
                </PDFDownloadLink>
              </DownloadIconWrapper>
            </HeaderWrapper>
            <TitleWrapper>
              <Text style={{ color: "forestgreen" }}>{room?.title}</Text>
            </TitleWrapper>
            <ContentContainer>
              <ContentWrapper>
                <Content>
                  <IconContainer>
                    <MdOutlineAttachMoney size={iconSize} />
                  </IconContainer>
                  <Label>Price:</Label>
                  <Text>S$ {room?.price || "-"}</Text>
                </Content>
                <Content>
                  <IconContainer>
                    <MdOutlineDateRange size={iconSize} />
                  </IconContainer>
                  <Label>Start Date:</Label>
                  <Text>{convertDateFormat(room?.startDate) || "-"}</Text>
                </Content>
                <Content>
                  <IconContainer>
                    <BsMailbox2 size={iconSize} />
                  </IconContainer>
                  <Label>Postal Code:</Label>
                  <Text>{room?.postalCode || "-"}</Text>
                </Content>
                <Content>
                  <IconContainer>
                    <BsFillTelephoneFill size={iconSize} />
                  </IconContainer>
                  <Label>Phone No:</Label>
                  <Text>{room?.phoneNo || "-"}</Text>
                </Content>
                <Content>
                  <IconContainer>
                    <FaUserAlt size={iconSize} />
                  </IconContainer>
                  <Label>Gender:</Label>
                  <Text>{room?.gender || "-"}</Text>
                </Content>
                <Content>
                  <IconContainer>
                    <BiCurrentLocation size={iconSize} />
                  </IconContainer>
                  <Label>Address:</Label>
                  <Text>{room?.address || "-"}</Text>
                </Content>
                <Content>
                  <IconContainer>
                    <FaUserTie size={iconSize} />
                  </IconContainer>
                  <Label>Post Owner:</Label>
                  <Text>
                    {room?.anonymousName ? (
                      <>
                        <i
                          style={{
                            color: "#b37feb",
                            fontWeight: 600,
                          }}
                        >
                          {room?.anonymousName}
                        </i>
                      </>
                    ) : room?.createdUser ? (
                      room?.createdUser
                    ) : (
                      "unknown"
                    )}
                  </Text>
                </Content>
              </ContentWrapper>
            </ContentContainer>
            {room?.importantNote && (
              <AlertWrapper>
                <Alert
                  message="Important Note"
                  description={room?.importantNote}
                  type="info"
                  showIcon
                  style={{ width: "100%" }}
                />
              </AlertWrapper>
            )}
            <RoomDetails>{room?.details || "-"}</RoomDetails>
          </Card>
        </Container>
      ) : (
        <div
          style={{
            margin: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AlertMessage
            message={modalError}
            visible={true}
            typeAlert={"error"}
            width={"80%"}
          />
        </div>
      )}
    </>
  );
};

export default RoomDetail;
