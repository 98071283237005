import { statusInput } from "../../types/LoginUserType";
import {
  DeleteUserData,
  EditUserData,
  EditUserStatusData,
  GetUserById,
  GetUserByMonth,
  GetUserByYear,
  UserPaginationData,
} from "../../types/UserDataType";
import http from "../axiosService";

export const getUserListApi = async (inputData: UserPaginationData) => {
  return await http.post("/user/get/list", inputData);
};

export const getAllUserListByMonthApi = async (inputData: GetUserByMonth) => {
  return await http.post("/user/get/all/listByMonth", inputData);
};

export const getAllUserListByYearApi = async (inputData: GetUserByYear) => {
  return await http.post("/user/get/all/listByYear", inputData);
};

export const getTotalCountApi = async () => {
  return await http.get("/user/get/total");
};

export const getCountApi = async (input: statusInput) => {
  return await http.post("/user/get/count", input);
};

export const updateUserApi = async (input: EditUserData) => {
  return await http.post("/user/update", input);
};

export const updateUserStatusApi = async (input: EditUserStatusData) => {
  return await http.post("/user/update/active/status", input);
};

export const deleteUserApi = async (input: DeleteUserData) => {
  return await http.post("/user/delete", input);
};

export const getUserByIdApi = async (inputData: GetUserById) => {
  return await http.post("/user/getById", inputData);
};
